

.shift-type-check {
  padding: 8px 8px;
  background-color: transparent;
  border: 0.5px solid #006ca6;
  border-radius: 8px;
  width: 120px;
}

.shift-type-checkbox {
  width: 100%;
  padding-right: 80px;
  flex-wrap: wrap;
  row-gap: 9px;
  column-gap: 4px;
}

.shift-type-check span {
  color: var(--main-dark-color);
  font-size: 16px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  padding-left: 0;
}

.dashboard-form .form-text {
  font-size: 16px;
  color: #3d4244;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.shift-type-check span svg {
  width: 22px;
}

/* media query 1320px  */

.shift-type-checkbox {
  padding-right: 0;
}

.shift-type-check {
  width: auto;
  padding: 4px;
}

.shift-type-check span {
  font-size: 13px;
}

.dashboard-form .shift-type-section-box {
  margin-right: 0;
  padding-left: 12px;
  max-width: 117px;
}

.shift-type-check span svg {
  width: 19px;
}

.dashboard-form .form-text {
  font-size: var(--f-size-14);
}

.shift-checkbox.form-text span {
  font-size: var(--f-size-14);
}


#attendance.attended .fc .fc-daygrid-day.fc-day-today {
  background-color: #AFFED0 !important;  
}


/* #attendance .fc .fc-day-today .fc-daygrid-day-top {
  opacity: 0.3 !important;
} */

/* #attendance .fc .fc-day-past .fc-daygrid-day-top {
  opacity: 0.3 !important;
} */


#attendance .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame .fc-daygrid-day-number{
  background: #15a07d !important;
    color: #FFF;
    padding: 11px 5px 4px 13px;
}

#attendance .fc .fc-daygrid-day-frame .fc-daygrid-day-number{

  border-radius: 50%;
  min-height: auto !important;
  transition: all 0.2s ease-in-out;
  width: 42px;
  height: 42px;
}

.attendance-checkbox-gup{
position: relative;
top: 70px;
right: 125px;
}

.checkbox-gup-label.MuiTypography-root{ 
  font-size: 15px;
  white-space: pre;
  color: #202E2A;
  }

.checkbox-gup-label-present.MuiTypography-root{ 
  font-size: 15px;
  white-space: pre;
  color: #109648;
  }

.checkbox-gup-label-absent.MuiTypography-root{ 
  font-size: 15px;
  white-space: pre;
  color: #F02D3A;
  }



