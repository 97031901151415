.capitalize-label .MuiFormControlLabel-label {
  text-transform: initial !important;
}

.text-justify {
  text-align: justify !important;
}

.notification-wrap {
  white-space: normal !important;
}

.d-grid {
  display: grid !important;
}

.home-head {
  color: var(--main-dark-color);
  font-weight: bold !important;
}

.ml-1 {
  margin-left: 5px;
}

.ml-4 {
  margin-left: 16px !important;
}

.mt-3 {
  margin-top: 15px !important;
}

.mt-2 {
  margin-top: 10px !important;
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 20px !important;
}

.mr-4 {
  margin-right: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 5px !important;
}

.mb-3 {
  margin-bottom: 10px !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.p-2 {
  padding: 10px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mb-min5 {
  margin-bottom: -5px !important;
}

.font-card {
  font-weight: bold !important;
}

.wp-no {
  white-space: nowrap !important;
}

.ml-subs {
  margin-left: 35px;
}

.cardspan {
  font-family: "proxima_novaregular" !important;
  font-weight: 100 !important;
}

.dropw {
  width: auto !important;
}

.justify-content-start {
  justify-content: start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.Text-white {
  color: white !important;
}

.d-contents {
  display: contents !important;
}

/*  Profile details cammera icon */

.camera-img-divhc {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: -45px;
  margin-right: 5px;
  /* position: absolute;
  left: 227px;
  margin-top: -47px; */
}

/*  */
.approve-text {
  font-size: 1rem;
  color: #109648;
  font-weight: lighter !important;
}

.reject-text {
  font-size: 1rem;
  color: #f02d3a;
  font-weight: lighter !important;
}

/* /client/onboard */
.com-access span + span {
  padding-left: 40px !important;
}

/* list view and calender view tabs */

/* .custom-view-tab{
  width: 50% !important;
} */
/* .custom-view-tab1{
  width: 53% !important;
} */

.table-email-img img {
  width: 100% !important;
  height: 100px !important;
}

.interview-filter-grid {
  display: flex !important;
  margin-top: 0px !important;
}

@media only screen and (max-width: 320px) {
  .interview-filter-grid {
    display: grid !important;
  }

  .d-flexmob {
    display: grid !important;
  }

  .ml-mobile-2 {
    margin-left: 10px !important;
  }
}

@media only screen and (min-width: 321px) and (max-width: 1052px) {
  .interview-filter-grid {
    display: grid !important;
  }

  .d-flexmob {
    display: grid !important;
  }

  .ml-mobile-2 {
    margin-left: 10px !important;
  }

  .responsive-btn-sec a {
    width: 82px !important;
  }
}

/* @media only screen and (min-width:769px){
  .adsfas{
    display: grid !important;
  }
} */

.usermang-filter-icon:hover {
  background-color: #e3eefa;
  border-color: #0056b3;
  border-radius: 5px;
}

.usermang-filter-icon {
  cursor: pointer;
}

/* auto fill input  */
input:-webkit-autofill {
  -webkit-background-clip: text;
}

.edit-goal-cancel {
  background-color: white !important;
  color: #109648 !important;
}

.edit-goalt-delete-btn {
  border: none !important;
}

.demo-text {
  text-transform: none !important;
}

.table-th {
  white-space: pre;
}

/* super admin demo reqest staus column */
.lead-list-status {
  min-width: 238px;
}

.custom-action-colum {
  white-space: pre;
}

/* health carer induction */
.custom-induction-img {
  padding: 0px 15px 0px 8px;
}

.induction-profile-labels {
  color: #3d4244 !important;
  font-weight: 400 !important;
  font-size: var(--f-size-16) !important;
  font-family: "proxima_novaregular" !important;
}

.text-label {
  font-family: "proxima_novasemibold";
}

/* hc appointment list action column */
.custom-action-icon {
  margin-right: 8px !important;
}

/* SA list progressnote objective bg */
.custon-appointment-statusbg {
  background-color: #d0ece5 !important;
  white-space: pre;
  color: #40b195 !important;
  width: fit-content;
}

.edit-icon {
  color: #01c1eb !important;
  white-space: pre;
}

/* landing registration form */
.contained-button {
  margin: 10px 10px 10px 0px !important;
  background-color: #15a07d1a !important;
  color: #15a07d !important;
  border: 1px solid #15a07d !important;
  text-transform: inherit !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.outlined-button {
  margin: 10px 10px 10px 0px !important;
  color: #202e2a !important;
  border: 1px solid #e3e8e6 !important;
  text-transform: inherit !important;
  border-radius: 8px !important;
  font-size: 16px !important;
}

.custom-landing-form-fields input {
  background: none !important;
  border: 1px solid #e3e8e6 !important;
  color: #202e2a !important;
  font-family: "proxima_novaregular" !important;
  margin-bottom: -20px !important;
}

.Register-head-text {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #202e2a !important;
}

.custom-input input::placeholder {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.registration-first-heading {
  font-size: 22px;
}

.landing-img-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
}

.popup-head1 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.popup-head2 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.fdasfdfsa {
  font-size: 92px !important;
}

.val-msg {
  color: #f02d3a !important;
  font-size: 14px !important;
  font-family: "proxima_novaregular" !important;
}

.transform-box {
  padding: 12px !important;
}

.discover-head-text {
  margin-bottom: -20px !important;
}

.transform-box-text {
  margin-top: -30px !important;
}

.view-details-pickupshift {
  padding: 0 22px;
}

/* for mobile view */
@media only screen and (max-width: 600px) {
  .learn-div {
    text-align: center;
  }

  .get-touch-form {
    margin-left: 16px !important;
  }

  .HC-sign-head {
    margin-top: 60px !important;
    white-space: initial;
  }

  .HC-sign-form {
    padding: 20px;
  }

  .view-pro-resp {
    height: 30px;
  }

  .view-details-pickupshift {
    padding: 0 10px !important;
  }

  .explore-icon.MuiAvatar-root {
    height: 60px !important;
    width: 60px !important;
  }

  .mobile-profile-view {
    font-size: 18px !important;
  }

  .card-details-pickup {
    display: grid !important;
    white-space: nowrap !important;
  }

  .card-icon-pickup {
    margin-left: 0px !important;
  }

  .contact-submitbtn {
    display: flex;
    justify-content: center;
  }

  .responsive-btn-sec a {
    width: 105px !important;
  }
}

.last-box {
  height: 100px;
}

/* client personal details  */
.looking-labels span + span {
  padding-left: 38px !important;
}

.looking-div {
  margin-bottom: -40px !important;
}

/*  */
.leave-popup-icon {
  height: 15px;
  width: 15px !important;
}

.header-landing {
  background-color: #fff;
  padding: 10px;
}

.logo-landing {
  /* max-width: 200px;  */
  margin: 0 auto;
  /* Center the logo */
}

.logo-landing img {
  width: 100%;
  height: auto;
}

.HC-textfied-valmsg .css-k4qjio-MuiFormHelperText-root.Mui-error {
  position: absolute;
  margin-top: 40px;
  margin-left: 0px;
}

.safds {
  font-size: 32px !important;
}

.support-border {
  border: none !important;
}

.add-incident-link {
  margin-top: 30px;
}

.view-bowel-label label {
  font-family: "proxima_novaregular";
}

.aggrement-text {
  font-weight: 100;
  color: gray !important;
}

.dash-add-btn {
  color: white;
}

.dash-card-header {
  font-size: 14px !important;
  font-family: "proxima_novabold" !important;
}

.dash-card-number {
  font-size: 16px !important;
}

.interview-popup-head {
  font-size: 20px !important;
  font-weight: bold !important;
}

.textfied-aling .css-k4qjio-MuiFormHelperText-root.Mui-error {
  position: absolute !important;
  margin-top: 36px !important;
}

.sc-profile-camera {
  left: 215px !important;
}

.boder-btm {
  border-bottom: none !important;
}

.right-border {
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.table-col-width {
  min-width: 250px !important;
  white-space: normal;
}

.see-more-btn {
  border: none !important;
  background: none !important;
  color: var(--main-dark-color) !important;
  cursor: pointer;
}

.action-timesheet-btn {
  position: relative !important;
}

.timesheet-table-row th {
  white-space: nowrap;
}

.timesheet-col-space {
  white-space: nowrap;
}

.appointment-popup-text {
  color: gray !important;
  font-weight: 400 !important;
  font-size: 21px !important;
}

.appoint-btn-cancel {
  border: 1px solid gray !important;
  text-transform: capitalize !important;
  color: gray !important;
  height: 50px !important;
  font-size: 18px !important;
  background: #fff !important;
}

.appoint-btn-delayed {
  background-color: #15a07d !important;
  text-transform: capitalize !important;
  height: 50px !important;
  font-size: 18px !important;
}

.popup-warning-img {
  height: 100px !important;
  width: 100px !important;
}

.val-error {
  white-space: nowrap !important;
}

.chacked-view-inc {
  color: rgba(0, 0, 0, 0.26) !important;
}

.upload-view-img {
  margin-left: 25px;
}

.sajkdjsah {
  position: relative;
  min-height: 59vh;
}

.sdfjd {
  position: absolute !important;
  bottom: 0;
  width: 100%;
  /* Occupy full width */
  /* background-color: #f0f0f0; */
  padding: 10px;
  box-sizing: border-box;
}

.help-heading {
  font-size: "16px";
  font-family: "proxima_novabold";
}

.help-text {
  font-size: 16px !important;
}

.helpsearch {
  background: #1ec299ce !important;
  color: white;
}

.helpsearch input::placeholder {
  color: white !important;
  /* Change the color of the placeholder text to white */
  border: none !important;
}

.helpsearch .MuiInputAdornment-root .MuiSvgIcon-root {
  color: white;
  /* Change the color of the search icon to white */
}

.help-icon {
  height: 55px;
  width: 55px;
}

.helpsearch .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.help-heading:hover {
  background-color: #e8f6f2;
  /* Change background color on hover */
}

.help-heading:hover .help-icon {
  display: inline-block;
  /* Display the help icon on hover */
}

.link-icon {
  display: none;
  /* Initially hide the LinkIcon */
}

.help-heading:hover .link-icon {
  display: inline-block;
  /* Display the LinkIcon on hover */
}

.profile-dd-avtar {
  height: 30px !important;
  width: 30px !important;
  border-radius: 16px !important;
}

.cal-img {
  height: 25px;
}

.task-text {
  font-size: 14px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 20px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.pt-2 {
  padding-top: 20px !important;
}

.goal-task-comp.MuiButton-root {
  border-radius: 20px;
  text-transform: capitalize;
  border-color: #109648 !important;
  color: #109648 !important;
  height: 28px;
  margin-right: -15px;
}

.goal-task-started.MuiButton-root {
  border-radius: 20px;
  text-transform: capitalize;
  border-color: #00a7e1 !important;
  color: #00a7e1 !important;
  height: 28px;
}

.goal-task-pending.MuiButton-root {
  border-radius: 20px;
  text-transform: capitalize;
  border-color: #ffc700 !important;
  color: #ffc700 !important;
  height: 28px;
}

.expenses-chart-header {
  font-size: 14px !important;
  font-family: "proxima_novaregular" !important;
  text-transform: capitalize !important;
}

.expenses-br-chart {
  margin-left: 80px;
}

.expenses-main-section {
  background-color: #d0ece5;
  padding: 13px 80px 20px 35px;
  box-shadow: none;
  width: 100%;
  margin-bottom: 19px;
  border-radius: 7px;
}

.expenses-main-according {
  margin-top: -35px;
}

.routines-main-scroll {
  height: 400px;
  overflow-y: auto;
}

.acc-border {
  border-bottom: 1px solid #8080804d;
  box-shadow: none;
}

.demo-back {
  background: #15a07d !important;
  height: 550px !important;
}

.demo-container {
  background: white !important;
  border-radius: 10px !important;
  padding: 25px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.renew-agree {
  background-color: white !important;
  margin-top: 55px !important;
}

body .uwy.userway_p1 .userway_buttons_wrapper {
  top: auto !important;
  right: auto !important;
  bottom: 20px !important;
  left: 20px !important;
  transform: none !important;
  /* background-color: #15A07D !important; */
}

/* body .uwy.userway_p1 .userway_buttons_wrapper .userway_button {
  background-color: #15A07D !important;
} */

.schedule-btn {
  text-transform: capitalize !important;
  background-color: #645dd7 !important;
  height: 56px;
  color: #fff !important;
  font-size: 16px !important;
  border-radius: 5px !important;
  font-family: "proxima_novasemibold" !important;
}

.text-right {
  text-align: right !important;
}

.dropdown-parent {
  position: relative;
  z-index: 10000;
  /* Adjust as needed */
}

/* Ensure popup has a lower z-index than dropdown's parent */
.calenderdata-popup {
  position: absolute;
  z-index: 9999;
}

.reject-text {
  color: #f02d3a !important;
}

.approve-text {
  color: #109648 !important;
}

.aad-avai .rc-time-picker-input {
  height: 40px !important;
  /* width: 150px; */
  font-size: 15px;
}

.w-50 {
  width: 50% !important;
}

.sil-card {
  background: rgba(100, 93, 215, 0.15);
  padding: 20px;
  border-radius: 10px;
}

.sil-textfield {
  background: white !important;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.accepted-circle {
  background-color: rgba(197, 255, 241, 1);
}

.rejected-circle {
  background-color: rgba(255, 221, 223, 1);
}

.pending-circle {
  background-color: rgba(255, 243, 200, 1);
}

.completed-circle {
  background-color: rgba(175, 254, 208, 1);
}

.cancel-circle {
  background-color: rgba(255, 226, 200, 1);
}

.status-border {
  border: 1px solid gray;
  padding: 5px 15px 5px 15px;
  border-radius: 18px;
}

.custom-search input {
  padding-right: 20px !important;
}

.custom-closeIcon-sil {
  color: #f02d3a;
}

.custom-total-sil-bold {
  font-weight: bold;
}

.editable {
  color: #645dd7 !important;
}

.time-action .time-action-option {
  transform: translate(-7px, -32px) !important;
  /* transform: translate(-18px, -1px) !important; */
  position: sticky !important;
}

.agency-add .sdhg:hover {
  color: #109648 !important;
}

.client-datepick input {
  text-align: justify !important;
  padding: 7px 0 7px 10px !important;
}

.chartview-um {
  height: 20px;
}

.dsf {
  padding: 30px 0px 11px 5px !important;
}

.personallables {
  padding: 30px 0px 11px 5px !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  color: transparent !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  color: transparent !important;
}

.customexportbtn {
  min-width: 80px !important;
}

.custom-text > div {
  display: block !important;
}

.customdate {
  width: 150% !important;
}

.field-height input {
  height: 0.4375em !important;
  font-size: 13px !important;
}

.delayed-filter .input-group {
  bottom: -46px !important;
}

.camera-delete-divhc {
  /* color: red; */
  position: relative;
  bottom: 93%;
  left: 75%;
}

.camera-delete-div-bg {
  padding: 9px !important;
  background: #fff !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.camera-avatar-hc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.avatar-hc-label {
  color: #15a07d !important;
  font-size: 15px !important;
}

.text-black {
  color: black !important;
}

.import-circle {
  background: #15a07d !important;
  border-radius: 25px;
  padding: 13px;
  width: 45px;
  margin-bottom: 10px;
  color: white;
}

.pop-heading {
  background-color: #e8f6f2 !important;
  /* padding: 10px; */
}

.pop-content-text p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.steps-view.MuiButton-root {
  background-color: #e8f6f2 !important;
  color: #15a07d;
  text-transform: capitalize;
}

.downcsvfile.MuiButton-root {
  text-transform: capitalize;
  background-color: #15a07d !important;
  color: white;
}

.downcsvfile.MuiButton-root svg {
  color: white;
}

.downcsvfile-link {
  color: #121212;
}

.view-checked {
  color: rgba(0, 0, 0, 0.26) !important;
}

.dissable-action {
  color: #a3a3a3 !important;
}

.subs-head-card {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  padding: 20px;
  border-radius: 10px;
  background: white;
}

.h-100 {
  height: 100%;
}

.swap-btn {
  color: #924cbd !important;
}

.induction-table-action {
  transform: translate3d(-119px, 5.4px, 0px) !important;
}

.view-history-client-card {
  width: 80% !important;
}

.subs-background {
  background: #645dd7 !important;
  padding: 1px 50px 50px !important;
  border-radius: 20px !important;
}

.w-90 {
  width: 90% !important;
}

.tooltiparro {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

/* ***************************tooltip css*************************** */
.tooltiparro .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: white;
  color: black;
  /* text-align: center; */
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  /* white-space: nowrap; */
  z-index: 1;
  bottom: 150%;
  left: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* margin-left: -60px; */
  padding: 10px;
}

.tooltiparro .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: inherit;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.tooltiparro:hover .tooltiptext {
  visibility: visible;
}

.d-flexmob {
  display: flex;
}

.appointscroll .css-1aquho2-MuiTabs-indicator {
  background-color: white !important;
}

.sp-content-text {
  margin-bottom: 10px !important;
}

.btn-image {
  height: 25px !important;
  margin-right: 5px;
}

.Text-green {
  color: #109648 !important;
}

.chat-card {
  box-shadow: none !important;
  border-bottom: 1px solid rgb(194, 192, 192) !important;
  border-radius: 0px !important;
}

.mb-m1 {
  margin-bottom: -5px !important;
}

.responsive-btn-sec a {
  width: 105px;
}

.new-class-stepper {
  width: 100% !important;
  padding: 30px;
}

.service-task-notes-mb {
  margin-bottom: -3px;
}
