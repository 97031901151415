#appointment-details .add-expenses {
  max-width: 826px;
  width: 100%;
  margin-bottom: 50px;
}

.rotate-arrow {
  transform: rotate(180deg) !important;
}

.add-description svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;
}

.add-description svg path {
  color: #01c1eb;
}

.add-description p {
  color: #01c1eb;
  cursor: pointer;
  font-size: var(--f-size-16);
  font-weight: 400;
  font-family: "proxima_novasemibold";
}

#appointment-details .input label {
  padding-bottom: 0;
}

#appointment-details .add-description {
  padding-bottom: 20px;
}

#appointment-details .text-area-box {
  margin-top: 20px;
  margin-bottom: 8px;
}

#appointment-details .text-area {
  min-height: 63px;
  height: 100% !important;
}

#appointment-details .agency-btn-section {
  margin-bottom: 0;
}

#appointment .dashboard-main-box {
  padding-right: 0px !important;
}

.check-text-area {
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

.check-text-area-box {
  margin-top: 0 !important;
}

.appoint-check label {
  padding-bottom: 0 !important;
}

#agreement .check span {
  color: #01c1eb;
}

#agreement .check input {
  color: #01c1eb;
}

#agreement .agreement-box {
  margin-bottom: 50px;
}

.bg-strip {
  background: #dae5eb;
  padding: 14px 32px;
}

.bg-strip p {
  font-size: 20px;
  /* color: #3d4244 !important; */
  font-weight: 600;
  font-family: "proxima_novasemibold";
}

.add-appointment-input-box {
  padding: 0 80px 0 32px;
}

.hide-client-details {
  margin: 30px 0 37px 0;
  background-color: #f6f6f6;
  padding-top: 0;
  padding-bottom: 14px;
  box-shadow: 0 0 15px rgba(22, 22, 22, 0.1);
}

.hide-client-arrow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.hide-client-arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #006ca6;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.hide-box {
  margin-top: 45px;
}

.hide-client-img-box {
  border-right: 1px solid #dae5eb;
}

.hide-client-img {
  width: 136px;
  height: 136px;
}

.hide-client-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hide-client-img-box p {
  font-size: 13px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
  color: #3d4244;
  padding-top: 10px;
}

.hide-content {
  padding-bottom: 29px;
}

.hide-content p {
  font-size: var(--f-size-16);
  font-weight: 400;
  color: #3d4244;
}

.hide-client-drop {
  padding-left: 32px;
}

.hide-content span {
  color: #929495;
}

.hide-content a {
  color: #006ca6;
  font-size: var(--f-size-16);
  font-weight: 400;
  font-family: "proxima_novasemibold";
}

.hide-client-img-main {
  margin-left: 40px !important;
}

.hide-content .mapicon {
  width: 24px;
  height: 24px;
}

.hide-content .mapicon img {
  width: 100%;
}

.hide-client-progress {
  width: 90%;
}

#add-appointment .add-appointment-input-box {
  margin-top: 0;
}

#add-appointment .service-strip {
  margin: 32px 0;
}

#add-appointment .add-appoint-radio-group {
  padding-left: 60px;
}

#add-appointment .radio {
  margin-right: 40px;
}

#add-appointment .checkbox-padding {
  padding-left: 32px;
}

.dashboard-form .add-location-btn {
  border: 1px solid #01c1eb;
  border-radius: 10px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}

.dashboard-form .add-location-btn img {
  margin-right: 10px;
}

.client-map {
  padding: 0 20px 0 20px;
  margin-top: 20px;
}

.skill-box {
  padding: 0 80px 40px 32px;
  margin-top: 20px;
  border-bottom: 1px solid #dae5eb;
}

#add-appointment .skill-box p {
  color: #3d4244;
}

#add-appointment .add-appoint-support-coordinator {
  padding-bottom: 40px;
}

#add-appointment .add-routine-btn {
  font-size: 20px;
  font-weight: 600;
  font-family: "proxima_novasemibold";
  width: 173px;
  height: 40px;
  background: #15a07d;
  border-radius: 8px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.routine-stepper-box {
  padding: 0 80px 0 32px;
}

.routine-stepper-box .routine-chip {
  background: rgba(100, 93, 215, 0.1);
  border-radius: 4px;
  color: #645dd7;
  width: auto !important;
  padding: 6px 8px !important;
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.routine-stepper-box .routine-chip img {
  margin-left: 0px;
}

.routine-stepper-box .routine-chip svg path {
  color: #ff5251;
}

.other-details {
  padding: 0 80px 0 32px;
}

.other-details p {
  color: #3d4244;
}

.other-details .add-settings {
  color: #01c1eb;
}

.other-details .other-textarea {
  height: 100px;
}

.other-details .other-textarea input {
  height: 100%;
}

.other-details .other-textarea .MuiInputBase-formControl {
  height: 100%;
}

#appoint-stepper .MuiStepLabel-horizontal {
  flex-direction: column-reverse;
}

#appoint-stepper .stepper-circle .MuiStepLabel-iconContainer {
  width: 32px;
  height: 32px;
}

#appoint-stepper .stepper-circle .MuiStepLabel-iconContainer svg {
  width: 100%;
  height: 100%;
}

#appoint-stepper .MuiStepConnector-horizontal {
  margin: 22px 0 0 -10px;
}

.routine-stepper-box .stepper-add-task-btn {
  width: 132px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #645dd7;
  color: #645dd7;
  border-radius: 6px;
  background: none;
  margin-left: 60px;
}

.routine-stepper-box .stepper-add-task-btn svg {
  margin-right: 5px;
}

.routine-stepper-box .add-task-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  margin-bottom: 20px;
}

.appointbottom .appointinr {
  justify-content: space-between;
  align-items: center;
}

.btn-upsidebox .d-flex {
  justify-content: space-between;
  align-items: center;
}

.appointExpand {
  display: inline-block;
  padding-left: 10px;
}

.routine-stepper-box .add-task-item p {
  /* color: #006ca6; */
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
  /* border-bottom: 1px solid #006ca6; */
  margin-bottom: 6px;
}

.task-num {
  color: #006ca6;
}

.addtask {
  background-color: transparent;
  /* border: 1px solid #006ca6; */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done-task {
  background-color: #15a07d !important;
  color: white !important;
}

.done-task span {
  color: #fff;
}

.appointment-table {
  overflow-x: scroll !important;
}

/* .appointment-table::-webkit-scrollbar {
  display: none !important;
} */

.routine-stepper-box .add-task-item img {
  width: 16px;
  height: 16px;
  margin-top: 6px;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.verified-none {
  display: none !important;
}

.routine-stepper-box .add-task-item svg {
  width: 16px;
  height: 16px;
  margin-top: 6px;
  color: #1e9f12;
}

.routine-stepper-box .connector-line {
  width: 100px;
  height: 1px;
  /* background-color: #006ca6; */
  margin-bottom: 9px;
}

.routine-stepper-box .connector-line2 {
  width: 100px;
  height: 1px;
  background-color: #15a07d;
  margin-bottom: -10px;
}

/* progress bar css start here  */

.dashboard-form .progress-bar {
  background: none;
  border: 0.5px solid #1e8e14;
  border-radius: 8px;
  height: 8px;
}

.progress-bar span {
  border-radius: 8px;
  background: #1e8e14;
}

.radio-box {
  flex-wrap: nowrap !important;
}

.tab-box {
  padding-left: 32px !important;
}

.view-list-search {
  max-width: 500px;
  width: 100%;
}

.view-list-search input {
  width: 100%;
}

#schedule-apointment .agency-table {
  margin-top: 0 !important;
  overflow-x: auto;
}

.view-list-search .MuiTextField-root {
  width: 100% !important;
}

.appointment-cell {
  min-width: 120px;
}

.appointment-cell-item {
  min-width: 160px;
}

.table-team {
  position: relative;
}

.table-team img {
  width: 32px !important;
  height: 32px !important;
  object-fit: cover !important;
}

.team-cell {
  position: relative;
  cursor: pointer;
}

.table-icon {
  width: 20px;
  height: 20px;
  margin: auto;
  position: relative;
}

.table-icon-popover {
  position: absolute;
  top: -39px;
  left: 0;
  width: 100px;
  border-radius: 4px;
  padding: 4px 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  display: none !important;
}

.table-icon .team-popover-arrow {
  bottom: -32px;
}

.table-icon:hover .table-icon-popover {
  display: flex !important;
}

.table-icon-popover img {
  width: 20px !important;
  margin-right: 8px;
}

.table-icon-popover p {
  font-size: 16px;
  font-weight: 500;
  font-family: "proxima_novasemibold";
}

.table-icon img {
  width: 100%;
  height: 100%;
}

.team-popover {
  position: absolute;
  top: -8ipx;
  z-index: 999;
  min-width: 162px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  border-radius: 4px;
  padding: 6px;
  display: none;
}

.team-popover1 {
  left: 8px;
}

.team-popover2 {
  left: 42px;
}

.team-popover3 {
  left: 75px;
}

.teamImg1 {
  width: 40px;
  height: 14px;
}

.teamImg2 {
  width: 40px;
  height: 14px;
}

.teamImg4 {
  width: 40px;
  height: 40px;
}

.teamImg3 {
  width: 40px;
  height: 14px;
}

.teamImg1 {
  border-radius: 22% !important;
  width: 49px !important;
  height: 14px !important;
  object-fit: contain !important;
}

/* .teamImg2{
    border-radius: 22% !important;
    width: 40px !important;
    height: 14px !important;
} */
.teamImg3 {
  border-radius: 40% !important;
  width: 41px !important;
  height: 25px !important;
  object-fit: contain !important;
}

.img-avatar {
  width: 45px !important;
  height: 15px !important;
}

.teamImg1:hover ~ .team-popover1 {
  display: block !important;
}

.teamImg2:hover ~ .team-popover2 {
  display: block !important;
}

.teamImg3:hover ~ .team-popover3 {
  display: block !important;
}

.teamImg4:hover ~ .team-popover4 {
  display: block !important;
}

.team-popover-img {
  width: 56px;
  height: 56px;
}

.team-popover-img img {
  width: 100%;
  height: 100%;
}

.team-popover-text {
  width: calc(100% - 58px);
  padding-left: 10px;
}

.popover-team-img .team-popover-img {
  width: 34px;
  height: auto;
}
.popover-team-img .team-popover-text {
  width: calc(100% - 34px);
  padding-left: 5px;
}
.popover-buttons .details-popover-button {
  color: #006ca6;
}
.team-popover-text p {
  color: #006ca6;
  font-size: 14px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
  border-bottom: 1px solid #006ca6;
  display: inline-block;
}

.team-popover-text span {
  color: #929495;
  font-size: 13px;
}

.team-popover-text span,
.team-popover-text p {
  font-weight: 400;
  font-family: "proxima_novasemibold";
}

.team-popover-box {
  position: relative;
}

.team-popover-arrow {
  position: absolute;
  color: #fff;
  left: -11px;
  bottom: -37px;
  z-index: 1;
  font-size: 55px !important;
}

.time-cell p,
.time-cell span {
  font-weight: 400;
  font-family: "proxima_novasemibold";
}

.time-cell p {
  font-size: 14px;
  color: #3d4244;
}

.time-cell span {
  font-size: 13px;
  color: #929495;
}

.routine-cell {
  min-width: 200px;
  padding-bottom: 0 !important;
}

#appointment-table .routine-cell-btn {
  width: auto;
  padding: 3px 5px;
  text-align: center;
  background: #d0ece5 !important;
  border-radius: 4px;
  border: none;
  color: #15a07d;
  cursor: pointer;
  font-family: "proxima_novasemibold";
  font-size: 12px !important;
}

#appointment-table button svg {
  width: 15px;
  margin-right: 8px;
}

#appointment-table .routine-cell-task-btn {
  border: none;
  background-color: transparent;
  color: #645dd7;
  font-family: "proxima_novasemibold";
  font-size: 12px;
}

.search-appointment .MuiInputBase-formControl {
  border-radius: 20px !important;
  border-color: #dae5eb !important;
}

.agreement-body .form-check-label {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding-left: 25px;
  display: block;
}

.agreement-body .form-check input {
  width: 16px;
  height: 16px;
  float: left;
  margin-top: 4px;
}

.agreement-body .form-check {
  padding: 6px 0;
}

.routine-cell-sub-box {
  width: 100%;
  border: 0.5px solid #dae5eb;
  border-radius: 8px;
  padding: 5px;
  margin-top: 6px;
}

.routine-sub-box-text {
  width: calc(100% - 51px);
}

.routine-sub-box-text p {
  font-size: 12px;
  color: #3d4244;
  font-family: "proxima_novasemibold";
}

.routine-sub-box-text span {
  font-size: 12px;
  color: #006ca6;
  font-family: "proxima_novasemibold";
  font-weight: 400;
  border-bottom: 0.8px solid #006ca6;
  line-height: 10px;
}

.appointment-status {
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 40px;
}

.status-box {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  box-shadow: 0 0 4px rgb(223, 217, 217);
}

.routine-task {
  width: 50px;
  justify-content: flex-end;
}

.routine-task {
  font-size: 12px;
  color: #645dd7;
  border: none;
  background: none;
  box-shadow: none;
  font-family: "proxima_novasemibold";
}

#appointment-table .table-editcell {
  position: relative;
  min-width: 100px;
}

#appointment-table .table-edit {
  color: #645dd7 !important;
}

#appointment-table .table-appt-delete {
  color: red !important;
}

#appointment-table .table-appt-view {
  color: #00a7e1 !important;
}

#appointment-table .table-edit svg {
  width: 16px;
  margin-right: 8px;
}

#appointment-table .view-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

#appointment-table .view-icon img {
  width: 100%;
}

#view-all {
  display: none;
}

.show {
  display: block !important;
}

#appointment-table .table-del p {
  color: #006ca6;
  font-size: 13px !important;
}

#appointment-table .view-all p {
  color: #929495;
  font-size: 12px;
}

#appointment-table .view-all svg {
  width: 20px;
}

.fliter-img {
  width: 16px;
  margin-left: 10px;
}

.tableHeader {
  position: relative;
}

.tableHeader .input-group {
  position: absolute;
  bottom: -18px;
  left: 0;
  min-width: 222px;
  width: 100%;
  height: 32px;
  z-index: 999;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #01c1eb;
  background: #fff;
}

.tableHeader .input-group input {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
  outline: none !important;
}

.filter-search-icon {
  margin-right: 0;
  width: 20px;
}

.search-img {
  padding-right: 15px;
}

.drop-icon {
  color: #006ca6 !important;
  position: absolute;
  top: 0;
  right: 0;
}

#appointment-table .tableHeader {
  padding: 8px 10px;
}

.searchbtn-wrap {
  padding-bottom: 20px;
}

.search-field .btn-primary {
  min-width: 100px;
  margin-left: 15px;
}

.checkbox-img {
  width: 24px;
  margin-right: 10px;
}

.tableSearchField .MuiOutlinedInput-notchedOutline {
  border: none;
  box-shadow: none;
}

#searchFilter .MuiAutocomplete-popper {
  width: 100% !important;
  left: -10px !important;
}

/*  Task Page */

.appoint-task-wrap {
  display: flex;
  padding: 0;
  overflow-x: scroll;
  margin: 0 -15px;

  /* @media only screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
}

.appoint-box {
  max-width: 380px;
  padding: 0 15px;
  flex-shrink: 0;
  width: 100%;
  flex: 0 0 auto;
}

.taskBox {
  margin-bottom: 10px;
  width: auto;
}

.taskBox .appointboxheader {
  background-color: #ffe27b;
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
}

.taskTitle,
p.tasktime {
  color: #3d4244;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  text-decoration: underline;
}

p.tasktime {
  text-decoration: none;
}

.tasktime svg {
  max-width: 20px;
  margin-right: 6px;
}

.appointboxheader > div {
  display: flex;
}

.appointboxheader .Header-title {
  font-size: 13px;
  color: #3d4244;
  font-size: 14px;
  font-weight: 500;
}

.appointcontent {
  border: 1px solid #dae5eb;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  padding: 15px;
}

.appointbottom {
  border: 1px solid #dae5eb;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 15px;
}

.appointcontent h3 {
  font-size: 14px;
  color: #3d4244;
  margin-bottom: 15px;
  font-family: "proxima_novathin";
}

.appointcontent .attach-document-browse {
  flex-direction: column !important;
}

.appointcontent .attach-document-browse .MuiTextField-root {
  max-width: 100%;
}

.appointcontent .attach-document-browse button {
  max-width: 120px;
  margin-top: 15px;
}

.notes-section {
  margin-top: 20px;
}

.note-title {
  margin-bottom: 10px;
  justify-content: space-between;
}

.note-title a {
  color: #006ca6;
  font-family: "proxima_novasemibold";
}

.note-title h3 {
  margin-bottom: 0;
}

.note-link a {
  color: #01c1eb;
  font-family: "proxima_novabold";
}

.note-link img {
  width: 22px;
  margin-right: 10px;
}

.profiletxt {
  display: flex;
}

.profiletxt p {
  padding: 0 10px;
  color: #929495 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.notes-list {
  padding: 10px 0;
}

.notes-list .srno {
  color: #929495 !important;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.profiletxt img {
  max-width: 20px;
  margin-left: 10px;
  border-radius: 30px;
  height: 20px;
}

.notes-list .notes-inr:nth-child(odd) {
  background-color: #ffd2d1;
}

.notes-list .notes-inr {
  padding: 6px;
  border-radius: 5px;
}

/* appointment dashboard css  */

.appointment-detail {
  padding: 20px 0 20px 20px;
}

.appointment-dashboard-profile img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 0.7px solid #01c1eb;
  margin-right: 8px;
}

.appointment-dashboard-profile p {
  font-size: 16px;
  font-weight: 500;
  color: #3d4244;
}

.appointment-dashboard-profile span {
  color: #929495;
  font-size: 13px;
  font-weight: 300;
}

.appointment-dashboard-timebar {
  padding-right: 80px;
}

.appointment-dashboard-timebar p {
  color: #929495;
  font-size: 16px;
  font-weight: 500;
  margin-left: 10px;
}

#Appointment-Dashboard .approved-leave-section {
  background: rgba(218, 229, 235, 0.35);
}

#Appointment-Dashboard .approved-heading img {
  margin-right: 20px;
}

#Appointment-Dashboard #play-icon {
  margin-left: 60px;
}

#Appointment-Dashboard .approved-leave-section .tasksAssigned p,
#Appointment-Dashboard .approved-leave-section .tasksAssigned span {
  font-size: 16px;
  font-weight: 400;
}

#Appointment-Dashboard .approved-leave-section .tasksAssigned p {
  color: #3d4244;
}

#Appointment-Dashboard .approved-leave-section .tasksAssigned span {
  color: #929495;
}

#Appointment-Dashboard .approved-leave-section .addTask-btn a {
  color: #fff;
  background: #006ca6;
  width: 118px;
  border-radius: 8px;
  padding: 8px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "proxima_novasemibold";
}

.badgeTxt {
  background: #ff5251;
  border-radius: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 18px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.galleyinr {
  align-items: center;
  justify-content: space-around;
  margin-left: -10px;
  margin-top: 10px;
}

.galleyinr img {
  min-width: 20%;
}

.galleyimg {
  position: relative;
}

.galleyimg .viewgallery {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "proxima_novasemibold";
  font-size: 13px;
}

/* Task Status

pendingtaskbox
starttaskbox
completetaskbox
rejectedtaskbox 
*/

.pendingtaskbox .appointboxheader {
  background-color: #ffe27b;
}

.starttaskbox .appointboxheader {
  background-color: #c9c4ff;
}

.completetaskbox .appointboxheader {
  background-color: #a4ffb3;
}

.rejectedtaskbox .appointboxheader {
  background-color: #ffa5a5;
}

/* .is-hidden{ 
    display: none !important;
} */

.table-head-cell:nth-child(1) .fliter-img,
/* .table-head-cell:nth-child(8) .fliter-img, */
.table-head-cell:nth-child(10) .fliter-img,
.table-head-cell:nth-child(12) .fliter-img {
  display: none;
}

.filter-search-field:nth-child(1) .input-group,
/* .filter-search-field:nth-child(8) .input-group, */
.filter-search-field:nth-child(10) .input-group,
.filter-search-field:nth-child(12) .input-group {
  display: none !important;
}

.hide-field {
  display: none !important;
}

.activeFiled {
  display: flex !important;
}

.filter-search-field .dropdown {
  line-height: 1.5;
  height: 15px;
}

.filter-search-field .dropdown-toggle {
  background: none !important;
  position: static !important;
  height: 15px;
}

#document-table .filter-search-field .dropdown-menu {
  display: none;
  position: absolute;
  top: 34px !important;
  background: none !important;
  left: 0 !important;
  z-index: 1;
  transform: none !important;
  border: none !important;
  border-radius: 10px;
  padding: 8px 10px;
  width: 136px;
}

.tableSearchField svg path {
  fill: #006ca6;
}

.tableSearchField svg {
  width: 20px !important;
}

#tabs .checkbox-label {
  margin-right: 0 !important;
}

.appointment-calender-filed-box .appointment-calender-filed-select {
  width: 115px;
}

.appointment-calender-filed-box .appointment-calender-filed-select label {
  top: -7px;
}

.slide-filed {
  min-width: 166px;
}

.appointment-calender-filed-box
  .appointment-calender-filed-select
  .MuiOutlinedInput-notchedOutline {
  border-color: #006ca6;
}

.appointment-calender-filed-box
  .appointment-calender-filed-select
  .MuiOutlinedInput-notchedOutline:hover {
  border-color: #006ca6;
}

.appointment-calender-filed-box .appointment-calender-date-filed {
  max-width: 250px;
  width: 100%;
  margin-left: 20px;
  border: 1px solid #006ca6;
  border-radius: 4px;
}

.appointment-calender-date-filed .date-input-filed {
  width: auto;
  margin: 0;
}

/* .date-input-filed input {
  padding: 7px 14px;
  text-align: center;
  min-height: 26px;
} */

.mr-5 {
  margin-right: 5px !important;
}

.appointment-calender-date-filed
  .date-input-filed
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

#tabs .appointment-calender-date-filed .MuiPickersPopper-root > .MuiPaper-root {
  position: absolute;
  background: #faeaea;
  top: 10px;
  border-radius: 20px;
  left: -200px;
}

[data-popper-placement~="bottom-end"],
[data-popper-placement~="bottom"] {
  /* margin: 10px 160px 0 0 !important; */
  background-color: #fff !important;
}

[data-popper-placement~="bottom-end"] .MuiPickersPopper-paper,
[data-popper-placement~="bottom"] .MuiPickersPopper-paper {
  border-radius: 28px;
  background: #fff !important;
  box-shadow: 0 0 15px #f9f9f9;
  border: 1px solid #f1f1f1;
}

[data-popper-placement~="bottom-end"] [type~="button"],
[data-popper-placement~="bottom"] [type~="button"] {
  background-color: transparent;
}

[data-popper-placement~="bottom-end"] [type~="button"].Mui-selected,
[data-popper-placement~="bottom"] [type~="button"].Mui-selected {
  background-color: #15a07d !important;
  border-radius: 50% !important;
}

.slidertext-sty {
  width: 140px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 7px 10px !important;
  margin: 6px !important;
  border: 1px solid #006ca6;
  border-radius: 6px !important;
}

.slidertext-sty p {
  color: #3d4244 !important;
}

.appointment-calender-filed-select legend {
  display: none;
}

.appointment-calender-filed-select fieldset {
  height: 38px;
  top: 0px;
}

.appointment-calender-filed-box {
  margin-top: 10px;
}

.rotateTaskIcon {
  transform: rotate(180deg);
}

.select-wrap .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.helpsvg {
  color: #929495 !important;
  width: 13px !important;
  margin-left: 6px;
  cursor: pointer;
}

.helpIcon {
  position: relative;
}

.note-popover {
  position: absolute;
  bottom: 29px;
  left: 0;
  padding: 8px;
  width: 288px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  border-radius: 4px;
  z-index: 2;
  display: none;
}

.note-popover p {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
}

.note-popover > div {
  position: relative;
}

.note-popover:before {
  content: "";
  border-top: 10px solid #ffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  bottom: -10px;
  left: 6px;
}

.helpIcon:hover .note-popover {
  display: block;
}

.select-style {
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}

.select-box-1 {
  color: #ffdb5b;
  background-color: #ffdb5b;
}

.select-box-2 {
  color: #ccf4f8;
  background-color: #ccf4f8;
}

.select-box-3 {
  color: #a4ffb3;
  background-color: #a4ffb3;
}

.select-box-4 {
  color: #c9c4ff;
  background-color: #c9c4ff;
}

.select-box-5 {
  color: #ffa5a5;
  background-color: #ffa5a5;
}

.select-box-6 {
  color: #f8a6ffe8;
  background-color: #f8a6ffe8;
}

.select-box-7 {
  color: #5bd8ff;
  background-color: #5bd8ff;
}

#demo-multiple-checkbox {
  display: flex;
  align-items: center;
  padding-left: 0;
}

#gallary-dialog .add-expenses {
  max-width: 800px;
  width: 100%;
  background: none;
  box-shadow: none;
  margin: 0;
}

#gallary-dialog .add-expenses img {
  width: 100%;
  object-fit: cover;
}

.gallaryBox .MuiDialog-paperScrollPaper {
  background: none !important;
  box-shadow: none !important;
  overflow: unset !important;
}

.gallaryBox .MuiDialog-paperScrollPaper .modal-text {
  overflow-y: unset !important;
}

/* Appointment Start View page*/
.finish-btn {
  padding: 8px 29px;
  background-color: #ff5251;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
}

.location-modal {
  padding: 39px 50px 0 50px;
}

.location-modal-btn {
  margin-top: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.face-scan {
  width: 110px;
  margin: auto;
  display: block;
}

.face-capture-btn {
  width: 200px;
  text-align: center;
  padding: 8px 0;
  background: none;
  border: 0.5px solid #006ca6;
  border-radius: 8px;
  margin: 40px 0;
  color: #006ca6;
}

.dashboard-form-typo-button .swap-button {
  padding: 10px 31px;
  font-size: 16px;
  border: 1px solid #006ca6;
  color: #006ca6;
  background: none;
  border-radius: 8px;
  margin-left: 30px;
  cursor: pointer;
}

.dashboard-form-typo-button .start-button {
  padding: 10px 31px;
  font-size: 16px;
  color: #fff;
  background: #006ca6;
  border-radius: 8px;
  border: none;
  margin-left: 30px;
  cursor: pointer;
}

@media (max-width: 1320px) {
  #add-appointment .skill-box p {
    font-size: 14px;
  }

  .skill-box {
    padding-right: 0px;
  }

  .no-wrap {
    flex-wrap: nowrap !important;
  }

  .tab-box {
    padding-left: 10px !important;
  }
}

/* task-pending slider css  */

ul {
  list-style-type: none;
  padding: 0;
}

.carousel__wrap {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 100%;
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(90rem);
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(330rem);
}

.carousel__slide-item {
  display: inline-block;
  height: 38rem;
  margin: 0;
  position: absolute;
  transition: all 0.3s;
  width: 38rem;
}

.carousel__slide-item-img-link {
  cursor: pointer;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}

.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}

.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.2rem 0.2rem 0;
  height: 14px;
  padding: 3px;
  width: 14px;
  z-index: 10;
}

.carousel__btn-arrow--left {
  transform: rotate(135deg);
}

.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}

.gallary-dialog-head {
  background-color: #ebebeb;
  width: 100%;
  padding: 10px 20px;
}

.gallary-dialog-head .arrow-slide-box {
  width: 100px;
  margin: auto;
  margin-left: 120px;
}

.gallary-dialog-head .arrow-slide-box span,
.gallary-dialog-head .arrow-slide-box div,
.gallary-dialog-head p {
  color: #3d4244;
  font-size: 16px;
  font-weight: 400;
}

/* *****************************/

/* new css  */

/* *****************************/

.swapped-text span,
.swapped-text p {
  font-size: 12px;
  color: #3d4244;
}

.swapped-text span {
  font-weight: 400;
}

.swapped-text p {
  font-weight: 600;
}

.d-none {
  display: none;
}

.taskPopover {
  position: absolute;
  top: 23px;
  left: -2px;
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  width: 190px;
  display: none;
  z-index: 2;
}

.taskPopover img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.taskPopover p {
  font-size: 12px;
  color: #3d4244;
  font-weight: 400;
  text-align: left;
  padding-left: 12px;
  line-height: 15px;
}

.taskPopover .taskPopover-overflow {
  overflow-y: scroll;
  height: 56px;
}

.taskPopover .taskPopover-overflow div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.badgeTxt:hover .taskPopover {
  display: block;
}

.taskPopover:before {
  content: "";
  border-bottom: 10px solid #ffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  left: 6px;
  top: -10px;
}

.routine-overflow {
  overflow-y: hidden;
  margin-bottom: 5px;
  height: 60px;
}

.view-all {
  font-size: 13px;
  color: #929495;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.routine-overflow-hide {
  overflow-y: scroll;
  height: 239px;
}

.attach-document-body img {
  margin: 0 10px;
}

.view-all {
  font-size: 13px;
  color: #929495;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.view-all p {
  color: #8c8c8c !important;
}

.routine-overflow-hide {
  overflow-y: scroll;
  height: 239px;
}

.taskBox .attach-document-browse {
  margin-top: 10px;
}

.uploaddoc button {
  background: #e2ecf2;
  color: #006ca6;
  border-radius: 4px;
}

.modal-slider-close {
  position: absolute;
  top: -26px;
  right: 0;
  color: #fff;
}

.modal-slide-arrow {
  width: 15px !important;
}

.carousel__btn--next {
  margin-left: 5px;
}

/* edit list css start here   */

.select-style {
  margin-left: 10px;
}

.Task-list-main {
  padding: 0 80px 0 35px;
  margin-top: 100px;
}

.frequency-times .times {
  width: 50px;
}

.frequency-radio label span:nth-child(1) {
  padding-left: 0;
}

.frequency-times .times .MuiInputBase-sizeSmall {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.frequency-times .times {
  margin-right: 8px;
}

.task-edit-attach-document {
  background-color: #f4f8fa;
  padding: 10px 20px;
}

.task-edit-attach-document img {
  margin: 0 10px;
}

.list-attach-document-browse .MuiOutlinedInput-notchedOutline {
  border-color: #e7f2f8 !important;
}

.upload-btn {
  background-color: #e2ecf2 !important;
  margin-left: 20px !important;
}

.gallary-box {
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 0 35px;
}

.gallary {
  width: 170px;
  height: 170px;
  margin-right: 36px !important;
  margin-bottom: 35px !important;
}

.gallary img {
  width: 100%;
  height: 100%;
}

.gallary-overlay {
  position: relative;
}

.gallary-overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  cursor: pointer;
}

.gallary-overlay::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14, 13, 13, 0.599);
}

.list-image-detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-image-detail img {
  width: 16px;
}

.list-image-detail p {
  color: #aaabac;
  font-size: 13px;
  font-weight: 400;
}

.list-add-note {
  width: 100%;
  margin-top: 50px;
  padding: 0 80px 0 35px;
}

.list-add-note .MuiInputBase-formControl {
  width: 100%;
}

.list-add-note input {
  width: 100%;
}

.list-notes {
  margin-top: 30px !important;
}

.notes-profile {
  margin-bottom: 10px;
}

.notes-profile img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0.6px solid #5bd8ff;
  margin-right: 10px;
}

.notes-profile p {
  font-size: 13px;
  font-weight: 400;
  color: #aaabac;
}

.list-note-description {
  background: #dae5eb;
  padding: 20px 20px 10px 20px;
  border-radius: 6px;
}

.list-note-description p {
  color: #3d4244;
  font-size: 14px;
  font-weight: 400;
}

.list-note-description span {
  font-size: 12px;
  font-weight: 400;
  color: #a9b1c5;
}

.list-note-description p span {
  font-size: 14px;
  font-weight: 400;
  color: #006ca6;
}

.view-more-notes {
  color: #006ca6;
  font-size: 14px;
  font-weight: 400;
  font-family: "proxima_novasemibold";
  margin-top: 10px;
}

.list-status {
  margin-top: 50px;
}

#listNote .list-update-btn {
  background: #006ca6;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100px;
  padding: 10px 0;
  border-radius: 8px;
  margin-left: 150px;
}

#listNote {
  margin-bottom: 100px;
}

/* Repeat */
.repeat-week-section p {
  color: #3d4244;
}

.repeat-every-select fieldset {
  border: none;
  box-shadow: none;
}

.repeat-every-select {
  width: 100px !important;
}

.repeat-every-select input {
  text-align: center;
}

.repeat-weeks div {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.repeat-weeks div:hover {
  background: rgba(1, 193, 235, 0.5);
  color: #fff;
}

.repeat-section-calender {
  width: 147px;
  margin-left: 20px;
}

/* Add task page css */
#AddTask .upload-btn {
  margin-left: 0 !important;
  margin-top: 20px;
}

#AddTask .list-update-btn {
  margin-left: 20px;
}

#AddTask .Add-Task-cancel {
  background: transparent;
  color: #006ca6;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100px;
  padding: 10px 0;
  border: 1px solid #006ca6;
  border-radius: 8px;
  margin-left: 150px;
}

.frequency-radio label {
  min-width: 80px;
}

.frequency-times {
  min-width: 150px;
}

/* calender section start here */

#calender .fc-col-header {
  width: 100% !important;
}

#calender .fc-col-header-cell-cushion {
  font-family: "proxima_novasemibold";
  font-size: 16px;
  font-weight: 400;
  color: #3d4244;
}

#calender .fc-daygrid-day-events {
  width: 100%;
  height: 100px;
  background-color: transparent;
}

#calender .fc-event-main {
  height: 100%;
}

#calender .fc-event {
  height: 100%;
}

#calender .fc-daygrid-event-harness {
  height: 100%;
}

#calender .calender-date-cell {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
}

#calender .calender-date-cell p {
  color: #3d4244;
  font-size: 14px;
  font-weight: 400;
}

#calender .fc-h-event {
  background: none;
  border: none;
}

#calender .calender-client-cell p {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
}

#calender .calender-client-cell span {
  font-size: 10px;
  font-weight: 400;
  color: #3d4244;
}

#calender .calender-client-cell span svg {
  width: 14px;
  margin-left: 10px;
}

#calender .fc-theme-standard td {
  width: 16.6666666667%;
  padding: 0 10px;
  border-left: none;
  border-right: none;
}

#calender .calender-client-cell {
  /* padding: 8px 14px; */
  padding: 8px 10px;
  border-radius: 8px;
  width: 100%;
}

#calender .fc-scrollgrid-section > td {
  padding: 0 !important;
}

#calender .fc-scrollgrid {
  border-left: none;
}

#calender .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
  display: none;
}

#calender .fc-today-button {
  display: none;
}

#calender .fc-header-toolbar {
  flex-direction: row-reverse;
}

#calender .calender-icon {
  width: 18px;
  color: #929495;
}

#calender .fc-theme-standard td,
.fc-theme-standard th {
  border: none;
  border-bottom: 1px solid var(--fc-border-color);
}

.calender-view-gray {
  background-color: #d3d4d5;
}
.calender-view-pink {
  background-color: #f0d8ff;
}
.calender-view-purple {
  background-color: #edc3ac !important;
}
.calender-view-succes {
  background-color: #a4ffb3;
}

.calender-view-yellow {
  background-color: #ffeba4;
}

.calender-view-blue {
  background-color: #ccf4f8;
}

.calender-view-red {
  background-color: #ffcdcd;
}

/* #calender .fc-daygrid-event-harness {
  visibility: visible !important;
} */

#calender .fc-event .fc-event-main:focus-visible {
  outline: none !important;
}

/* Menu page links */
#Menu .menu-main {
  padding-left: 35px;
}

#Menu .dashboard-form-typo-up {
  border-bottom: none;
}

#Menu .menu-filed-box {
  padding: 0 0 0 35px;
  margin-top: 50px;
  margin-left: -25px;
}

#Menu .menu-table-row {
  background-color: #f2f6f8;
}

#Menu .menu-browse {
  padding: 8px 0;
  width: 100px;
  text-align: center;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #929495;
}

.menu-table {
  margin-top: 40px;
  padding-left: 5px;
}

.menu-table-addicon {
  color: #15a07d;
}

.menu-remove {
  width: 18px;
  margin-left: 6px;
}

.w-small {
  width: 40px !important;
  min-width: 50px !important;
}

#document-table.menu-table .table-head-cell {
  width: auto !important;
}

/* appointment details screen css  */

.hide-client-img-box a {
  color: #006ca6;
  font-family: "proxima_novasemibold";
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
}

.hide-content-number a {
  color: #929495;
  margin-left: 8px;
  font-weight: 400;
}

.client-detail-divider {
  border-bottom: 1px solid #dae5eb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.progress-date p {
  font-size: 13px;
  font-weight: 400;
}

.follow-up-btn {
  background-color: #e2ecf2 !important;
  padding: 8px 16px;
  border-radius: 8px;
  color: #006ca6;
}

.hide-client-status {
  width: 274px;
  /* width: 534px; */
}

.hide-client-label .css-tlelie-MuiListItemText-root {
  padding-left: 10px;
}

.team-section {
  border-top: 1px solid #dae5eb;
  border-bottom: 1px solid #dae5eb;
  padding: 20px 40px;
}

.Team img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
}

.Team p {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #3d4244;
}

.Team span {
  color: #929495;
  font-size: 16px;
  font-weight: 500;
}

.team-divider {
  height: 20px;
  width: 1px;
  background-color: #dae5eb;
  margin: 0 15px;
}

.team-details {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.team-details p {
  font-size: 16px;
  font-weight: 500;
  color: #3d4244;
}

.team-details span {
  font-size: 16px;
  font-weight: 500;
  color: #929495;
  padding-left: 8px;
}

.routine-task-strip {
  padding: 32px 40px;
  border-bottom: 1px solid #dae5eb;
}

.routine-task-strip p {
  color: #3d4244;
}

.add-routine-btn {
  padding: 8px 31px;
  background-color: #006ca6;
  color: #fff;
  border-radius: 8px;
  border: none;
}

.chat-search-box {
  border: 1px solid #dae5eb;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 16px;
}

.chat-search-box p {
  color: #3d4244;
}

.chat-box-main {
  background: #dae5eb59;
  padding: 51px 0;
}

.chat-date-strip {
  width: 100%;
  background: rgba(218, 229, 235, 0.35);
  text-align: center;
  padding: 2px 0;
}

.chat-date-strip p {
  color: #929495;
}

.massege-profile img {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 8px;
}

.massege-profile p {
  color: #929495;
  font-size: 10px;
}

.masseges {
  background: #e2ecf2;
  border-radius: 4px;
  padding: 2px 4px;
  display: inline-block;
  width: auto;
}

.out-going-massege {
  background: #9197b3;
  border-radius: 4px;
  padding: 2px 4px;
  display: inline-block;
  width: auto;
}

.out-going-massege p {
  color: #fff;
}

.in-coming-main {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.out-going-main {
  display: flex;
  align-items: flex-end;
  justify-content: start;
}

.masseges span {
  color: #9197b3;
  font-size: 10px;
  width: 100%;
  text-align: right;
}

.out-going-massege span {
  font-size: 10px;
  width: 100%;
  color: #fff;
  text-align: right;
}

.chat-in-coming {
  margin-top: 30px;
}

.chat-out-going {
  margin-top: 30px;
}

.chat-send {
  margin-top: 20px;
}

.chat-send img {
  width: 22px;
}

.chat-send-inputFiled {
  width: 203px;
}

.map-details-div {
  margin-top: 15px;
}

.map-details p,
.map-details span,
.map-details a {
  font-size: 16px;
  color: #3d4244;
  font-weight: 600;
}

.map-details span {
  color: #929495;
  margin-left: 8px;
}

.map-details a {
  text-decoration: none;
  color: #01c1eb;
  font-family: "proxima_novasemibold";
}

.status-wrap {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1400px) {
  .calender-view-box {
    display: block !important;
  }

  #calender .fc-theme-standard td {
    width: auto;
    padding: 4px;
  }

  #calender .fc-daygrid-day-events {
    top: 50%;
  }

  #calender .calender-client-cell {
    padding-left: 3px;
    padding-right: 3px;
  }

  .buttom-check span {
    font-size: 13px;
  }
}

.calender-add-icon {
  width: 20px;
}

.calender-add-icon-color path {
  fill: #006ca6 !important;
}

.calender-add-icon:hover .table-icon-popover {
  display: flex !important;
  width: auto;
  padding-left: 4px;
  padding-right: 4px;
}

.calendar-top-title p {
  display: flex;
  align-items: center;
}
