.personal-info2 {
  padding: 0px 56px;
}

.homecare {
  background: url(../../../assets/images/homecare-icon.svg) no-repeat 40px center;
}

.homecare span+span,
.community-access span+span,
.silsda span+span,
.respite span+span {
  /* padding-left: 63px; */
  /* padding-left: 40px; */
  padding-left: 55px;
}


.community-access {
  background: url(../../../assets/images/comm-icon.svg) no-repeat 40px center;
      background-size: 37px;
}

.silsda {
  background: url(../../../assets/images/sda-icon.svg) no-repeat 40px center;
}

.respite {
  background: url(../../../assets/images/respite-sta.svg) no-repeat 40px center;
}



.form-heading-align.MuiTypography-root{
  margin-left: 40px ;
}

.stakeholder-header{
  font-size: 20px;
  color: #3d4244;
  margin-top: -35px;
}

/* ____ */

.gender-chip .MuiChip-root{
 background-color: #E8F6F2;
}


.gender-chip .MuiChip-root .MuiChip-deleteIcon{
  color: #15A07D !important;
}

.acc-back.MuiButton-root{
border: 1px solid rgba(0, 0, 0, 0.23)!important;
padding: 5px 15px;
text-transform: capitalize;
color: rgba(0, 0, 0, 0.87)!important;
font-size: 16px;
/* font-family: "proxima_novasemibold"; */
}