.custom-multi-select-dropdown-component {
    min-width: 200px;
    max-width: 200px;
    position: relative;
    font-family: Arial, sans-serif;
  
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  
    .dropdown {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 1;
  
      .dropbtn {
        padding: 5px;
        width: 100%;
        // background-color: #f9f9f9;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
  
        // &:hover {
        // //   background-color: #f1f1f1;
        // }
  
        .custom-multi-select-selected-chip {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
  
          p {
            margin: 0;
            color: #999;
            font-size: 14px;
          }
        }
      }
    }
    
  
    .dropdown-content {
      padding-top: 5px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #ffffff;
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 4px 8px rgba(225, 178, 178, 0.1);
      z-index: 2;
      display: none;
  
      &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
        color: #15a07d;
        height: 10px;
      }

      &.open-upward {
        top: auto;
        bottom: 100%;
      }
  
      .custom-multi-select-menu-item {
        padding: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
  
        &:hover {
          background-color: rgba(17, 205, 239, 0.1);
        }
  
        p {
          margin: 0;
          font-size: 14px;
          color: #333;
        }
  
        .MuiCheckbox-root {
          margin-right: 8px;
        }
  
        &.item-not-found {
          text-align: center;
          color: #999;
        }
      }
    }
  
    .custom-multi-select-selected-chip {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
  
      .MuiChip-root {
        margin: 2px;
        font-size: 13px;
        background-color: #e0f7fa;
  
        .MuiChip-deleteIcon {
          font-size: 16px;
          color: #888;
  
          &:hover {
            color: #333;
          }
        }
      }
    }
  
    /* Styles for the search box */
    .custom-search-input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 8px;
      font-size: 14px;
      box-sizing: border-box;
  
      &:focus {
        outline: none;
        border-color: #11cdef;
      }
    }
  
    /* Custom scrollbar styling */
    .dropdown-content {
      ::-webkit-scrollbar {
        width: 8px;
      }
  
      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
  
      ::-webkit-scrollbar-thumb {
        background-color: #c0c0c0;
        border-radius: 10px;
      }
  
      ::-webkit-scrollbar-thumb:hover {
        background-color: #888;
      }
    }
  }



  @media only screen and (max-width: 768px){
    .custom-multi-select-dropdown-component {
      min-width: 100%;
      max-width: 100%;
    }  
  }
  