/* 
=========================
dashboard main page css  
========================
*/

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

::placeholder {
  color: #929495 !important;
}

.cross-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #000;
  /* Adjust color as needed */
}

.Dposition-relative {
  position: relative;
}

.Dclose-button {
  position: absolute;
  top: 0;
  right: 0;
}

.table-profile-image {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
}

.row-color {
  background-color: lightpink !important;
}

input {
  outline: #15a07d !important;
}

.main-green-color {
  color: #15a07d !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #15a07d !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.table-profile-image img {
  width: 100%;
  object-fit: cover;
}

.special-rate {
  color: #645dd7 !important;
  font-size: 10px !important;
  margin-bottom: -17px;
  font-family: "proxima_novaregular";
}

.special-input-field {
  border-radius: 4px;
  border: 0.5px solid var(--stroke-border, #e3e8e6);
  padding: 4px 10px 4px 6px;
  position: absolute;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px !important;
  outline: none;
}

.green-approve-button {
  background-color: #109648 !important;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  margin-left: 10px;
  padding: 10px 14px !important;
  font-family: "proxima_novabold";
}

.widthd {
  width: 10%;
  height: 10%;
}

.addTask-btn-ds {
  color: #fff;
  background: #15a07d;
  width: 118px;
  border-radius: 8px;
  padding: 8px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "proxima_novasemibold";
}

.breadcrumb-box-sty {
  display: flex;
  justify-content: end;
  align-items: center;
}

.section-content {
  padding: 0 16px;
}

.spacet-md {
  padding-top: 40px;
}

.spaceb-md {
  padding-bottom: 40px !important;
}

.appbar-border {
  border-bottom: 0.5px solid #d0ece5;
}

.pe-80 {
  padding-right: 80px;
}

.appbar-main {
  padding-left: 80px;
  padding-right: 80px;
}

.appbar {
  padding: 10px 0 2px 0;
}

.pl-0 {
  padding-left: 0 !important;
}

.color-primary {
  color: #15a07d !important;
}

.empid-label-box span {
  color: var(--main-dark-color);
}

.fixed {
  position: fixed;
  top: 50px;
  transform: translateY(-50px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: 999;
  padding-top: 10px;
  margin-top: 0 !important;
}

.main-fixed {
  margin-left: auto;
}

.dashboard-logo {
  width: 97px;
}

.dashboard-logo img {
  width: 100%;
}

.sp-company-logo {
  width: 97px !important;
}

.sp-company-logo img {
  /* width: 100%; */
  width: 97px !important;
  height: 97px !important;
  object-fit: fill !important;
}

.appbar-switch {
  background-color: transparent;
  border: 1px solid #dadde9;
  border-radius: 8px;
  padding: 8px 16px;
}

.inactive-call-btn {
  color: grey;
}

.active-call-btn {
  background-color: #15a07d;
}

.appbar-switch p {
  font-size: var(--f-size-20);
  padding-left: 16px;
  color: var(--dashboard-blue);
}

.appbar-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 128px;
  position: relative;
  cursor: pointer;
}

.appbar-notification .MuiBadge-badge {
  background-color: #645dd7 !important;
}

.hc-header-profileIcon {
  color: #3f3d3d;
}

.notification-menu .MuiPaper-root::before {
  display: none;
}

.notification-menu .MuiPaper-root {
  min-width: 0 !important;
  min-height: 0 !important;
  top: 40px !important;
}

.notification-dot span {
  transform: none !important;
}

.appbar-notification .MuiTouchRipple-root {
  display: none !important;
}

.appbar-notification button {
  background: none !important;
  box-shadow: none !important;
}

.appbar-notification p {
  padding-right: 10px;
  color: #484747;
}

.appbar-notification svg path {
  fill: #484747;
}

.appbar-menu {
  width: 100%;
  justify-content: flex-end;
}

.appbar-explore {
  padding: 8px 16px;
  background: #d0ece5;
  border-radius: 8px;
}

.appbar-curentplan {
  padding: 8px 16px;
  background: #e8e7f9;
  border-radius: 8px;
}

.curentplan-text.MuiTypography-root {
  color: #645dd7;
}

.appbar-switch-text.MuiTypography-root {
  color: #15a07d !important;
}

.appbar-explore img {
  width: 20px;
  margin-right: 10px;
}

.appbar-explore p {
  color: var(--dashboard-blue);
}

#appbar .text {
  font-size: var(--f-size-20);
}

.app-worker-none {
  display: none;
}

.appbar-none .app-worker-none {
  display: block;
}

#appbar .app-worker-name {
  border: 1px solid var(--main-dark-color);
  padding: 8px 16px;
  border-radius: 8px;
}

#appbar .app-worker-name:hover {
  background: #e2ecf1;
}

#appbar .app-worker-name span,
p {
  display: block;
  /* color: var(--main-dark-color); */
  color: #3d4244;
}

#appbar .app-worker-name p {
  font-size: 13px;
  text-transform: capitalize;
}

#appbar .app-worker-name span {
  font-size: var(--f-size-16);
}

.my-account-topbar {
  padding-left: 32px !important;
}

.my-account-topbar p {
  margin: 0 40px 0 0 !important;
}

.my-account-topbar label {
  margin-right: 42px !important;
}

.appbar-menu-item {
  /* margin-left: 70px; */
  margin-left: 20px;
}

.appbar-menu-item .MuiSwitch-switchBase {
  position: absolute;
}

.appbar-menu-item .MuiSwitch-thumb {
  background-color: #fff;
}

/* old sidebar code css */

/* .sidebar {
  width: 100%;
}

#sidebar div {
  position: static;
} */

/* old sidebar code css */

.sidebar {
  /* width: 100%; */
  transition: width 0.3s ease;
  /* Add transition for smooth animation */
}

.sidebar.open {
  width: 0;
  /* When sidebar is open, set width to 0 to hide it */
}

#sidebar div {
  position: relative;
  /* Update position to relative */
  z-index: 99;
}

#sidebar {
  border-right: 0.5px solid #d0ece5;
  width: 240px;
  margin-top: 5px;
  height: calc(100vh - 49px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 10px;
  padding-bottom: 20px;
}

.sidebar-closed #sidebar {
  width: 46px !important;
  /* Set width to 46px when sidebar is closed */
}

.sidebar-open #sidebar {
  width: 240px !important;
  /* Set default width when sidebar is open */
}

#sidebar .sidebar-item-link:hover .expand-icon {
  color: var(--main-dark-color);
}

#sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #15a07d;
}

.sidebar-icon {
  width: 17px;
}

.sidebar-item span {
  color: #8c8c8c;
  font-size: 16px;
  line-height: 19px;
}

#sidebar .sidebar-item-icon {
  min-width: auto;
  margin-right: 10px;
}

#sidebar .sidebar-item-icon svg {
  width: 17px;
}

#sidebar .sidebar-icon {
  margin-right: 8px;
}

.sidebar-item-link:hover .sidebar-icon svg path {
  fill: #15a07d;
}

#sidebar .sidebar-item-link .MuiTouchRipple-root {
  display: none;
}

#sidebar .sidebar-submenu-link .MuiTouchRipple-root {
  display: none;
}

#sidebar li > div {
  padding: 10px 0 10px 27px !important;
  transition: all 0.3s ease-in-out;
  margin-right: 8px;
}

#sidebar .sidebar-item-link,
#sidebar .sidebar-submenu-link {
  border: 0.1px solid #d3d4d5;
  margin: 1px 8px 1px 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 8px;
  display: flex;
  color: #8c8c8c;
}

#sidebar .submenu-main {
  height: 257px;
  overflow-y: scroll;
}

#sidebar .sidebar-submenu-link {
  border: 0.1px solid #d0ece5;
  padding-left: 44px;
}

#sidebar .sidebar-item-link:hover,
#sidebar .sidebar-submenu-link:hover {
  background: #d0ece5;
  border: 0.1px solid #d0ece5;
}

#sidebar .active {
  background: #d0ece5;
  border: 0.1px solid #d0ece5;
}

#sidebar li span {
  transition: all 0.3s ease-in-out;
}

#sidebar .active:hover span {
  color: #15a07d;
}

#sidebar .sidebar-item-link:hover span,
#sidebar .sidebar-submenu-link:hover span {
  color: #15a07d;
}

#sidebar .sidebar-item-icon svg path {
  transition: all 0.3s ease-in-out;
  fill: #3d4244;
}

#sidebar .sidebar-item-link:hover .sidebar-item-icon svg path,
#sidebar .sidebar-submenu-link:hover svg path {
  fill: var(--dashboard-blue);
}

#sidebar .sidebar-submenu-link .MuiListItemIcon-root {
  min-width: 32px;
}

.sidebar-second-section {
  padding-top: 34px;
}

.sidebar-second-section .sidebar-setting {
  padding: 0 16px 8px 2px;
  display: flex;
}

.appbar-border {
  border-bottom: 0.5px solid #d0ece5;
}

/* .main {
  max-width: calc(100% - 240px);
  width: 100%;
  margin-top: 0;
  margin-left: auto;
} */

.main {
  max-width: calc(100% - 240px);
  width: 100%;
  margin-top: 0;
  margin-left: auto;
}

/* Adjust max-width when drawer is closed */
.drawer-closed + .main {
  max-width: calc(100% - 46px);
}

.sidebar-openclose {
  /* margin-left: 182px !important; */
  margin-left: 216px !important;
  /* z-index: 99; */
  z-index: 100 !important;
  position: absolute !important;
  margin-top: 40px !important;
}

.sidebar-openclose1 {
  margin-left: 16px !important;
}

.hide-sidebar-icons {
  margin: unset !important;
}

@media screen and (max-width: 761px) {
  .hide-sidebar-icons {
    /* display: none !important; */
  }
}

.hover-sidebar-btn {
  padding: 4px !important;
  background: #fff !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

/* .hover-sidebar-btn:hover{
  background: #15a07d !important;
  color: #fff !important;
} */

.hover-sidebar-btn svg {
  padding: 2px;
  color: #15a07d !important;
  margin-left: 2px;
  margin-right: -2px;
}

.MuiDrawer-border .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: none !important;
}

/* .hover-sidebar-btn svg:hover{
  color: #fff !important;
} */

/* .hover-sidebar-btn-icon-back{
  padding: 2px;
  color: #15A07D !important;
  margin-left: 2px;
  margin-right: -2px;
}

.hover-sidebar-btn-icon-back:hover{
  color: #fff !important;
} */

/* .main::-webkit-scrollbar {
  display: none;
} */

.dashboard-main-box {
  align-items: flex-start;
}

.overflow-scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* .overflow-scroll::-webkit-scrollbar {
  display: none;
} */

.dashboard-form-typo-up {
  /* padding: 8px 0; */
  padding: 8px 12px;
  border-bottom: 0.5px solid #d0ece5;
  padding-left: 32px;
}

.dashboard-form-typo p {
  font-size: 16px;
  display: flex;
  color: #969696;
  align-items: center;
  padding-top: 4px;
}

.dashboard-form-typo-sec svg path {
  fill: var(--main-dark-color);
}

.dashboard-form-typo-sec {
  padding-top: 20px;
  padding-bottom: 20px;
}

.dashboard-form-typo-swap svg path {
  fill: var(--main-dark-color);
}

.dashboard-form-typo-swap {
  padding-top: 5px !important;
  padding-bottom: 0px !important;
}

.dashboard-form-typo h4 {
  font-size: 20px;
}

.dashboard-form-section {
  width: 100%;
}

.dashboard-form-section .form-input {
  width: 100%;
}

.dashboard-form-section h3 {
  padding-bottom: 24px;
}

.form-input-icon-bg::before {
  position: absolute;
  content: "";
  background: var(--icon-bg);
  width: 50px;
  height: 40px;
}

.agency-btn-section {
  text-align: center;
  margin-top: 36px;
}

#agency .dashboard-form-section {
  max-width: 560px;
  margin: auto;
  padding-top: 40px;
  /* padding-bottom: 30px;
  max-width: 546px;
  margin: 32px auto 0 auto;
  width: 100%;
  box-shadow: 0px 9px 15px rgba(22, 22, 22, 0.1);
  border-radius: 0px 0px 8px 8px; */
}

/* .dashboard-form-head {
  background-color: var(--ex-light-gray);
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dashboard-form-head h3 {
  padding-bottom: 0;
  font-size: 18px;

}

.dashboard-form-body {
  padding: 0 20px 20px 20px;
}

.dashboard-form-section label {
  font-family: "proxima_novasemibold";
  padding: 20px 0 10px 0;
  display: block;
} */

.cancel-button-ui {
  color: #8c8c8c !important;
  background-color: white !important;
  border: 1px solid #8c8c8c !important;
}

.agency-btn-section a {
  width: 136px;
  padding: 10px 0;
  border: 1px solid #15a07d;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.agency-btn-section a:nth-child(1) {
  color: var(--dashboard-blue);
  margin-right: 16px;
}

.agency-btn-section a:nth-child(2) {
  color: #fff;
  background-color: var(--main-dark-color);
}

/* save exit button css here  */

.exit-button {
  background: none;
  color: #15a07d !important;
}

.save-button {
  background: #15a07d !important;
  color: #fff !important;
}

.save-exit-button {
  width: 136px;
  padding: 18px 0 !important;
  border: 1px solid #15a07d !important;
  border-radius: 8px !important;
  text-align: center !important;
  font-size: 16px;
  font-family: "proxima_novasemibold" !important;
  line-height: 16px !important;
}

.save-exit-button2 {
  width: 40px;
  padding: 5px 0 !important;
  border: 1px solid #15a07d !important;
  border-radius: 8px !important;
  text-align: center !important;
  font-size: 16px;
  font-family: "proxima_novasemibold" !important;
  line-height: 16px !important;
}

.save-exit-button-sp {
  background-color: #15a07d !important;
  color: #ffffff !important;
  width: 136px;
  padding: 18px 0 !important;
  /* border: 1px solid #15a07d !important;  */
  border-radius: 8px !important;
  text-align: center !important;
  font-size: 16px;
  font-family: "proxima_novasemibold" !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.sidebar-porfile {
  display: flex;
  padding-left: 6px;
  border: 0.1px solid #d3d4d5;
  margin-right: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.sidebar-porfile img {
  width: 32px;
  margin-right: 8px;
  height: 32px;
  border-radius: 50%;
}

.sidebar-porfile-content p {
  font-size: 18px;
  color: var(--dashboard-blue);
}

.sidebar-porfile-content span {
  font-size: 13px;
  color: #898989;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
}

.sidebar-setting li {
  color: var(--light-blue);
}

#sidebar .sidebar-setting li:hover > div {
  background: transparent;
}

#sidebar .sidebar-setting svg path {
  fill: var(--light-blue);
}

.border-none {
  border: none !important;
}

.sidebar-icon-text {
  margin-left: -22px !important;
}

/* Availibility page css  */

.shift-type-check {
  padding: 8px 16px;
  background-color: transparent;
  border: 0.5px solid #15a07d;
  border-radius: 8px;
}

.shift-type-checkbox {
  width: 100%;
  padding-right: 80px;
}

.shift-type-check span {
  color: var(--main-dark-color);
  font-size: 16px;
  padding-left: 11px;
}

.dashboard-form .form-text {
  font-size: 16px;
  color: #3d4244;
}

.dashboard-form .shift-select {
  cursor: pointer;
}

.dashboard-form .shift-select fieldset {
  border: none;
}

.dashboard-form .shift-select .MuiSelect-select {
  padding-left: 0;
}

.dashboard-form .shift-select .MuiSelect-select em {
  font-style: normal;
  font-size: 16px;
  color: #3d4244;
}

.dashboard-form .shift-checkbox:nth-child(1) {
  margin-left: 0;
}

.shiftModal .time-filed {
  width: 100%;
}

.switch-box .time-filed:nth-child(3) {
  margin-left: 36px;
}

.inputlist-field div {
  background: none !important;
}

.time-filed .MuiInputBase-formControl::after {
  display: none;
}

.border-text {
  color: #15a07d;
}

.time-filed .MuiInputBase-formControl::before,
.inputlist-field .MuiInputBase-formControl::before {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.inputlist-field .MuiInputBase-formControl {
  border: 1px solid #898989;
  margin-bottom: 10px;
  border-radius: 5px;
}

.inputlist-field .MuiInputBase-formControl input {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 20px;
}

.shiftModal .modal-button .agency-btn-section {
  margin: 0 0 21px 0;
}

.shift-type-section {
  padding: 8px 0;
  border-bottom: 1px solid #d0ece5;
}

#my-account-main-tab .shift-type-section {
  padding-top: 0 !important;
}

.dashboard-form .shift-type-section:nth-child(1) {
  padding: 25px 0;
}

.dashboard-form .shift-type-section:nth-child(2) {
  padding: 22px 0;
}

.dashboard-form .shift-type-section:nth-child(3) {
  padding: 32px 0;
}

.shift-formcontrol-box {
  width: 100%;
  padding-right: 80px !important;
}

.shift-type-section .appbar-menu-item {
  margin-left: 0;
}

.shift-type-section .appbar-switch {
  border: none;
}

.shift-type-section .appbar-switch p {
  font-size: 20px;
  color: #3d4244;
}

.dashboard-form .shift-type-section-box {
  max-width: 174px;
  width: 100%;
  margin-right: 80px;
  padding-left: 32px;
}

.dashboard-form .time-filed {
  width: 90px;
  position: relative;
  text-align: center;
  margin-left: 35px;
  background: #fff;
  outline: none;
}

.dashboard-form .time-filed .MuiInputBase-formControl {
  padding: 0 !important;
}

.dashboard-form .time-filed .MuiAutocomplete-endAdornment {
  right: 3px !important;
}

.dashboard-form
  .time-filed
  .MuiInputBase-formControl
  > .MuiAutocomplete-endAdornment
  button:nth-child(1) {
  display: none;
}

.dashboard-form .time-filed .MuiInputBase-formControl input {
  background: #fff;
  padding: 0;
  font-size: var(--f-size-14);
}

.dashboard-form .time-filed .MuiInputBase-formControl input::placeholder {
  color: #3d4244;
}

.dashboard-form
  .time-filed
  .MuiInputBase-formControl
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.dashboard-form .time-filed input {
  width: 100%;
  height: 38px;
  padding: 0 16px;
  border-radius: 6px;
  border: 1px solid #757780;
  text-align: center;
  outline: none;
}

.dashboard-form .time-filed input::placeholder {
  font-size: 16px;
  color: #3d4244;
}

.table-link {
  color: #3d4244;
  font-size: 14px;
}

.table-link:hover {
  color: var(--main-dark-color);
}

.table-sr-no {
  min-width: 77px !important;
}

.agency-btn-section .dashboard-form .time-filed label {
  position: absolute;
  top: -10px;
  font-size: 13px;
  left: 50%;
  z-index: 1;
  background: #fff;
  transform: translateX(-50%);
}

.dashboard-form .weakday-section {
  padding: 14px 32px 54px 32px;
  width: 100%;
}

.dashboard-form .weakday-box {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0;
  max-width: 120px;
  width: 100%;
  height: 100%;
}

.dashboard-form .weakday-inner-box {
  padding-bottom: 8px;
}

.weak-name {
  text-align: center;
  padding: 4px 30px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.weak-name p {
  color: #fff;
  font-size: 16px;
  line-height: 19px;
}

.weak-hours {
  padding-top: 8px;
}

.weak-icon-gray svg path {
  fill: #929495;
}

.weak-icon-primary svg path {
  fill: #70acdb;
}

.weak-hours span {
  padding-left: 3px;
}

.week-disable {
  background: #e5e5e5 !important;
}

.dashboard-form .week-disable .time-filed input {
  background: #e5e5e5 !important;
  color: #757780 !important;
  border-color: #757780 !important;
}

.dashboard-form .week-disable .del-btn {
  display: none !important;
}

.dashboard-form .week-disable .week-add-icon {
  display: none !important;
}

.dashboard-form
  .week-disable
  input[type="time"]:nth-child(1)::-webkit-calendar-picker-indicator {
  opacity: 0.5;
}

.weakday-box .time-filed {
  margin: 20px auto 0 auto;
}

.weakday-box .time-filed label {
  padding: 1px 4px;
  color: #929495;
  border-radius: 8px;
}

.weakday-box .time-filed input::placeholder {
  color: #929495;
}

.weak-light-gray {
  background: #e5e5e5;
}

.weak-dark-gray {
  background-color: #c2c2c2;
}

.weak-text-gray span {
  color: #929495;
}

.weak-dark-primary {
  background-color: #70acdb;
}

.weak-light-primary {
  background-color: #eff8fd;
}

.weak-text-primary span {
  color: #70acdb;
}

.week-tue-bg {
  background-color: #a7829d;
}

.weak-success {
  background-color: #71ba60;
}

.weak-success-dark {
  background-color: #5fb0a2;
}

.weak-warning {
  background-color: #dfb070;
}

.weak-warning-light {
  background-color: #dfc361;
}

.weak-danger {
  background-color: #ea8178;
}

.dashboard-form .weakday-box .time-filed label {
  left: 12px;
  transform: translateX(0);
}

.weak-box-border {
  border-bottom: 0.5px solid #d0ece5;
}

.Hc-availibility-radio .shift-checkbox {
  margin-right: 40px;
}

/* .dashboard-main-box .agency-btn-section {
  margin: 0;
} */

.hide {
  display: none;
}

.drop-box {
  min-height: auto;
}

.week-add-icon svg path {
  color: var(--main-dark-color);
}

.dots-style {
  width: 6px;
  height: 6px;
  margin-right: 10px;
  background-color: #3d4244;
  border-radius: 50%;
}

.table-chart-image {
  width: 28px;
  margin: auto;
}

.table-chart-image img {
  width: 100%;
  object-fit: cover;
}

.table-chart-icons {
  color: #15a07d;
}

/* Modal css start  */

.modal-content p {
  font-size: 20px;
  color: #484747;
  font-family: "proxima_novasemibold";
  padding-bottom: 32px;
}

.modal-button .agency-btn-section {
  margin: 56px 0 32px 0;
  text-align: center;
  width: 100%;
}

/* .divider {
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: #dae5eb;
} */

/* table css  start*/

#document-table .table-btn {
  height: auto;
  min-width: 87px;
  text-align: center;
  padding: 4px 0;
  font-size: 13px;
  background-color: transparent;
  color: #15a07d;
  border: 1px solid #15a07d;
  border-radius: 4px;
}

#document-table {
  box-shadow: none;
}

#document-table .dropdown-menu {
  display: none;
  background: #fff;
  z-index: 999;
  border: 1px solid #d0ece5;
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  left: 90px !important;
  right: 30px !important;
  /* margin-top: -20px; */
}

/* #document-table .dropdown-menu {
  display: none;
  background: #fff;
  z-index: 999;
  border: 1px solid #d0ece5;
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  position: absolute !important;
  inset: auto 0px 0px auto !important;
  transform: translate3d(-85px, -3.5px, 0px) !important; 
} */

#document-table .dropdown-menu-action {
  width: 136px;
}

#document-table .dropdown-menu-appt {
  display: none;
  /* top: 0px !important; */
  margin-top: -20px !important;
  background: #fff;
  /* left: auto !important; */
  /* right: 30px !important; */
  z-index: 999;
  border: 1px solid #d0ece5;
  /* transform: none !important; */
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  /* margin-right: 0 !important; */
  left: 60px !important;
  right: 30px !important;
}

#document-table .dropdown-menu-client {
  display: none;
  /* top: 20px !important; */
  background: #fff;
  /* left: auto !important; */
  /* right: 30px !important; */
  z-index: 999;
  border: 1px solid #d0ece5;
  /* transform: none !important; */
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  /* margin-right: 0 !important; */
  left: 60px !important;
  right: 30px !important;
}

#document-table .dropdown-menu-secondary-user {
  display: none;
  background: #fff;
  z-index: 999;
  border: 1px solid #d0ece5;
  border-radius: 10px;
  padding: 8px 10px;
  width: 150px;
  /* left: -44px !important; */
  inset: unset !important;
  transform: unset !important;
  position: unset !important;
}

.client-evaluation-details-dropdown {
  width: 385px !important;
}

#appointment-table .dropdown-menu {
  margin-right: 0 !important;
}

#document-table .show {
  display: block !important;
}

.dropdown-menu .drop-menu-item {
  color: #15a07d;
}

.dropdown-menu a {
  /* margin-bottom: 5px; */
  margin-bottom: 2px;
}

.drop-data-ap a {
  margin-bottom: 0px !important;
}

.drop-box {
  padding: 0 !important;
  padding-bottom: 10px !important;
}

.MuiTable-root caption {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-fill {
  background-color: #15a07d !important;
  color: #fff !important;
}

#document-table .tableborder {
  border: 1px solid #d0ece5;
  /* width: 100px !important; */
}

#document-table .table-bg {
  background-color: var(--ex-light-gray);
}

#document-table tbody tr:nth-child(3) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(6) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(12) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(13) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(16) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table .table-title {
  color: #15a07d;
}

#document-table .table-head-cell,
#document-table .table-head-cell p {
  color: #929495;
}

.table-dander svg {
  margin-right: 5px;
}

#document-table .assess-list-cell {
  /* min-width: 70px; */
  width: 200px !important;
}

/* add css for bank table action cell */

.actioncelldiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-menu {
  display: flex;
}

.table-menu-edit {
  color: #15a07d;
}

.table-menu-del {
  color: #ff5251;
}

.table-menu svg {
  width: 18px;
  margin-right: 5px;
}

.threedotsty {
  margin-top: -50px;
  margin-right: -40px;
}

.dot-icon {
  position: absolute;
  right: 0;
  top: 8px;
}

#document .personal-doc-btn {
  padding: 7px 16px;
  border: 0.5px solid #15a07d;
  border-radius: 4px;
  color: #15a07d;
  margin-right: 0 !important;
}

/* .search-appointment input {
  padding-top: 4px;
  padding-bottom: 4px;
} */

#document .personal-doc-btn span {
  font-size: 14px !important;
  padding-right: 10px;
}

#document .personal-doc-btn {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

#document .bankdetails-btn span {
  font-size: 14px !important;
}

#document .personal-doc-btn svg path {
  fill: #15a07d;
}

#document .doc-search {
  border: none;
  outline: none;
  box-shadow: none;
}

#document .doc-search fieldset {
  display: none;
}

#document .doc-search input {
  height: 18px;
  border-radius: 16px;
  border: 1px solid #d0ece5;
  box-shadow: none;
  flex-direction: row-reverse;
  padding-left: 40px;
}

#document .MuiInputBase-adornedStart {
  position: relative;
  padding-left: 37px;
}

#document .MuiInputBase-adornedStart svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px !important;
}

#document .MuiAutocomplete-endAdornment {
  display: none;
}

#document .doc-search input {
  color: #15a07d;
  margin-left: 0;
}

#document .ForwardRef-iconButtonHidden-3 {
  display: none;
}

#document .blank-row {
  background-color: #e8f6f2;
  padding: 3px 0;
}

#document .blank-row th {
  padding: 0;
}

#document .blank-row th:nth-child(1) {
  padding-left: 20px;
  color: #929495;
}

.table-time-text span {
  font-size: 13px;
}

.table-time-text svg {
  width: 14px;
}

.table-dander {
  color: #ff5251;
}

.table-time-light {
  color: #9197b3;
}

.doc-search-box {
  padding: 24px 0;
}

#simple-tabpanel-0 > .MuiBox-root {
  padding-bottom: 0 !important;
}

.table-caption {
  padding-bottom: 0 !important;
}

/*  table css  end*/

.header-topsection {
  padding-top: 6px;
  padding-bottom: 10px;
  position: relative;
  width: 100% !important;
  border-bottom: 0.5px solid #d0ece5;
}

/* .header-topsection::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100% !important;
  height: 0.6px;
  background-color: #dae5eb;
 
} */

/* Bank table section start here */

#document .bankdetails-btn {
  background-color: #15a07d;
  border-radius: 4px;
  color: #ffffff;
  /* margin-right: 0;
  padding-top: 3px !important;
  padding-bottom: 3px !important; */
}

#document .bankdetails-btn span {
  text-transform: capitalize !important;
  font-size: 16px;
  padding-right: 10px;
}

#document .bankdetails-btn svg path {
  fill: #ffffff;
}

.table-actionbtn span {
  font-size: 15px;
}

.table-actionbtn svg {
  width: 14px;
}

.table-actionbtn-span1 {
  color: #15a07d;
  margin-left: -13px;
}

.table-actionbtn-span2 {
  color: #ff5251;
}

/* Bank table section start end */

/* Tabs Change Start */

.tab-wrap button {
  padding: 8px 16px;
  text-transform: capitalize;
  font-size: 17px;
}

#health-carer label {
  color: #3d4244;
}

/* Tabs Change End */

/* Add & edit Bank Start  */

.arrowbacksty {
  margin: 3px;
  color: #15a07d;
}

.arrowbacktext.MuiTypography-root {
  /* margin-top: -3px; */
  margin-bottom: 12px;
  font-size: 20px;
  padding-left: 10px;
}

.ChangePasswordtext.MuiTypography-root {
  margin-bottom: 40px;
  font-size: 24px;
}

/* Bank table section start end */

/* Add & edit Bank End  */

/* My Account Page  css start */

.checkbox-label-box {
  margin: 0px;
}

.checkbox-label-box .checkbox-label {
  margin-left: 13px;
  font-size: 20px;
}

/* table css  start*/

#document-table .table-btn {
  height: auto;
  min-width: 87px;
  text-align: center;
  padding: 4px 0;
  font-size: 13px;
  font-family: "proxima_novasemibold";
  background-color: transparent;
  color: #15a07d;
  border: 1px solid #15a07d;
  border-radius: 4px;
}

.bg-fill {
  background-color: #15a07d !important;
  color: #fff !important;
}

#document-table .tableborder {
  border: 1px solid #d0ece5;
}

#Document {
  width: 100px !important;
}

.cell-width {
  width: 140px;
}

#document-table .table-bg {
  background-color: var(--ex-light-gray);
}

#document-table tbody tr:nth-child(3) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(6) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(12) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(13) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table tbody tr:nth-child(16) {
  background: rgba(218, 229, 235, 0.35);
}

#document-table .table-title {
  color: #15a07d;
}

#document-table .table-head-cell {
  color: #929495 !important;
}

.table-dander svg {
  margin-right: 5px;
}

#document .personal-doc-btn {
  padding: 7px 16px;
  border: 0.5px solid #15a07d;
  border-radius: 4px;
  color: #15a07d;
  margin-right: 26px;
}

#document .personal-doc-btn span {
  font-size: 16px;
  padding-right: 10px;
}

#document .personal-doc-btn svg path {
  fill: #15a07d;
}

#document .doc-search {
  border: none;
  outline: none;
  box-shadow: none;
}

#document .doc-search fieldset {
  display: none;
}

#document .doc-search input {
  height: 18px;
  border-radius: 16px;
  border: 1px solid #d0ece5;
  box-shadow: none;
  flex-direction: row-reverse;
  padding-left: 40px;
}

#document .MuiInputBase-adornedStart {
  position: relative;
}

#document .MuiInputBase-adornedStart svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 27px;
}

#document .MuiAutocomplete-endAdornment {
  display: none;
}

#document .doc-search input {
  color: #15a07d;
  margin-left: 0;
}

#document .ForwardRef-iconButtonHidden-3 {
  display: none;
}

#document .blank-row {
  background-color: #e8f6f2;
  padding: 3px 0;
}

#document .blank-row th {
  padding: 0;
}

#document .blank-row th:nth-child(1) {
  padding-left: 20px;
  color: #929495;
}

.table-time-text span {
  font-size: 13px;
}

.table-time-text svg {
  width: 14px;
}

.table-dander {
  color: #ff5251;
}

.table-time-light {
  color: #9197b3;
}

.doc-search-box {
  padding: 24px 0;
}

.radio-group-box {
  padding-left: 80px;
}

/* Error 404 page css  */

.dashboard-form .error-main {
  width: 85%;
  height: 78vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-form .error-404 {
  font-size: 14em;
  line-height: 100%;
}

.dashboard-form .error-text {
  font-size: 24px;
}

.dashboard-form .error-main span {
  font-size: 16px;
}

.dashboard-form .error-btn a {
  background: var(--main-dark-color);
  color: #fff;
  border: 1px solid var(--main-dark-color);
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 6px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

.inline-upload .upload-btn {
  margin-top: 0 !important;
  margin-left: 20px !important;
}

#attached-file {
  padding: 0;
}

#attached-file .list-image-detail {
  justify-content: center;
}

#attached-file .list-image-detail img {
  margin-right: 10px;
}

#edittimesheet .appointment-dashboard-timebar {
  padding-right: 0;
}

#edittimesheet .appointment-dashboard-timebar img {
  margin-right: 10px;
}

.expenselist-wrap {
  margin-bottom: 60px;
}

.expenselist-wrap .dashboard-form-typo-up {
  border-top: 1px solid #d0ece5;
  border-bottom: 1px solid #d0ece5;
}

.expenselist-wrap .Task-list-main {
  margin-top: 40px;
  padding: 0 20px;
}

.timesheet-overview {
  padding: 20px 16px 0;
}

.timesheet-overview .hide-content p span {
  display: inline-block;
  padding-left: 10px;
}

#document-table p {
  color: #3d4244;
  font-size: 13px;
}

.expenses-table {
  padding: 32px;
}

.expenses-table h4 {
  padding-bottom: 20px;
}

.timesheet-overview label {
  font-family: "proxima_novaregular";
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.txt-input {
  min-height: 40px;
  color: #333;
  font-size: 13px;
}

.txt-input img {
  margin: 2px 5px 0 0;
}

.timesheet-overview {
  padding: 30px 0px 0px 0;
}

.icon-hide::-webkit-calendar-picker-indicator {
  display: none;
}

input#appt {
  border: 1px solid #d0ece5;
  padding: 8px 5px;
  border-radius: 5px;
}

.pb-3 {
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .MuiTabs-root {
    justify-content: center;
    padding: 0 8px;
  }

  .MuiTabs-scrollButtons {
    display: none;
  }

  .sidebar-openclose {
    margin-left: 55px !important;
  }
}

.myaccounttab {
  /* width: 20.33%;*/
  width: 20%;
  text-transform: capitalize !important;
  font-size: 20px !important;
}

#support-coordinator-MyAccount .Mui-selected {
  background-color: #e8f6f2 !important;
}

/* .myaccounttab:hover{
.MuiTabs-root .MuiTab-root {
   width: 19.33%;
} */

.tab-text.MuiTypography-root {
  color: #3d4244;
  font-size: 17px;
}

/* Profile tabs Components style */

.card-image {
  border-radius: 8px;
  margin: auto;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-content {
  padding: 0px !important;
  margin: auto;
}

.card-content .card-text {
  padding: 5px 0;
  font-size: var(--f-size-16);
  color: #3d4244;
  text-align: start;
}

.card-content .card-text span {
  text-align: start;
  color: #3d4244;
  word-break: break-all;
}

.card-content-new {
  padding: 0px !important;
  margin: auto;
}

.card-content-new .card-text-new {
  padding: 5px 0;
  font-size: var(--f-size-16);
  color: #3d4244;
  text-align: start;
  font-weight: bold;
}

.card-content-new .card-text-new span {
  text-align: start;
  color: #3d4244;
  word-break: break-all;
  font-weight: lighter;
}

/* .text-card {
  font-weight: bold !important;
} */

/* .card-bold{
font-family: inherit !important;
} */

.skill-typo.MuiTypography-root {
  padding: 8px 0;
  border-bottom: 0.5px solid #d0ece5;
  border-top: 0.5px solid #d0ece5;
  padding-left: 8px;
}

.skill-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skill-map-image {
  /* border-radius: 20px; */
  margin: auto;
  height: 95%;
  width: 95%;
  object-fit: cover;
  margin-left: 30px;
}

.icon-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.img-icons {
  width: 80px;
}

.sidebar-icons {
  width: 20px;
}

.card-text-icons.card-icons {
  padding: 10px 0;
  font-size: 15px;
  color: #3d4244;
  text-align: start;
}

.card-icons {
  /* margin-top: 10px; */
  color: #ff5251;
}

/* agency page css  */

#agency .dashboard-form-typo-up {
  border: none;
}

.dashboard-form .agency-searchbar {
  margin-top: 100px;
  padding-left: 0;
}

.dashboard-form .agency-searchbar > div {
  margin-left: 0;
  padding-left: 0;
}

.dashboard-form .table-editcell {
  width: 150px;
  position: relative;
}

.table-action-width {
  width: 15% !important;
}

.dashboard-form .agency-add,
.add-btn-form {
  background-color: var(--main-dark-color);
  color: #fff;
  font-size: 14px;
  border: 1px solid var(--main-dark-color);
  font-family: "proxima_novasemibold";
  display: inline-block;
  padding: 6px 8px;
  border-radius: 5px;
  width: auto;
  line-height: 27px;
  margin-left: 0;
  min-height: 40px;
}

.agency-searchbar {
  padding-left: 16px;
}

.Hc-viewDetails-subTab .agency-add:hover svg path {
  fill: #15a07d !important;
}

.dashboard-form .agency-add svg {
  float: left;
}

.add-btn-form-div {
  display: flex;
  justify-content: end;
}

.add-btn-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* group list pages css */

.danger {
  color: #ff5251 !important;
}

#GroupList .dropdown-menu {
  width: 93px !important;
}

#GroupList .doc-search > div {
  border-radius: 21px;
}

.pagination-record {
  color: #15a07d;
  font-family: "proxima_novaregular";
  font-size: 14px;
}

#GroupList .pagination-div {
  margin-top: 20px !important;
  display: block;
  padding-left: 20px;
}

#GroupList .table-head-cell:nth-child(1) .fliter-img,
#GroupList .table-head-cell:nth-child(2) .fliter-img,
#GroupList .table-head-cell:nth-child(3) .fliter-img,
#GroupList .table-head-cell:nth-child(5) .fliter-img,
#GroupList .table-head-cell:nth-child(7) .fliter-img,
#GroupList .table-head-cell:nth-child(8) .fliter-img,
#GroupList .table-head-cell:nth-child(10) .fliter-img {
  display: none;
}

#GroupList .filter-search-field:nth-child(1) .input-group,
#GroupList .filter-search-field:nth-child(2) .input-group,
#GroupList .filter-search-field:nth-child(3) .input-group,
#GroupList .filter-search-field:nth-child(5) .input-group,
#GroupList .filter-search-field:nth-child(7) .input-group,
#GroupList .filter-search-field:nth-child(8) .input-group,
#GroupList .filter-search-field:nth-child(10) .input-group {
  display: none !important;
}

/* add new btn updated css */

.addnew-btn.MuiButton-root {
  background-color: var(--main-dark-color) !important;
  color: #fff;
  font-size: 14px;
  font-family: "proxima_novasemibold";
  border-radius: 5px;
  text-transform: capitalize;
}

.addnew-btn.MuiButton-root svg {
  margin-right: -7px;
}

.dashboard-form .agency-add:hover {
  background-color: transparent;
  color: var(--main-dark-color);
}

.dashboard-form .agency-form-box {
  margin-top: 60px;
}

.dashboard-form .agency-form-box-btn {
  margin-top: 40px;
}

.dashboard-form .agency-form-box-btn button {
  padding: 6px 16px;
  font-size: 16px;
  color: var(--main-dark-color);
}

.filter-wrap {
  margin-left: -16px;
}

.dashboard-form .agency-form-box-btn button:nth-child(1) {
  background-color: #e8f6f2;
  border-radius: 8px;
}

.dashboard-form .agency-table {
  margin-top: 60px;
  box-shadow: none;
  padding-right: 80px;
}

#agency .doc-search .MuiFormControl-root .MuiInputBase-root {
  padding: 0 0 0 12px;
}

.dashboard-form .table-edit-del {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  margin-right: 36px;
}

.dropdown-toggle {
  position: absolute;
  right: 0;
  top: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.dashboard-form .MuiTableCell-body {
  padding-top: 0;
  padding-bottom: 0;
  height: 82px;
}

.dashboard-form .table-edit-del a {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: left;
  padding: 0;
  font-size: 14px;
  font-family: "proxima_novasemibold";
  border-radius: 3px;
  margin: 0;
}

.width-request {
  width: 150% !important;
}

.table-editcell {
  display: flex;
  align-items: center;
}

.dashboard-form .table-edit-del + .table-edit-del {
  padding: 0 !important;
}

.dashboard-form .table-edit {
  color: #645dd7;
}

.dashboard-form .table-del {
  color: #ff5251;
}

.dashboard-form .table-pending {
  color: #a3a53e;
}

.dashboard-form .table-complete {
  color: #35aa0a;
}

.dashboard-form .table-rejected {
  color: #ff5251;
}

.status-cell2-active {
  background-color: #a4ffb3;
}

.status-cell2-inactive {
  background-color: #ffeba4;
}

.status-cell2-rejected {
  background-color: #ffb0a4;
}

.dashboard-form .table-edit-del a svg {
  width: 16px;
  margin-right: 4px;
}

.dashboard-form .table-edit-del .table-approve {
  background: #217c21;
  color: #fff;
  border-radius: 4px;
  display: block;
  padding: 5px 8px;
  line-height: 14px;
}

.dashboard-form .table-edit-del .table-approve + br {
  display: none;
}

.dashboard-form .table-edit-del .table-reject {
  background: #ff5251;
  color: #fff;
  border-radius: 4px;
  display: block;
  padding: 5px 8px;
  line-height: 14px;
  margin-top: 5px;
}

.dashboard-form .pe-80 {
  padding-right: 80px;
}

.dashboard-form .pe-32 {
  padding-left: 32px;
}

.dashboard-form .p-0 {
  padding: 0 !important;
}

.dashboard-form .me-32 {
  margin-left: 32px;
}

.dashboard-form .agreement-box {
  width: 80%;
  margin: 30px auto 0 auto;
  border-radius: 15px;
  box-shadow: 0 0 15px #f9f9f9;
  border: 1px solid #f1f1f1;
}

.dashboard-form .agreement-header {
  padding: 20px;
  background-color: #e8f6f2;
}

.dashboard-form .agreement-header div p {
  font-size: 20px;
}

.dashboard-form .agreement-header div span {
  font-size: 16px;
  padding-left: 5px;
}

.dashboard-form .agreement-body {
  padding: 40px;
}

.agreement-body p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.agreement-body p svg {
  margin-right: 10px;
}

.agreement-body p svg path {
  fill: var(--main-dark-color);
}

.dashboard-form .agreement-check {
  margin-top: 20px;
}

.dashboard-form .agreement-check p {
  color: var(--main-dark-color);
}

.dashboard-form .agreement-check .check {
  color: var(--main-dark-color);
}

.dashboard-form .agreement-body .agency-btn-section {
  margin-bottom: 0 !important;
}

/* pagination css   */

/* pagination css   */

#pagination button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
}

#pagination li {
  margin-right: 10px;
}

.pagination-box {
  margin-top: 80px;
}

.pagination-box ul {
  justify-content: center;
}

#pagination li:last-child button {
  color: var(--main-dark-color);
  text-transform: capitalize;
  padding-left: 10px;
}

/* Appointment table pagination style */

.appt-pagination-box {
  margin-top: 10px;
}

.appt-pagination-box ul {
  justify-content: center;
}

#pagination button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
}

#pagination li {
  margin-right: 10px;
}

.pagination-box {
  margin-top: 80px;
}

.pagination-box ul {
  justify-content: center;
}

#pagination li:last-child button {
  color: var(--main-dark-color);
  text-transform: capitalize;
  padding-left: 10px;
}

/* Shift new css =============  */

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.label-container {
  width: 20%;
  margin: 10px;
}

h3.panel-label {
  background-color: #ea5b5b;
  color: #fff;
  margin: 0;
  padding: 10px 15px 10px;
}

.plus {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid #aaa;
  margin-left: 5px;
}

.label-container.icebox {
  width: 100%;
}

.drop-area {
  min-height: 600px;
  padding: 5px;
  border: 1px solid #aaa;
}

.drop-area p {
  margin: 5px;
  padding: 5px;
  background-color: #ececec;
}

.label-wrapper {
  overflow: hidden;
  position: relative;
}

textarea.label {
  margin: 5px;
  padding: 5px;
  background-color: #ececec;
  /* background-color: transparent; */
  width: 100%;
  border-box: 15px;
  box-sizing: border-box;
  /* overflow: hidden; */
  /* overflow: hidden; */
  border: 0;
}

#inputValue {
  width: 100%;
  padding: 10px;
}

.dashboard-form .inputValue-box {
  width: 100%;
}

.inputValue-box .MuiFormControl-root {
  width: 100%;
}

.del-btn {
  width: 98px;
  padding: 8px 2px 0 2px;
}

/* 
.dashboard-form .next-week-checkbox{ 
    display: none;
} */

.inputlist-field .MuiInputLabel-filled {
  font-size: 18px;
  top: -4px;
}

.dashboard-form .checkbox-modal-button {
  margin-top: 36px;
}

.dashboard-form .modal-checkbox .MuiFormControlLabel-label {
  color: var(--main-dark-color);
}

.del-btn a {
  font-size: 13px;
  color: #ff5251;
}

/* .dashboard-form .input-icon {
  width: 20px;
} */

.dashboard-form .input-copy {
  color: var(--main-dark-color);
}

.dashboard-form .input-icon .del-icon {
  font-size: 24px;
}

/* 
=========================
notification css start
=========================
*/

.notification-main {
  min-width: 400px;
  max-width: 400px;
  width: 100%;
  background-color: #fff;
  border: 1px solid var(--main-dark-color);
  box-shadow: 0 0 10px #cfd0d1;
  padding: 20px;
  border-radius: 5px;
  min-height: 350px;
  height: 100%;
  position: absolute;
  top: 30px;
  right: 10px;
  overflow: hidden;
  z-index: 1;
}

.notification-content {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  height: 267px !important;
  scrollbar-color: var(--light-blue) #ffffff;
}

.notification-content::-webkit-scrollbar {
  width: 10px;
  height: 100px;
}

.notification-content::-webkit-scrollbar-track {
  background: #fff;
  box-shadow: none;
}

.notification-content::-webkit-scrollbar-thumb {
  background-color: var(--light-blue);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.notification-box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.notification-text {
  font-size: 14px;
  color: var(--main-font-color);
  margin-left: 20px;
  line-height: 16px;
  font-family: "proxima_novasemibold";
  text-wrap: wrap !important;
}

.notification-time .notification-text {
  margin-left: 0;
  padding-top: 5px;
}

.notification-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-form .notification-time .notification-text {
  margin: 4px 0 10px 0;
  font-size: 13px;
  line-height: 20px;
  color: var(--main-dark-color);
  font-family: "proxima_novasemibold";
}

/* meeting page css  */

.meeting-box {
  padding: 12px 22px;
  margin: 5px 0 10px 0;
  background-color: #e8f6f2;
  width: 100%;
}

.meeting-box .MuiFormControl-root {
  width: 100%;
}

.meeting-radiobox1 .MuiFormControlLabel-label {
  background: url(../images/video.svg);
}

.meeting-radiobox2 .MuiFormControlLabel-label {
  background: url(../images/voice.svg);
}

.meeting-radiobox3 .MuiFormControlLabel-label {
  background: url(../images/chat.svg);
}

.meeting-radiobox4 .MuiFormControlLabel-label {
  background: url(../images/users.svg);
}

.meeting-radiobox .MuiFormControlLabel-label {
  background-position: left;
  background-repeat: no-repeat;
  background-size: 32px;
  padding-left: 46px;
  display: inline-block;
  min-height: 34px;
  line-height: 34px;
  font-size: var(--f-size-14);
}

.meeting-profile {
  max-width: 181px;
}

/* Client management page start here  */

.dashboard-form .tab-main .agency-searchbar {
  margin: 0;
  padding-left: 0;
}

/* .dashboard-form .tab-label {
  font-size: var(--f-size-16);
  font-family: "proxima_novasemibold";
  font-weight: 500;
  margin: 0 20px;
  color: #3d4244;
  

} */

.dashboard-form .tabs-item-field {
  padding-top: 12px;
}

.dashboard-form .tab-main .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-form .tab-main .doc-search .MuiInputBase-formControl {
  border-radius: 50px;
}

.dashboard-form .tab-main .doc-search .MuiInputBase-formControl input {
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard-form .tab-main .agency-table {
  padding-right: 0;
}

.table-category-cell {
  width: 102px;
}

.dashboard-form .tab-main tbody tr:nth-child(3) {
  background-color: transparent !important;
}

.table-meeting-cell p {
  font-size: var(--f-size-14);
}

#meetingCell .status-cell-2 {
  background-color: #fff !important;
}

#meetingCell {
  position: relative;
}

.table-meeting-cell img {
  width: 16px;
}

.table-meeting-cell span {
  padding-left: 4px;
  font-size: 13px;
}

.popup-img {
  width: 80px;
  height: 95.47px;
}

.popup-img img {
  width: 100%;
}

.popup-img .MuiAvatar-rounded {
  width: 100%;
  height: 100%;
}

.client-modal {
  min-width: 385px;
  width: 100%;
  background: #fff;
}

.client-detail-modal {
  padding-left: 16px;
}

.client-detail-modal p,
.client-detail-modal span {
  color: #3d4244;
  font-size: var(--f-size-16);
}

.client-detail-modal span {
  font-family: "proxima_novasemibold";
  padding-left: 6px;
}

/* Client management page start here  */

.dashboard-form .tab-main .agency-searchbar {
  margin: 0;
  padding-left: 0;
}

.dashboard-form .tab-label {
  font-size: var(--f-size-16);
  font-family: "proxima_novasemibold";
  margin: 0 20px;
  color: #3d4244;
  font-size: 20px !important;
  text-transform: capitalize !important;
}

.dashboard-form .tabs-item-field {
  padding-top: 12px;
}

.dashboard-form .tab-main .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
}

.dashboard-form .tab-main .doc-search .MuiInputBase-formControl {
  border-radius: 50px;
}

.dashboard-form .tab-main .doc-search .MuiInputBase-formControl input {
  padding-top: 0;
  padding-bottom: 0;
}

.dashboard-form .tab-main .agency-table {
  padding-right: 0;
}

.table-category-cell {
  width: 102px;
}

.dashboard-form .tab-main tbody tr:nth-child(3) {
  background-color: transparent !important;
}

.table-meeting-cell p {
  font-size: 11px;
}

#meetingCell .status-cell-2 {
  background-color: #fff !important;
}

#meetingCell {
  position: relative;
}

.table-meeting-cell img {
  width: 16px;
}

.table-meeting-cell span {
  padding-left: 4px;
  font-size: 12px;
}

.popup-img {
  width: 80px;
  height: 95.47px;
}

.popup-img img {
  width: 100%;
}

.popup-img .MuiAvatar-rounded {
  width: 100%;
  height: 100%;
}

.tab-main .client-modal {
  min-width: 385px;
  width: 100%;
  background: #fff;
  padding: 20px !important;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  border-radius: 0px 0px 8px 8px;
}

.client-detail-modal {
  padding-left: 16px;
}

.client-detail-modal p,
.client-detail-modal span {
  color: #3d4244;
  font-size: var(--f-size-16);
}

.client-detail-modal span {
  font-family: "proxima_novasemibold";
  padding-left: 6px;
}

#client-management .tab-main .client-popup-tab {
  border: 1px solid var(--main-dark-color);
  width: 178px;
  border-radius: 10px;
  min-height: 0;
}

#client-management .tab-main .client-tab-box {
  margin-top: 26px;
}

#client-management .tab-main .client-popup-tab .Mui-selected {
  color: #fff !important;
  background-color: var(--main-dark-color) !important;
}

#client-management .tab-main .client-popup-tab .client-tab {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#client-management .tab-main .client-popup-tab .client-tab2 {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#client-management .tab-main .client-popup-tab .client-tab-item,
.tab-main .client-popup-tab .client-tab-item {
  min-height: 0px;
  min-width: 84px;
  margin: 2px;
  position: relative;
  align-items: end !important;
  justify-content: end !important;
  padding: 9px 8px;
  flex-direction: row !important;
}

.popup-meatingIcon {
  margin-top: 2px;
}

.popup-meatingWrapper {
  padding-left: 0 !important;
}

.clientEvaluationTab-main {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#client-management .client-tab-item .tab-icon img {
  filter: grayscale(100);
}

#client-management .tab-main .dropdown-menu a {
  margin-bottom: 0;
}

#client-management .tab-main .client-popup-tab span {
  display: none;
}

#client-management .task-tab-main {
  border: 1px solid #d0ece5;
  border-radius: 10px;
}

#client-management .task-tab-row {
  padding: 8px 16px;
  min-height: 0;
}

#client-management .task-tab-row-typo {
  font-size: 13px;
  font-family: "proxima_novasemibold";
  color: #3d4244;
}

#client-management .task-tab-row-typo svg {
  margin-left: 6px;
  fill: var(--main-dark-color);
}

#client-management .task-tab-row-span {
  font-size: 11px;
  font-family: "proxima_novasemibold";
  color: #3d4244;
  display: flex;
  align-items: center;
}

.accordianfirst-row {
  background-color: #ffe27b !important;
}

.accordiansecond-row {
  background-color: #ffa5a5 !important;
}

.accordianthird-row {
  background-color: #a4ffb3 !important;
}

.accordianfourth-row {
  background-color: #ccf4f8 !important;
}

.accordianfifth-row {
  background-color: #c9c4ff !important;
}

#client-management .MuiAccordionSummary-content {
  margin: 0;
  justify-content: space-between;
}

#client-management .task-add-img {
  color: #15a07d;
  padding: 10px 0;
}

#client-management #dropdown-autoclose-outside .table-link {
  font-size: 10px;
}

#client-management .accordian.Mui-expanded {
  margin: 0;
  box-shadow: none;
}

#client-management .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(90deg);
}

.status-box {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

#client-management .accordian-details {
  padding: 16px;
}

#client-management #meetingCell .dropdown-toggle {
  left: 0;
  right: 0;
}

#client-management .accordian-details div {
  border: none !important;
  box-shadow: none !important;
}

#client-management .accordian-details-text .sapn1 {
  padding-top: 12px;
}

#client-management .span-italic {
  font-style: italic !important;
}

#client-management #meetingCell .dropdown-menu {
  display: none;
  top: 20px !important;
  /* background: #fff; */
  left: auto !important;
  right: -34px !important;
  z-index: 1;
  /* border: 1px solid #D0ECE5; */
  transform: none !important;
  border-radius: 10px;
  padding: 8px 10px;
  width: 136px;
}

/* #client-management #document-table::-webkit-scrollbar {
  display: none;
} */

/* #document-table::-webkit-scrollbar {
  display: none;
} */

#client-management .tab-dropdown-menu {
  background-color: transparent !important;
  border: none;
}

.approve-btn {
  border-color: #41a451;
  color: #41a451 !important;
}

.success-btn {
  color: #41a451 !important;
}

#client-management .client-drop-menu {
  left: 12px !important;
  width: 100px;
}

#client-management .view-icon {
  width: 13px;
  height: 10px;
  margin-right: 5px;
}

#client-management .th-width {
  min-width: 120px;
}

.dashboard-form .schedule-icon {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.dashboard-form .schedule-icon img {
  width: 100%;
  height: 100%;
}

.sechedule-td {
  padding: 0 10px !important;
}

.dashboard-form .map-iframe {
  min-height: 342px;
}

.bg-light {
  background-color: #fff !important;
}

#play-icon {
  transition: all 0.2s ease-in-out;
}

.tab-icon {
  position: absolute;
  top: 9px;
  left: 6px;
}

.green-label {
  color: green !important;
}

.btn-primary {
  box-shadow: none !important;
  background: #15a07d !important;
  text-transform: none !important;
  line-height: 21px !important;
}

.dropdown-toggle.btn-primary {
  background: none;
}

.dropdown-toggle.btn-primary {
  background: none !important;
  position: static;
}

.fc .fc-more-popover .fc-popover-body {
  max-height: 200px;
  overflow-x: scroll;
}

.action-toggle-button {
  width: 20px;
  height: 20px;
}

.table-meeting-cell .justify-content-between {
  justify-content: center !important;
}

.client-management-table .dropdown {
  text-align: center;
}

.dialog-meetingbox .client-detail-modal {
  padding-left: 20px !important;
}

.taskAddIcon img {
  width: 18px;
}

.taskAddIcon {
  margin-left: 10px;
}

.client-task-accordian .MuiAccordion-region {
  overflow-y: scroll;
  height: 100%;
  max-height: 178px;
}

.header-topsection .agency-add {
  padding-left: 20px;
  padding-right: 20px;
}

.header-topsection a.agency-add svg {
  margin-right: 0;
}

.header-topsection a.agency-add:hover svg path {
  fill: #15a07d;
}

.breadcrumb-wrap p {
  padding-top: 0;
}

.exportdropdown-wrap {
  display: flex;
  border: 1px solid #15a07d;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 2px;
}

.exportdata-btn .importfiletext {
  padding-left: 12px;
}

.exportdata-btn .importfiletext span {
  display: block;
  font-size: 9px;
}

.dropdownimport .import_CardContent {
  padding: 10px 10px !important;
  min-height: 54px;
  min-width: 120px;
}

.dropdownimport .import_CardContent-goal {
  padding: 61px 10px !important;
  min-height: 156px !important;
  min-width: 120px !important;
}

.export-icon {
  width: 20px !important;
}

.export-dropdown .MuiPaper-root {
  top: 174px !important;
  right: 107px !important;
  left: unset !important;
  width: 260px;
}

.cardactionsty2.dropdownimportbutton {
  margin-top: 0;
}

.exportdata-btn {
  display: flex;
  align-items: center;
}

.dropdownimportbutton.cardactionsty2 .filebtn {
  width: 110px;
}

#document-table .table-head-cell:first-child {
  min-width: 70px;
  width: 100px;
}

.tab-wrap {
  padding-left: 4px;
}

.dashboard-form-typo.container {
  max-width: 100%;
  padding-left: 24px;
}

.cleaneffect {
  box-shadow: none !important;
  text-transform: none !important;
}

.HC-tappanel > div {
  padding: 0 !important;
  margin-top: 40px;
}

/* Drop Down State */

.terminate {
  color: #ac5252 !important;
}

.inactive {
  color: #f17300 !important;
}

.view-history {
  color: #924cbd !important;
}

.terminate-btn {
  color: #d95d39 !important;
}

.active-icon {
  color: #50a85e !important;
}

.reference button {
  white-space: nowrap;
  color: #15a07d !important;
  margin: 0 !important;
  padding: 0 0 0 10px !important;
}

.ref-wrap {
  display: flex;
  align-items: center;
}

.md-icon {
  max-width: 20px;
}

/* breadcrumb */

.header-topsection .agency-add {
  background-color: var(--main-dark-color);
  color: #fff;
  font-size: 14px;
  border: 1px solid var(--main-dark-color);
  font-family: "proxima_novasemibold";
  display: inline-block;
  padding: 3px 14px;
  border-radius: 5px;
  width: auto;
  line-height: 27px;
  margin-left: 0;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-topsection .agency-add svg path {
  fill: #fff;
}

.header-topsection .importexportbtn {
  margin-left: 20px;
}

#breadcrumb {
  display: flex;
  padding-left: 20px;
  align-items: center;
}

#breadcrumb h4 {
  font-size: 20px;
}

#breadcrumb p {
  font-size: 14px;
}

.breadcrumb-inr {
  padding-left: 10px;
}

#breadcrumb svg {
  color: #15a07d;
}

.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbs-link,
.breadcrumbs-emergency {
  white-space: nowrap;
  color: #969696 !important;
  font-size: 16px;
  text-decoration: none !important;
  font-family: "proxima_novaregular";
  line-height: 13px;
}

.breadcrumbs-link:hover {
  border-bottom: 1px solid #969696;
}

.breadcrumbs-emergency {
  margin: 0 8px;
}

.breadcrumbs-icon {
  color: #969696 !important;
  font-size: 16px;
  text-decoration: none !important;
  font-family: "proxima_novaregular";
  line-height: 13px;
}

.breadcrumbs-icon {
  margin: 0 8px;
}

.breadcrumbs-link-active {
  color: #15a07d !important;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #fff;
}

.breadcrumbs-link-active:hover {
  border-color: #15a07d;
}

.breadcrumbs-arrow-active {
  color: #15a07d !important;
}

a.breadcrumbs-link-active:hover {
  text-decoration: none;
}

/* .dashboard-form .agency-add svg path {
  fill: #fff;
} */

.team-cell > div {
  position: relative;
}

.team-cell .teamImg4,
.team-cell .teamImg3,
.team-cell .teamImg1,
.team-cell .teamImg2 {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin: 0 2px !important;
}

.team-cell .team-popover1 {
  left: 0;
}

.team-cell .team-popover3 {
  left: 88px;
}

.team-cell .team-popover4 {
  left: 124px;
}

.pl-none {
  padding-left: 0 !important;
}

.default-page {
  padding-left: 24px !important;
}

.dashboard-form-typo .date-input-filed input {
  padding: 7px 0 7px 7px;
  text-align: left;
}

.peroid-td {
  position: relative;
}

.peroid-td::before {
  position: absolute;
  content: "";
  height: 0.5px !important;
  background: #d0ece5;
  top: 0;
  left: -10px;
  right: -10px;
}

.table-view {
  color: rgba(0, 167, 225, 1) !important;
}

.table-view img {
  width: 24px;
}

.dashboard-form .agency-add svg {
  margin-left: -6px;
  width: 18px;
  /* margin-right: 8px; */
}

.agency-searchbar.agency-searchbar-custom .searchbox-margin,
.agency-searchbar.agency-searchbar-custom .searchbox-margin {
  justify-content: space-between;
}

.agency-searchbar.agency-searchbar-custom .client-btn {
  text-align: right;
}

.doc-search > div {
  border-radius: 6px;
}

/* Calender Css Pophover*/

.calender-view-box {
}

.event-pophover {
}

.calender-popover {
  /*position: absolute;*/
  max-width: 300px;
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(22, 22, 22, 0.2);
  border-radius: 8px;
  padding: 20px;
  /* margin-left: -100px; */
  margin-left: -25px;
  z-index: 999 !important;
}

/* .hc-calender-popover{
  max-width: 500px !important;
  width: 400px !important;
} */

.appoint-popover-img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 0.5px solid #59c5fe;
}

.appoint-popover-profile-text {
  padding-left: 8px;
  word-wrap: break-word;
}

.health-carer-popover-block {
  overflow-wrap: break-word !important;
  word-break: break-word !important;
  width: 220px !important;
}

.appoint-popover-profile-text p {
  color: #3d4244;
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

.appoint-popover-profile-text span {
  color: #929495;
  font-size: 13px;
  font-family: "proxima_novaregular";
}

.popover-date-time {
  margin-top: 12px;
}

.popover-date-time p {
  color: #3d4244;
  font-size: 16px;
  font-family: "proxima_novaregular";
  margin-right: 8px;
}

.popover-date-time img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.popover-location img {
  margin-right: 8px;
}

.popover-location {
  margin-top: 15px;
}

.popover-location p {
  color: #3d4244;
  font: 16px;
  font-family: "proxima_novaregular";
}

.popover-content {
  margin-top: 10px;
}

.popover-content p {
  color: #3d4244;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  padding-right: 8px;
}

.popover-content span {
  color: #3d4244;
  font-size: 16px;
  display: block;
  font-family: "proxima_novaregular";
  white-space: break-spaces;
}

.popover-content-light {
  color: #929495 !important;
}

.popover-team-img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}

.popover-buttons {
  margin-top: 15px;
}

.started-popover-button,
.completed-popover-button,
.pending-popover-button,
.accepted-popover-button,
.rejected-popover-button,
.swapped-popover-button {
  padding: 6px 8px;
  line-height: 17px;
  font-size: 14px;
  font-family: "proxima_novasemibold";
  border-radius: 40px;
  margin-right: 15px;
}

.started-popover-button {
  /* background: #bfe4ff; */
  background: #ccf4f8;
  color: #0086e6;
}

.completed-popover-button {
  background: #ccfad4;
  color: #00a11a;
}

.pending-popover-button {
  background: #fdeeba;
  color: #e8b500;
}

.swapped-popover-button {
  background: #f0d8ff;
  color: #a924fb;
}

.accepted-popover-button {
  background: #c5fff1;
  color: #02a37c;
}

.rejected-popover-button {
  background: #ffe8e8;
  color: #ff5251;
}

.details-popover-button {
  color: #15a07d;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

div[x-placement~="top-start"] .popover-arrow {
  width: 47px;
  position: absolute;
  left: 50%;
  z-index: 1;
  margin-left: -70px;
  bottom: -27px;
  transform: rotate(90deg);
}

div[x-placement~="bottom-start"] .popover-arrow {
  width: 47px;
  position: absolute;
  left: 50%;
  margin-left: -70px;
  z-index: 1;
  top: -27px;
  transform: rotate(30deg);
}

div[x-placement~="bottom-start"] .hc-popover-arrow {
  width: 47px;
  position: absolute;
  left: 50%;
  margin-left: -140px;
  z-index: 1;
  top: -27px;
  transform: rotate(30deg);
}

.sub-popover p {
  margin-right: 0 !important;
}

.sub-popover {
  width: 112px !important;
}

.popover-team-img-box {
  position: relative;
  flex-wrap: wrap;
}

.popover-team-img-box .team-popover1 {
  left: 0;
}

.popover-team-img-box .team-popover {
  top: -71px;
}

.select-category {
  width: 222px;
}

.select-category-menuItem svg {
  margin-right: 10px;
}

.select-category {
  overflow: visible !important;
}

.appointment-calender-filed-box .appointment-calender-filed-select {
  display: flex !important;
  width: auto !important;
}

.select-category-main {
  width: 288px !important;
  height: 32px;
  padding-right: 20px !important;
}

.slot-calender {
  max-width: 140px !important;
}

.select-slot-calender {
  width: 100% !important;
  height: 39px !important;
  /* height: 32px; */
}

.no-routine-bg {
  background-color: rgba(21, 160, 125, 0.1) !important;
  border: none !important;
}

.routine-icon-box {
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.routine-icon-box svg path {
  fill: #645dd7;
}

.routine-icon-box p {
  color: #645dd7;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  padding-left: 8px;
  cursor: pointer;
}

.addTask-files-placeholder svg path,
.addTask-files-placeholder p {
  fill: #bababa;
  color: #bababa;
}

.download-file {
  font-family: "proxima_novasemibold";
}

.routine-icon-box img {
  cursor: pointer;
}

#routine-popup .routine-popup-images {
  width: 80px;
  text-align: center;
}

#routine-popup .routine-popup-images img {
  max-width: 32px;
}

#routine-popup .routine-popup-images p {
  font-size: 14px;
  font-family: "proxima_novaregular";
  color: #3d4244;
}

#routine-popup .add-expenses-body {
  padding: 32px 16px 0 16px;
  background-color: #fff;
}

#routine-popup .modal-button .agency-btn-section {
  margin-top: 0;
  background-color: #fff;
}

#routine-popup .add-expenses .add-expenses-head span svg {
  fill: #484747;
  cursor: pointer;
}

#routine-popup .MuiPaper-root {
  background: none;
  box-shadow: none;
}

#routine-popup .modal-button {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rotine-check input[type="radio"] {
  display: none;
}

.rotine-check input[type="radio"]:checked + img + p {
  color: #15a07d !important;
  font-weight: bold;
}

.selectedcl {
  color: #15a07d !important;
}

.notask-item {
  color: #929495;
}

.iconsm {
  max-width: 16px;
}

.routine-chip .iconsm {
  margin-right: 4px;
}

/* Button Style */

button.outlinedstyle {
  font-size: 18px;
  font-weight: 600;
  font-family: "proxima_novasemibold";
  width: auto;
  height: 40px;
  border: 1px solid #15a07d;
  border-radius: 8px;
  border: none;
  color: #15a07d;
  cursor: pointer;
  text-transform: none;
}

.space-white {
  white-space: nowrap;
}

/*calender filter slider css */

#calender-filter-slider {
  width: 200px;
}

#calender-filter-slide .carousel__inner {
  width: 200px;
  height: auto;
  display: flex;
  align-items: center;
}

#calender-filter-slide .carousel__slide-list {
  width: 100%;
  display: flex;
  align-items: center;
}

.slot-calender {
  height: 32px;
}

.slot-calender > div {
  /* height: 100%; */
  height: 119%;
}

#calender-filter-slide .carousel__slide-item {
  width: 100%;
  height: auto;
}

#calender-filter-slide .carousel__slide-item-img-link p {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #3d4244;
  font-family: "proxima_novaregular";
}

#calender-filter-slide .carousel__container {
  height: 18px;
}

#calender-filter-slide .select-slot-calender {
  border: 0.5px solid #d0ece5;
  border-radius: 4px;
  /* margin-top: -1px; */
  margin-top: 5px;
  height: 32px;
  margin-left: 18px;
  width: 130px;
}

#calender-filter-slide .carousel__btn svg {
  fill: #15a07d;
}

.calender-slide-input {
  display: none;
}

#calender-filter-slide .calender-slide-input-lable {
  width: 100%;
  text-align: center;
  line-height: 20px;
  display: inline-block;
  color: #212121;
}

/* add group page css */

.select-chip {
  background-color: #e2ecf1 !important;
}

.select-chip span {
  color: #15a07d;
}

.select-chip svg {
  width: 18px;
  margin-bottom: -8px;
  color: #15a07d;
}

#addgroup .Mui-selected {
  background: none;
}

.tab-wrap .Mui-selected {
  color: #15a07d !important;
}

#addgroup .group-chip-select {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 50px;
}

#addgroup label {
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

#addgroup .agency-btn-section {
  margin: 100px 0;
}

.client-btn a.remove-btn {
  margin-left: 15px;
  width: auto;
  padding: 2px 10px;
  background-color: #fff;
  color: #ff5e5e;
  border-radius: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #ff5e5e;
  display: flex;
  align-items: center;
}

/* My Account page css here    */

.my-account-tab {
  width: 200px;
}

.my-account-tab:hover {
  border-radius: 4px 4px 0px 0 !important;
}

.my-account-tab.Mui-selected {
  background: #e8f6f2;
  color: #15a07d !important;
}

.account-third-party-radio {
  padding: 30px 0;
}

.my-account-tabpanel > div {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.account-third-party-radio-text {
  font-size: 20px;
  color: #3d4244;
  font-family: "proxima_novabold";
  margin-right: 24px;
}

.my-account-profile-bedg {
  padding: 10px 16px;
  background-color: #e8f6f2;
  border-radius: 4px;
  margin-bottom: 20px;
}

.my-account-profile-bedg p {
  color: #3d4244 !important;
}

label {
  color: #3d4244 !important;
}

.personal-info input::placeholder {
  color: #3d4244 !important;
}

.residential-icon {
  width: 16px;
}

.my-account-subtab {
  background: none !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 23px !important;
}

.my-account-subtab span {
  display: none !important;
}

.my-account-subtab.Mui-selected {
  color: #15a07d !important;
}

.my-account-tab-main {
  padding-left: 15px;
}

.other-tab-skill {
  border: none !important;
  color: #3d4244 !important;
}

.skill-checkbox-main label p {
  color: #3d4244 !important;
}

#my-account .agency-btn-section a {
  background-color: #15a07d !important;
  color: #fff;
  padding: 5px 0;
  border-radius: 6px;
}

#my-account .agency-btn-section {
  margin-bottom: 30px;
}

.health-availability-calender-section {
  padding-left: 15px;
}

/* #calender {
  padding-left: 15px;
} */

/* slidebarmenu */

done-icon {
  color: #41a451;
}

#sidebar-menu .tableHeader {
  background-color: #e2ecf1 !important;
  padding-top: 0;
  padding-bottom: 0;
}

.submenu-contains-cell {
  width: 100px !important;
}

#sidebar-menu .tab-pannel-submenu > div {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#sidebar-menu .MuiTabs-root .MuiTab-root:hover {
  border-radius: 3px;
}

#sidebar-menu .Mui-selected {
  background-color: #e2ecf1 !important;
}

#sidebar-menu .sidebar-menu-tab .Mui-disabled {
  display: none;
}

#sidebar-menu .sidebar-menu-tab .MuiTabs-scrollButtons {
  background: #15a07d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 7px;
}

#sidebar-menu .sidebar-menu-tab .MuiTabs-scrollButtons svg {
  fill: #fff;
}

/* timesheet css start here   */

.timesheet-cells-hide {
  height: 24px;
  overflow-y: hidden;
}

.timesheet-cells-show {
  height: 84px;
  overflow-y: visible;
}

.timesheet-table-td span {
  display: block;
}

.timesheet-sub-table {
  width: 100% !important;
}

.timesheet-cell-arrow {
  width: 41px;
  cursor: pointer;
}

.timesheet-cell-arrow svg {
  width: 100%;
}

.timesheet-cell-arrow svg path {
  fill: #15a07d;
}

#timesheet-table {
  margin-bottom: 20px;
}

#timesheet-table .timesheet-table-td {
  vertical-align: top !important;
  padding: 2px 0 0 0 !important;
  position: relative;
  height: auto !important;
  width: 10px !important;
}

.timesheet-table-search {
  position: relative;
}

.timesheet-checkbox-th {
  height: 54px !important;
}

.timesheet-checkbox-th span {
  margin-left: 3px;
}

.timesheet-table .table-link {
  display: block;
}

.timesheet-table-search fieldset {
  border: none !important;
}

.timesheet-table-search > div {
  padding-left: 0;
}

.timesheet-table-search input::placeholder {
  font-size: 14px;
  color: #262728 !important;
}

.timesheet-table-search svg {
  cursor: pointer;
}

.timesheet-table-search input {
  padding-right: 5px;
}

.timesheet-search-dropIcon {
  position: absolute;
  top: 8px;
  right: 0;
}

.timesheet-search-dropIcon path {
  fill: #15a07d;
}

#timesheet .dashboard-form-typo-up {
  padding-left: 0;
}

#timesheet #document-table {
  padding: 0;
}

.approve-button {
  background-color: #a3a3a3 !important;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  margin-left: 10px;
  padding: 10px 14px !important;
  font-family: "proxima_novabold";
}

.approve-active {
  background-color: #41a451 !important;
}

.total-cell {
  /* position: absolute;
    bottom: -60px;
    left: 0; */
  width: 100%;
}

.timesheet-sub-td-total {
  padding: 0 !important;
  height: 54px !important;
  padding: 0 0 0 14px !important;
  display: flex;
  align-items: center;
  background: #e8f6f2;
}

.total-cell p {
  font-size: 14px;
  font-family: "proxima_novabold";
  color: #3d4244;
}

.timesheet-table-padding {
  padding: 0 19px !important;
}

.table-collapse-data {
  padding-left: 28px;
}

.timesheet-sub-td {
  height: 64px !important;
  border: none !important;
  position: relative;
}

.basepay-input-field {
  border-radius: 4px;
  border: 0.5px solid var(--stroke-border, #e3e8e6);
  padding: 4px 10px 4px 6px;
  outline: none;
  margin: auto;
  width: 100px;
  position: absolute;
  left: -6px;
}

.edit-base-pay {
  width: 12px;
  margin-left: 4px;
}

.edit-base-pay img {
  width: 100%;
  object-fit: cover;
}

.reject {
  color: #ff5251;
}

.dropdown-menu-time {
  font-size: 13px;
  color: rgb(0 167 225) !important;
}

.reject,
.approve,
.view,
.download {
  font-size: 13px;
  font-family: "proxima_novaregular";
  padding-left: 5px !important;
  padding-right: 5px !important;
  justify-content: flex-start !important;
  display: flex;
  align-items: center;
}

.download {
  color: #645dd7;
}

.reject svg,
.approve svg,
.view svg,
.download svg {
  width: 16px;
  /* height: 16px; */
  /* margin-right: 10px; */
  margin-right: 7px;
}

.approve svg,
.reject svg {
  height: auto !important;
}

.approve {
  color: #41a451;
}

.timesheet-action-cell .dot-icon {
  top: -1px !important;
}

.approve-cell {
  background-color: #e2ffe7;
}

.reject-cell {
  background-color: #ffe5e4;
}

.timesheet-dropdown-cell {
  height: 0;
}

#timesheet-cell-dropdown .dropdown-menu {
  height: auto !important;
  top: -13px !important;
  right: 17px !important;
}

.dropdown-menu-custom {
  inset: unset !important;
}

/* super admin health carer css changes  */

.health-carer-search {
  height: 32px !important;
}

.health-carer-search > div {
  height: 100% !important;
}

.calender-category {
  /* height: 100% !important; */
  height: 40px;
}

.appointment-calender-filed-select fieldset {
  height: 100% !important;
  top: 0px;
  border: 0.5px solid #d0ece5 !important;
}

.select-slot-calender > div {
  height: 100% !important;
}

.calender-employee-section {
  width: 200px;
  margin-top: 41px;
  display: flex;
  flex-direction: column;
}

#calender .fc-header-toolbar {
  flex-direction: row-reverse;
  /* display: none !important; */
}

/* !------------! */

/* Style the primary buttons */
#calender .fc .fc-button-primary {
  background-color: #15a07d; /* Updated background color */
  border: none;
  color: white; /* White text color */
  padding: 8px 16px; /* Padding around the text */
  border-radius: 4px; /* Rounded corners */
  margin: 0 5px; /* Margin between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

#calender .fc .fc-button-primary:hover {
  background-color: #107e63; /* Darker background on hover */
}

/* Style the toolbar title */
#calender .fc .fc-toolbar-title {
  font-size: 1.5rem; /* Larger font size */
  font-weight: bold; /* Bold font weight */
  color: #2d3748; /* Dark text color */
  margin: 0 10px; /* Margin around the title */
}

/* Center the toolbar content */
#calender .fc .fc-toolbar {
  display: flex;
  justify-content: flex-start; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

#calender .fc .fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

#calender .fc .fc-left,
#calender .fc .fc-center,
#calender .fc .fc-right {
  display: flex;
  align-items: center;
}

/* !------------! */

.stepperformInr#account-setup {
  background: none;
}

.stepperformInr .account-setup-top-content {
  padding-top: 0;
}

.offboard-inr .step-box .content strong {
  font-weight: 400;
}

.radiolabel {
  padding: 15px 0 0 0;
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
}

.form-space-top {
  padding-top: 20px !important;
}

.form-wrap {
  padding-top: 0 !important;
}

.form-wrap > label,
.form-space-top > label {
  margin-bottom: 10px;
  display: inline-block;
}

.details-popover-button,
.details-popover-button a {
  color: #15a07d !important;
}

/* .offboard-inr #personal-info .personal-info {
  padding-left: 0;
  padding-right: 0;
} */

/* Health carer calender css */

.health-calender-profile-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 6px;
}

#health-carer-calender .calender-client-cell p {
  display: flex;
  align-items: center;
}

#calender .calender-client-cell p svg {
  font-size: 20px;
  margin-left: 3px;
}

.calender-view-primary {
  background: #e8f6f2;
}

.calender-add-icon-color path {
  fill: #15a07d !important;
}

.calender-add-icon:hover .table-icon-popover {
  display: flex !important;
  width: auto;
  padding-left: 4px;
  padding-right: 4px;
}

#health-carer-calender .calender-add-popover {
  bottom: -33px !important;
}

.calender-add-popover {
  position: absolute;
  left: -11px;
  bottom: -31px;
  z-index: 1;
  font-size: 55px !important;
}

.calender-add-popover-icon path {
  fill: #fff !important;
}

.health-availability-calender-section {
  display: flex;
  flex-flow: row;
  align-items: start;
}

.employeeTime {
  margin-bottom: 8px;
}

.employee-td .routine-overflow {
  height: 38px;
}

.employee-table-height {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.calender-employee-section {
  width: 200px;
  margin-top: 41px;
  display: flex;
  flex-direction: column;
}

.calender-employee-data {
  width: calc(100% - 202px);
}

.employee-box {
  display: flex;
  align-items: center;
  height: 50px;
}

.employee-search-th {
  width: 160px;
}

.employee-box span {
  padding: 0 8px;
  display: inline-block;
  width: 107px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.employee-detail {
  display: flex;
  padding: 0 6px;
  align-items: center;
  justify-content: center;
}

.employee-detail span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #929495;
  font-size: 12px !important;
  font-weight: 400;
  padding: 0 6px 0 4px;
}

.employee-search-select > div {
  padding-left: 0 !important;
}

.employee-search-select svg {
  fill: #9197b3;
}

.employee-search-select p {
  font-size: 14px;
  font-family: "proxima_novaregular";
  color: #3d4244;
  opacity: 0.6000000238418579;
}

.employee-td {
  padding: 7px !important;
  vertical-align: top !important;
}

.employee-box img {
  width: 20px;
}

.employee-profile {
  border-radius: 50%;
  height: 20px;
}

.employee-search .doc-search {
  border-right: 1px solid #d0ece5 !important;
}

.employee-search .doc-search fieldset {
  border: none !important;
}

.employee-search .doc-search input {
  padding-top: 1px;
  padding-bottom: 2px;
  width: 118px;
}

.employee-search .doc-search input::placeholder {
  font-size: 13px;
}

.employee-search-icon {
  fill: #9197b3 !important;
  margin-right: 0 !important;
}

.employee-search-bar-icon {
  position: absolute;
  right: 0;
}

.health-carer-popover {
  width: 255px;
}

.health-carer-popover-img {
  width: 48px;
  height: 56px;
}

#calender .fc .fc-daygrid-more-link {
  color: #15a07d;
  height: 20px;
}

.health-calender-dropdown {
  width: 100%;
}

.health-carer-popover-client {
  color: #15a07d !important;
  border-bottom: 1px solid #15a07d;
}

.health-carer-popover-arrow {
  width: 47px;
  position: absolute;
  z-index: 1;
  right: -33px;
  top: 50%;
  transform: translateY(-50%);
}

.health-carer-popover-button {
  display: inline-block;
  margin-top: 20px;
}

.health-carer-popover-button a {
  width: auto !important;
  margin-left: 0 !important;
}

.cat-list p {
  color: #262728;
}

.cat-list > div {
  display: flex;
  align-items: center;
  color: #1a1a1a;
}

.cat-list #demo-simple-select svg {
  color: #1a1a1a;
  fill: #1a1a1a;
}

/* add appointment new css start here */

#add-appointment .bg-strip {
  margin: 30px 0;
}

.hide-content-map-text {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.list-appoinetment-wrapper .dropdown-menu {
  transform: translateY(87px) !important;
}

#add-appointment .publish-roster-strip {
  padding-left: 0 !important;
}

#add-appointment .hide-client-details {
  box-shadow: 0 0 1px rgba(22, 22, 22, 0.1);
  padding-top: 14px;
}

.appointment-datepicker input {
  text-align: left !important;
  padding-left: 10px !important;
  margin-top: 0 !important;
}

.margin-label-bottom {
  margin-bottom: 8px;
}

.Mui-checked {
  color: #15a07d !important;
}

.gray-radio {
  color: gray !important;
}

.map-add-location-field::placeholder {
  color: #3d4244 !important;
}

#add-appointment .MuiRadio-root.Mui-checked {
  color: #15a07d;
}

.add-task-radio-group .MuiRadio-root.Mui-checked {
  color: #15a07d;
}

.map-Distance-field span {
  margin-left: 10px;
}

.live-roster-checkbox {
  background-color: #f2f5f8;
  padding: 8px 12px 8px 4px;
}

.health-carer-field {
  padding-right: 20px !important;
}

.job-type-field {
  padding-left: 20px !important;
}

/* health week calender css   */

.week-table-border-bottom {
  border-bottom: 1px solid #d0ece5;
}

.week-table-border-right {
  border-right: 1px solid #d0ece5 !important;
}

.employee-th {
  padding: 0 10px !important;
}

.employee-search fieldset {
  border: none !important;
}

.employee-popover .add-svg {
  width: 20px !important;
  height: 20px !important;
}

.employee-popover {
  width: 140px !important;
}

.calender-add-icon {
  height: 24px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.hide + .view-all .less {
  display: none;
}

.show + .view-all .viewall {
  display: none;
}

#health-week-table .calender-popover {
  margin-left: -224px;
  margin-top: -238px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.emlpoyee-timefield-align {
  margin-top: 25px;
}

.employee-table-height > div {
  width: 100%;
}

.healthWeek-checkbox-main {
  flex-direction: row !important;
  width: 100%;
  max-width: 460px;
  background: var(--hb-fill-area-shade, rgba(218, 229, 235, 0.35));
  padding: 6px 16px;
  margin: 50px 0;
}

#calender .calender-client-cell span {
  color: #929495 !important;
}

/* #health-carer-calender .calender-popover {
  left: -157px !important;
  position: absolute !important;
  z-index: 999;
  top: -194px !important;
} */

#calender .fc-scroller-liquid-absolute {
  overflow: visible !important;
}

#calender .fc-scroller-harness-liquid {
  overflow: visible !important;
}

#health-carer-calender .table-edit-del a {
  padding-left: 10px;
  padding-right: 10px;
}

#health-carer-calender .calender-client-cell p {
  justify-content: space-between;
  color: #3d4244;
  font-size: 14px;
}

.appointment-calender-filed-select .calender-category > div {
  display: flex;
  align-items: center;
}

.select-home-icon {
  fill: #a924fb !important;
}

.select-community-icon {
  fill: #e8b500 !important;
}

.select-sda-icon {
  fill: #ff5251 !important;
}

.select-user-icon {
  fill: #00a11a !important;
}

.calender-category p {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
  opacity: 0.6000000238418579;
}

.calender-category svg {
  fill: #9197b3;
}

#health-week-table .calender-popover {
  margin-left: -224px;
  margin-top: -238px;
  position: absolute;
}

/* timesheet filter css  */

.timesheet-filter-datepicker-main {
  width: 300px;
}

#timesheet-filter .date-input-filed {
  margin-top: 0 !important;
}

#timesheet-filter .date-input-filed fieldset {
  border: none;
}

#timesheet-filter .timsheet-filter-datepicker {
  border: 1px solid var(--stroke-border, #e3e8e6);
  border-radius: 4px;
}

.timesheetfilter-button button {
  border-radius: 8px;
  border: none;
  margin: 0 10px;
}

.timesheet-filter-show {
  background-color: #15a07d;
  color: #fff;
  width: 112px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
}

.timesheet-filter-clear {
  background: rgba(21, 160, 125, 0.1);
  color: #15a07d;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

#timesheet-filter {
  border-top: 1px solid var(--stroke-border, #e3e8e6);
  border-bottom: 1px solid var(--stroke-border, #e3e8e6);
  padding: 30px 0;
}

.view {
  color: #00a7e1 !important;
}

/* 
.filter-datepicker-icon {
    background-image: url(../images/calendar_month.svg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 30px;
    height: 24px;
} */

/* NEW CHANGES  */

.personal-info .community-access,
.personal-info .homecare,
.personal-info .silsda,
.personal-info .respite,
.personal-info .respite + label {
  margin-right: 16px !important;
  margin-bottom: 10px;
}

/* timesheet view page css  */

.reject-field p,
.reject-field svg path {
  color: #f02d3a;
  fill: #f02d3a;
}

.approve-field p,
.approve-field svg path {
  color: #109648;
  fill: #109648;
}

.approve-field,
.reject-field {
  padding: 4px 0;
}

.three-dot-section-content {
  /* width: 142px; */
  box-shadow: 0px 4px 15px 0px rgba(22, 22, 22, 0.2);
  border-radius: 4px;
  position: absolute;
  bottom: -71px;
  right: 0;
  z-index: 2;
  background-color: #fff;
}

.view-timesheets-purple-bg {
  background: rgba(100, 93, 215, 0.15);
  padding: 24px 20px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.bg-strip-inner-icon svg {
  font-size: 30px;
}

.bg-strip-inner-icon svg path {
  fill: #15a07d;
}

.timesheet-amount-table-th {
  width: 100px;
}

.timesheet-document-table-th {
  width: 30%;
}

.appointment-dashboard-profile p {
  line-height: 100%;
}

.timesheet-strip-heading {
  font-size: 20px;
  color: #3d4244;
  font-weight: 600;
  font-family: "proxima_novasemibold";
}

.timesheet-strip-text-light {
  color: #8c8c8c;
}

.timesheet-button-group .approve-button {
  background-color: #00a11a !important;
  color: #fff;
  border-radius: 10px !important;
  padding: 10px 16px !important;
  margin-left: 10px;
}

.timesheet-button-group .reject-button {
  border: 1px solid #ff5251;
  color: #ff5251;
  border-radius: 10px;
  padding: 10px 16px;
  margin-right: 10px;
}

.reject-modal-icon {
  text-align: center;
  margin-bottom: 30px;
}

.timesheet-modal {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
}

.timesheet-modal p {
  color: #484747;
}

.reject-reason {
  height: 80px;
  width: 100%;
}

.timesheet-modal-buttons {
  margin-top: 30px;
}

.timesheet-modal-buttons button {
  padding: 8px 42px;
  border-radius: 8px;
  margin: 0 10px;
}

.timesheet-modal-buttons .modal-cancel-button {
  border: 0.5px solid #929495;
  background-color: transparent;
}

.timesheet-modal-buttons .modal-reject-button {
  background-color: #ff5251;
  color: #fff;
  border: none;
}

.timesheet-modal-buttons .modal-approve-button {
  background-color: #41a451;
  color: #fff;
  border: none;
}

.health-week-th {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
}

/* medical history css  */

#medical-history .timesheet-table {
  border: none !important;
}

#medical-table .table-edit-del {
  margin-right: 0;
}

.medical-history #document-table {
  padding: 0 !important;
}

.medical-add-button {
  background-color: #15a07d !important;
}

/* 
=====================
Appointment Schedule Css Start
*/

.appbar-border {
  border-bottom: 0.5px solid #d0ece5;
}

.selected-day {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.headertext-sty.MuiTypography-root {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  /* padding: 10px; */
}

.helpertext-sty.MuiTypography-root {
  margin-left: 10px;
}

.importexportsty {
  margin-right: 35px;
  float: right;
}

.importexportbtn {
  color: #645dd7 !important;
  border-color: #645dd7 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.Hc-viewDetails-subTab .importexportbtn {
  border: 1px solid !important;
  padding: 0 20px !important;
  margin-right: 10px;
}

.importexporticon-image {
  width: 20.5px;
}

/* popup style */

.cardcontent_box {
  background-color: #15a07d;
}

.import_CardContent {
  flex: 1 0 auto;
}

.importfiletext.MuiTypography-root {
  font-size: 14px;
  color: #ffffff;
}

.uploaddata.MuiTypography-root {
  margin-left: 12px;
  text-align: center;
  font-size: 10px;
  color: #ffffff;
}

.cardactionsty2 {
  margin-top: 15px;
}

.cardactionsty2 .filebtn {
  width: 140px;
  /* margin-left: 10px; */
  border-color: #d0ece5;
  color: #3d4244;
  text-transform: capitalize;
}

.importfilebtn {
  /* margin-left: 10px; */
  width: 140px !important;
  border-color: #d0ece5 !important;
  color: #3d4244 !important;
  /* text-transform: capitalize !important ; */
}

.slidertext-sty {
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px !important;
  margin: 6px !important;
  border: 1px solid #d0ece5 !important;
  border-radius: 6px !important;
}

.slidertext-sty .icons-color {
  color: #15a07d;
}

.checkbox-color.Mui-checked {
  color: #15a07d !important;
  margin: 0px;
}

#tabs .checkbox-label {
  padding: 20px 16px;
  border-radius: 8px;
}

.divider-line {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 114%;
  background-color: #7299c7;
}

.formgroupsty {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

/* 
=====================
Appointment Schedule Css End
*/

/* 
=====================
Tabs Css Start
*/

.tab-container {
  border: 1px solid #15a07d;
  border-radius: 8px;
  max-width: 320px;
  height: 1%;
  padding: 2px;
}

.tab-container .Tabstyle-test {
  border-radius: 8px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  min-height: 42px;
  font-size: 17px !important;
  text-transform: capitalize !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.MuiTabs-fixed .MuiTabs-indicator {
  display: none;
}

.tab-container .Tabstyle-test.Mui-selected {
  background: #15a07d;
  color: #fff;
  border: none !important;
}

.tab-container .Tabstyle-test:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.Tabstyle-test .tab-text {
  color: #000000;
}

.Tabstyle-test .tab-text:hover {
  background-color: #15a07d !important;
  color: #ffffff !important;
}

.tabs-maincontainer {
  margin-left: 18px;
}

#document-table .table-head-cell {
  background: #e8f6f2 !important;
}

/* 
=====================
Tabs Css end
*/

/* 
=====================
table Css start
*/

.table-container {
  width: 100%;
  overflow-x: auto;
}

.all-round-borders {
  /* table-layout: fixed; */
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}

.tableHeader {
  font-weight: 300 !important;
  color: #161616 !important;
  background-color: #e6e6e6 !important;
}

.tableHeaders {
  font-weight: 300 !important;
  color: #161616 !important;
  background-color: #e2ecf1 !important;
}

.all-round-borders th,
.all-round-borders td {
  border: 0.2px solid #e8f6f2;
  padding: 16px;
  text-align: left;
  /* white-space: nowrap; */
}

@media only screen and (max-width: 600px) {
  .all-round-borders th,
  .all-round-borders td {
    padding: 8px;
  }
}

.routine-iconcolor {
  color: #15a07d;
}

.routine-iconcolor span {
  text-decoration: underline;
  color: #15a07d;
}

.tableswap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableswap .swap-icon {
  text-align: center;
  color: #924cbd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.swap-icon {
  text-align: center;
  color: #924cbd;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.swap-icon-margin {
  margin-top: 17px !important;
}

.table-popover-wrapper {
  padding: 10px !important;
  top: -126px !important;
  width: 130px !important;
  left: -39px !important;
}

.table-popover-wrapper p {
  font-size: 13px !important;
}

.list-appointment-text-gray,
.list-appointment-text-gray p {
  color: #bababa;
}

.no-data-available {
  margin-top: 4%;
  margin-bottom: 4%;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
}

/* Leave management status css start*/

.status-cell-16,
.appointment-status.status-cell-16 {
  background-color: #fff3c8;
  color: #ffc700;
  position: relative;
}

.status-cell-17,
.appointment-status.status-cell-17 {
  background-color: #c5fff1;
  color: #02a37c;
  position: relative;
}

.status-cell-18,
.appointment-status.status-cell-18 {
  background-color: #ffdddf;
  color: #f02d3a;
  position: relative;
}

.status-cell-19,
.appointment-status.status-cell-19 {
  background-color: #ffe2c8;
  color: #f17300;
  position: relative;
}

.status-cell-20,
.appointment-status.status-cell-20 {
  background-color: #b5e6f7;
  color: #00a7e1;
  position: relative;
}

.status-cell-21,
.appointment-status.status-cell-21 {
  background-color: #affed0;
  color: #109648;
  position: relative;
}

.status-cell-22,
.appointment-status.status-cell-22 {
  background-color: #fff3c8;
  color: #ffc700;
  position: relative;
}

.status-cell-23,
.appointment-status.status-cell-23 {
  background-color: #ffdddf;
  color: #f02d3a;
  position: relative;
}

.status-cell-24,
.appointment-status.status-cell-24 {
  background-color: #ffe2c8;
  color: #f17300;
  position: relative;
}

/* Leave management status css end*/

.status-cell-1,
.appointment-status.status-cell-1 {
  background-color: #fdeeba;
  color: #e8b500;
  position: relative;
}

/* Use for Swap and approved*/

.status-cell-2,
.appointment-status.status-cell-2 {
  background-color: #affed0;
  position: relative;
  color: #109648;
}

.status-cell-3,
.appointment-status.status-cell-3 {
  background-color: #ccf4f8;
  position: relative;
  color: #3cbbc8;
}

.status-cell-4,
.appointment-status.status-cell-4 {
  background-color: #c9c4ff;
  position: relative;
  color: #5d53c4;
}

.status-cell-5,
.appointment-status.status-cell-5,
.badge-reject {
  position: relative;
  background-color: #ffe8e8;
  color: #ff5251;
}

.status-cell-6,
.appointment-status.status-cell-6 {
  background-color: #ffeba4;
  position: relative;
  color: #e8b500;
}

.status-cell-7,
.appointment-status.status-cell-7 {
  background-color: #a4ffb3;
  position: relative;
  color: #00a11a;
}

.status-cell-8,
.appointment-status.status-cell-8 {
  position: relative;
  background-color: #ccf4f8;
}

.status-cell-9,
.appointment-status.status-cell-9 {
  background-color: #c9c4ff;
  position: relative;
  color: #5d53c4;
}

.status-cell-10,
.appointment-status.status-cell-10 {
  background-color: #ffcdcd;
  position: relative;
}

.status-cell-11,
.appointment-status.status-cell-11 {
  background-color: #ffeba4;
  position: relative;
}

.status-cell-12 {
  background-color: #a4ffb3;
  position: relative;
}

.status-cell-13 {
  background-color: #ccf4f8;
  position: relative;
}

.status-cell-14 {
  background-color: #c9c4ff;
  position: relative;
}

.status-cell-15 {
  position: relative;
  background-color: #ffcdcd;
}

/* table cell end  */

.tablebutton-section {
  background-color: #d0ece5;
  /* padding: 15px 0; */
}

.buttom-check {
  padding: 0px 10px;
  background-color: transparent;
  border-radius: 8px;
}

.buttom-check2 {
  padding: 8px 16px;
  background-color: transparent;
  border-radius: 8px;
}

.calenderList-checkbox {
  padding: 0 10px;
  margin-bottom: 50px;
}

/* Media Queries  */

@media only screen and (max-width: 600px) {
  .headertext-sty.MuiTypography-root {
    font-size: 20px;
  }
}

/* #add-medical-history .Mui-checked {
  color: #15a07d !important;
} */

#add-medical-history .Mui-checked {
  color: #15a07d !important;
}

#check-grey .Mui-checked {
  color: #808080 !important;
}

.chk-grey {
  color: #808080 !important;
}

.textarea-md {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px;
  height: 100px;
  padding: 10px 0 0 10px;
  color: #bababa;
  font-size: 16px;
  outline: none;
  font-family: "proxima_novaregular";
}

.medical-add-more-button {
  color: #645dd7;
  background-color: #fff;
  border: 1px solid #645dd7;
  border-radius: 4px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
}

.medical-add-more-button svg {
  margin-right: 8px;
}

.medical-add-more-button-small {
  color: #645dd7;
  background-color: #fff;
  border: 1px solid #645dd7;
  border-radius: 4px;
  padding: 6px 6px;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.medical-add-more-button-small svg {
  font-size: 12px;
}

.medical-cheeps {
  background-color: #f2f5f8 !important;
  border-radius: 4px !important;
}

.medical-cheeps svg path {
  color: rgba(255, 82, 81, 1);
}

.mb-10 {
  margin-bottom: 10px !important;
}

.text-dark {
  color: #3d4244;
}

.bg-none {
  background-color: transparent !important;
}

.observation-checkbox {
  flex-direction: row !important;
}

#add-medical-history .timesheet-table {
  border: none !important;
}

.table-cell-100 {
  width: 100px;
}

.medical-main-sub-td {
  height: auto !important;
}

.date-field .carousel__wrap {
  justify-content: start;
}

.date-field .select-slot-calender {
  width: 250px !important;
  height: 18px !important;
  border: none !important;
}

.medical-sub-datefield {
  padding: 10px 0;
  background-color: #f2f2f2;
  border-bottom: 1px solid var(--stroke-border, #e3e8e6);
}

.medical-sub-timefield div {
  margin: 15px 0;
}

.medicalSub-head-cell {
  background-color: #f2f2f2;
  border: 1px solid #e3e8e6;
}

.popup-alert-icon {
  width: 80px;
  margin: auto;
}

.popup-alert-icon img {
  width: 100%;
  object-fit: cover;
}

.medical-popup-heading {
  font-size: 20px;
  font-family: "proxima_novasemibold";
  color: #202e2a;
}

#add-medical-history .modal-content {
  background-color: #fff !important;
}

.popup-type {
  margin: 20px 0;
}

.popup-type p {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novaregular";
  padding-bottom: 0;
  margin-right: 4px;
}

.popup-type span {
  color: #202e2a;
  font-size: 20px;
  font-family: "proxima_novasemibold";
}

.attach-file-modal .MuiDialog-paper {
  max-width: 600px;
  width: 100%;
  padding: 0 30px;
}

.timefield-popup input {
  padding: 5.5px 14px;
}

.table-edit img {
  width: 16px;
  /* margin-right: 4px; */
}

.medical-pdfIcon {
  width: 24px;
}

.medical-pdfIcon img {
  width: 100%;
  object-fit: cover;
}

#add-medical .appointment-calender-filed-select fieldset {
  border: none !important;
}

.add-medical-require {
  height: 30px !important;
  margin-top: 32px !important;
}

#add-medical .slot-calender {
  width: auto !important;
}

.add-medical-require .appointment-calender-filed-select p {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

.add-medical-require-select > div {
  padding-top: 0 !important;
}

.add-medical-require-select svg {
  top: -3px;
}

.add-medical-require-select .MuiSelect-select {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novasemibold";
}

.required-time-box {
  width: 110px;
  margin-right: 10px;
}

#add-medical .slide-filed {
  margin-left: 0 !important;
}

.require-week {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.require-week:hover {
  background-color: #b1aeeb;
}

.require-week p {
  font-size: 16px;
  color: #202e2a;
  font-family: "proxima_novasemibold";
}

.require-week:hover p {
  color: #fff;
}

/* #add-medical .agency-btn-section {
    margin-top: 90px;
} */

.medical-month-field .date-input-filed input {
  padding: 7px 0 7px 4px;
}

.add-medical-require {
  height: auto !important;
}

.add-medication .date-input-filed input,
.edit-medication .date-input-filed input {
  padding-top: 7px;
}

.client-manage-modal {
  width: 400px;
}

.client-manage-modal #alert-dialog-description {
  padding: 20px 20px 0 20px;
}

.client-popover-head {
  padding: 20px 20px 0 20px;
}

.clientmanage-popup-tab {
  border-radius: 6px;
  width: 182px;
  min-height: 35px !important;
  border: 0.75px solid var(--primary-button, #15a07d);
  margin-top: 15px;
}

.client-tab-item {
  min-height: 33px !important;
}

.clientmanage-popup-tab .Mui-selected {
  background: #15a07d;
  color: #fff !important;
}

.client-manage-tabpanel .task-tab-main .task-tab-row-typo {
  font-size: 13px;
  color: #3d4244;
  font-family: "proxima_novasemibold";
}

.client-manage-tabpanel .task-tab-main .task-tab-row-typo svg {
  color: #15a07d;
}

.client-manage-tabpanel .task-tab-main .task-tab-row-main {
  border-radius: 4px 4px 0px 0px;
  border-top: 1px solid #dae5eb;
  border-right: 1px solid #dae5eb;
  border-left: 1px solid #dae5eb;
  padding: 4px 16px;
}

.client-manage-tabpanel {
  padding-bottom: 24px !important;
}

#client-manage-popup-main #simple-tabpanel-0 > .MuiBox-root {
  padding-bottom: 0 !important;
}

#client-manage-popup-main #simple-tabpanel-1 > .MuiBox-root {
  padding-bottom: 0 !important;
}

#client-manage-popup-main .Mui-expanded {
  margin: 0 !important;
}

#client-manage-popup-main .task-tab-row-span {
  display: flex;
  align-items: center;
}

#client-manage-popup-main .accordian-details-text .task-tab-row-span {
  font-size: 10px;
}

.span-italic {
  font-style: italic !important;
}

#meetingCell .MuiButton-contained {
  display: none !important;
}

.client-manage-popup-shadow > .MuiPaper-elevation {
  box-shadow: 0px 4px 15px 0px rgba(22, 22, 22, 0.2) !important;
}

.meeting-cell {
  width: 100px;
}

.hundred-point-table {
  padding: 20px;
}

.hundred-point-table .table-bottom-content {
  padding-bottom: 0 !important;
}

#sidebar img {
  object-fit: contain !important;
  width: 25px;
}

#my-account-main-tab #simple-tabpanel-3 > div,
#my-account-main-tab #simple-tabpanel-1 > div {
  padding: 0 20px 0 20px !important;
}

.assesment-radio {
  margin-left: 10px;
}

/* #calender .fc .fc-popover {
  background-color: red !important;
} */

/* media query css start  */

.radio-box-wrraper > label > span {
  margin-right: 10px;
}

.radiolabel label {
  font-family: "proxima_novaregular" !important;
}

.radio-box-wrraper label {
  font-family: "proxima_novaregular" !important;
}

.off-boarding-main .form-input {
  margin-bottom: 0;
}

.category-filter-icon-button {
  height: 17px;
}

.calender-field-title {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  max-width: 80px;
  display: inline-block !important;
}

.table-scroll {
  overflow-x: scroll;
}

.table-scroll::-webkit-scrollbar {
  height: 6px;
  width: 160px;
  background-color: #15a07d;
}

.table-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

#fund-management .show-button {
  background-color: #15a07d;
  color: #fff;
  border-radius: 6px;
  padding: 8px 40px;
  font-family: "proxima_novabold";
}

#fund-management .client-detail-section {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 10px;
}

#fund-management .hide-content p span {
  margin-left: 10px;
}

#fund-management .hide-client-img {
  width: 170px;
  height: auto;
  margin: 0 20px 0 auto;
}

#fund-management .hide-client-img img {
  height: auto;
}

#fund-management .client-address-icon {
  width: 20px;
  margin-right: 10px;
  height: unset;
}

#fund-management .client-address-icon img {
  width: 100%;
  height: unset;
}

.client-address-icon {
  width: 25px;
}

.client-detail-box-footer {
  background-color: #ebeeed;
  padding: 20px 0;
  margin-top: -3px;
  position: relative;
  z-index: -1;
}

.client-subimage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.client-subimage img {
  width: 100%;
  object-fit: cover;
}

.client-subContent p {
  font-size: 16px;
  font-family: "proxima_novasemibold";
  color: #202e2a;
  line-height: 14px;
}

.client-subContent span {
  font-size: 13px;
  font-family: "proxima_novaregular" !important;
  color: #8c8c8c;
}

#fund-management .tab-container {
  max-width: 293px;
}

.fund-manage-tabBox {
  margin-top: 30px;
  margin-left: 20px;
}

.fund-bar-box {
  background-color: rgba(100, 93, 215, 0.15);
  padding: 15px 32px;
  margin-top: 32px;
}

.fund-bar-box-2 {
  background-color: #f5dcee;
  padding: 15px 32px;
  margin-top: 32px;
}

.fund-bar-box-3 {
  background-color: #fc7a5726;
  padding: 15px 32px;
  margin-top: 32px;
}

.fund-bar-content span {
  color: #202e2a;
  font-size: 14px;
  font-family: "proxima_novaregular" !important;
}

.fund-bar-content p {
  color: #202e2a;
  font-size: 18px;
  font-family: "proxima_novabold";
}

.fundImage {
  width: 48px;
  margin-right: 16px;
}

.fundImage img {
  width: 100%;
  object-fit: cover;
}

.fund-edit {
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 50px;
  cursor: pointer;
}

.fund-edit-border {
  border-left: 2px solid rgba(186, 186, 186, 1);
  padding-left: 20px;
}

.fund-edit img {
  width: 16px;
  margin-right: 5px;
}

.fund-edit p {
  font-size: 16px;
  font-family: "proxima_novaregular" !important;
  color: rgba(100, 93, 215, 1);
}

.support-cell-sub-content {
  margin-top: 16px;
}

.support-subCell {
  padding-left: 40px;
  margin-bottom: 16px;
}

.support-subCell p {
  font-size: 16px;
  font-family: "proxima_novaregular" !important;
  color: rgba(32, 46, 42, 1);
}

.support-subCell span {
  background-color: rgba(16, 150, 72, 1);
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: "proxima_novabold";
  margin-top: 4px;
}

.LabelColorVk {
  background-color: rgba(128, 115, 161, 1) !important;
}

.Support-Budget {
  width: 76%;
}

.support-sm-td {
  width: 136px !important;
}

.fundTableMain {
  margin-top: 30px;
}

.Support-Budget-total {
  border: 1px solid #e3e8e6;
  background: rgba(227, 232, 230, 0.7);
  height: 51px !important;
}

.Support-Budget-total a span,
.Support-Budget-total span {
  color: #202e2a;
  text-align: right;
  font-size: 16px;
  width: 100%;
  line-height: normal;
  font-family: "proxima_novabold";
}

.addFund-field {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#fund-management .fundcheck-button {
  min-width: 42px !important;
  background-color: #15a07d;
  color: #fff;
  border-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  padding: 7px 0;
  text-align: center;
}

#fund-management .fundcheck-button svg {
  height: 100%;
}

#fund-management .addFund-field fieldset {
  border: none;
}

.addFund-field {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 6px;
}

.addFund-field .form-input {
  width: calc(100% - 32px);
}

.addFund-field .form-input input {
  padding: 7px 10px !important;
}

.amount-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  outline: none;
  padding: 0 10px;
  height: 40px;
  width: 100px;
  border-radius: 6px;
}

.mobile-number {
  color: #15a07d !important;
}

.email-success {
  color: #15a07d !important;
}

.new-progress .remaining-value {
  margin-left: 0;
}

#fund-management .hide-client-img {
  margin: 0;
}

#fund-management .hide-client-img-main {
  margin-left: 0 !important;
  margin-top: 11px;
}

#fund-management .hide-client-img-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;
  padding-right: 30px;
  border: none !important;
}

#fund-management .client-profile-detail {
  padding-right: 30px;
}

.fund-note {
  background-color: rgba(255, 221, 223, 1);
  padding: 27px 0px 27px 27px;
  margin-top: 60px;
}

.fund-note p {
  color: #000;
  font-size: 16px;
  font-family: "proxima_novabold";
}

.fund-note span {
  color: #000;
  font-size: 16px;
  font-family: "proxima_novaregular" !important;
  padding-left: 8px;
}

/* induction screen css start  */

.induction-add-button {
  font-family: "proxima_novabold" !important;
}

.induction-category-card {
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px !important;
  width: 100%;
  border: 1px solid #f9f9f9;
  box-shadow: 0 0 15px #f1f1f1 !important;
  background-color: #fff !important;
}

.add-induction-heading {
  background-color: rgba(21, 160, 125, 0.2) !important;
  color: #15a07d !important;
  border-radius: 20px !important;
  padding: 3px 12px !important;
  margin: 7px 0 !important;
  font-size: 12px !important;
}

.add-induction-heading-wrraper {
  width: 210px;
  margin-left: auto;
}

.induction-category-description {
  font-size: 14px;
  font-family: "proxima_novaregular" !important;
  color: #454545 !important;
}

#induction-category .dropdown-menu-action {
  top: 9px !important;
  right: 24px !important;
  border: 1px solid #f9f9f9;
  box-shadow: 0 0 15px #d0ece5;
  margin: 0 !important;
}

/* induction screen css end  */

#induction-title-screen .induction-category-card {
  margin: 20px 0;
}

.induction-heading {
  font-size: 20px;
}

.induction-table-cell {
  padding-top: 4px !important;
  padding-bottom: 12px !important;
}

.induction-title-dropdown {
  margin-left: 30px;
  margin-top: -10px;
}

.pay-run-input {
  margin: 0 20px;
}

#pay-run .filter-search-field .payRun-Filter-dropdown {
  display: none;
}

#pay-run .filter-search-field:nth-child(3) .payRun-Filter-dropdown {
  display: block;
}

.pay-run-input-light-txt {
  color: #8c8c8c;
  margin-left: 6px;
}

.run-pay-subTabelcell {
  height: 40px !important;
}

.paySlip-btn {
  color: #15a07d !important;
}

.paySlip-btn svg path {
  color: #15a07d !important;
}

.input-icon-field-padding input {
  padding-left: 14px;
}

.client-wrraper-padding {
  padding-left: 10px;
}

/* super admin goal view css  */

.gaol-accordion .accrdingbg-color {
  flex-direction: row-reverse !important;
}

.gaol-accordion .MuiAccordionSummary-expandIcon {
  padding-left: 0 !important;
}

.gaol-accordion .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: none !important;
}

.gaol-accordion
  .MuiAccordionSummary-expandIcon.Mui-expanded
  .view-routine-accordion-arrow {
  transform: rotate(180deg);
}

.goal-accordian-buttons svg {
  width: 20px;
  margin-right: 4px;
}

.goal-accordian-buttons {
  font-family: "proxima_novaregular";
  margin-left: 10px;
  margin-right: 0 !important;
}

.gaol-accordion {
  box-shadow: 0 4px 10px #f9e4e4 !important;
}

/*
*******************************
explore market view details css  
*******************************
*/

.view-details-status-cheeps {
  border: 1px solid #dae5eb;
  border-radius: 20px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 4px !important;
  cursor: pointer;
}

.view-details-status-cheeps span {
  padding: 0 !important;
}

.view-details-status-cheeps svg {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.view-details-status-cheeps p,
.view-details-status-cheeps span {
  font-size: 13px;
  color: #202e2a;
}

.view-details-status-cheeps label {
  margin-right: 0 !important;
}

.view-details-status-accepted svg path {
  fill: #c5fff1 !important;
}

.view-details-status-rejected svg path {
  fill: #ffdddf !important;
}

.view-details-status-pending svg path {
  fill: #fff3c8 !important;
}

.view-details-status-appointed svg path {
  fill: #affed0 !important;
}

.view-details-status-cancelled svg path {
  fill: #ffe2c8 !important;
}

.view-details-status-accepted-cl {
  background-color: #c5fff1;
}

.view-details-status-rejected-cl {
  background-color: #ffdddf;
}

.view-details-status-pending-cl {
  background-color: #fff3c8;
}

.view-details-status-appointed-cl {
  background-color: #affed0;
}

.view-details-status-cancelled-cl {
  background-color: #ffe2c8;
}

.view-details-divider {
  padding: 0 56px;
}

.view-details-status-transparent {
  background-color: transparent !important;
}

.all-status-cheep {
  background-color: var(--Primary_Light_10, rgba(0, 0, 0, 0.05)) !important;
}

.view-details-calender-main .calender-view-gray {
  background-color: transparent !important;
}

.view-details-calender-field {
  background-color: #e2e8e6;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0;
}

.view-details-calender-nofield-cl {
  background-color: #e2e8e6;
}

.view-details-calender-field-cl {
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px 0;
}

.view-details-calender-iconField {
  margin: 14px 10px 0px 0;
}

.clientView-details-calender-iconField {
  margin: 8px 10px 0px 0;
}

.clientView-details-calender-iconField,
.clientView-details-calender-iconField svg path {
  fill: #15a07d;
  color: #15a07d;
}

.booked-icon {
  color: #645dd7;
}

.view-booked-text {
  color: #a6a9a8;
  font-style: italic;
}

.client-view-detail-wrapper .select-slot-calender {
  margin-left: 6px !important;
}

.client-module-view-details .view-details-main-box {
  box-shadow: none;
}

.client-module-view-details .view-profile-detail {
  padding: 0 50px !important;
}

.client-module-view-details .view-tap-box {
  padding: 20px 20px;
}

/* 
.client-module-view-details .view-detail-google-map { 
   height: 800px;
} */

.client-module-view-details .view-tap-box .tab-container {
  max-width: 100% !important;
}

.client-module-view-details .calender-client-cell span {
  font-size: 13px !important;
}

.view-details-table .employe-td {
  background-color: #0086e6;
}

.client-module-view-details .fc-day-today,
.explore-view-details-main .fc-day-today {
  background-color: transparent !important;
}

.client-module-view-details .fc-day-today .fc-daygrid-day-number,
.explore-view-details-main .fc-day-today .fc-daygrid-day-number {
  background-color: #15a07d;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}

.client-module-view-details .fc-daygrid-day-number,
.explore-view-details-main .fc-daygrid-day-number {
  margin-top: 4px;
}

.client-module-view-details .view-tab-chip .MuiChip-filled,
.explore-view-details-main .view-tab-chip .MuiChip-filled {
  margin-bottom: 5px;
}

/* view detail service provider css  */

.view-detail-sp-clientBox {
  width: 60%;
  margin: 30px auto !important;
  overflow: visible !important;
  padding: 20px !important;
}

.view-detail-sp-clientBox .hide-client-img-box {
  padding-right: 20px;
}

.view-detail-sp-clientBox .hide-client-img-box p {
  color: #15a07d !important;
}

.view-service-provider-accordian {
  box-shadow: none !important;
}

.reject-danger-bg {
  background: #f02d3a !important;
}

.reject-danger-border {
  border: 1px solid #f02d3a !important;
}

.approve-success-bg {
  background: #109648 !important;
}

.reject-danger-button {
  color: #f02d3a !important;
}

.reject-danger-button:hover {
  background: none !important;
}

.export-hc-button {
  border: 1px solid #645dd7 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 4px !important;
  margin-right: 15px !important;
}

.export-hc-button .importexporticon-image {
  width: 15px;
}

.hc-list-editButton {
  margin-top: -19px;
}

/* .swal2-styled.swal2-confirm { 
  background-color: #15A07D !important;
} */
.swal2-styled {
  box-shadow: none !important;
}

.makeStyles-dialogactionbtn-10 {
  background-color: #15a07d !important;
}

.downloadzip-button {
  color: #645dd7 !important;
  margin-left: 10px;
}

.downloadzip-button svg path {
  fill: #645dd7;
}

.history-action-button {
  color: #645dd7 !important;
}

.view-action-button {
  color: #00a7e1 !important;
}

.makeStyles-dialogactionbtn-3 {
  background-color: #15a07d !important;
}

.history-action-button img {
  margin-right: 7px;
}

.meeting-error {
  color: #d32f2f;
  font-size: 13px;
}

.error-text p {
  margin-left: 0 !important;
}

.edit-client-list {
  margin-bottom: -22px;
}

.task-edit-attach-document input {
  accent-color: #15a07d !important;
}

.select-category-main .MuiSelect-select .default-menuSelect {
  display: none;
}

.popup-button-green {
  background-color: #15a07d !important;
}

.refrence-tabelcell {
  width: 120px;
}

.meating-detail-tabelCell {
  width: 120px;
}

.signUp-TableCell {
  width: 100px;
}

.client-details-box-wrapper {
  width: calc(100% - 160px);
}

.anyone-radio-input {
  margin-top: 9px;
}

.font-italic {
  font-style: italic !important;
}

.add-task-fileUpload p,
.add-task-fileUpload svg path {
  color: #bababa;
  fill: #bababa;
}

.download-file-button {
  font-family: "proxima_novaregular";
}

.download-file-image {
  width: 200px;
  height: 200px;
}

.status-item {
  color: #bababa !important;
}

.clientEvaluation-tab-row > div {
  margin: 0 !important;
}

.client-evaluation-details-dropdown {
  background: none !important;
  border: none !important;
  width: 385px;
  height: 100% !important;
}

.client-evaluation-details-dropdown .client-modal {
  top: 20px !important;
  background: #fff;
  left: auto !important;
  right: 30px !important;
  z-index: 999;
  border: 1px solid #d0ece5;
  transform: none !important;
  border-radius: 10px;
  padding: 8px 10px;
  width: 100%;
  margin-right: 0 !important;
}

.clientEvaluation-tab-row {
  min-height: 27px !important;
  padding: 4px 16px !important;
}

.clientEvaluation-tab-row .Mui-expanded svg {
  transform: rotate(-90deg);
}

.client-eval-viewIvon,
.client-eval-viewIvon svg {
  color: #00a7e1 !important;
  color: #00a7e1 !important;
}

.view-DetailsButton {
  padding: 6px 15px;
  border-radius: 6px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

.view-pop-RejectButton {
  background-color: #fff;
  border-color: #f13a46;
  color: #f13a46;
}

.view-pop-ApproveButton {
  background-color: #109648;
  border-color: #109648;
  color: #fff;
}

.clear-button-bgLight {
  background-color: rgba(21, 160, 125, 0.1) !important;
  color: #15a07d !important;
}

.add-routine-typeIcon {
  width: 48px;
  height: 48px;
  margin-right: 20px;
}

.add-routine-typeIcon svg {
  width: 100% !important;
  height: 100% !important;
}

.routine-selected-type p {
  color: #929495;
  font-size: 16px;
  font-family: "proxima_novaregular";
}

.routine-selected-type span {
  color: #3d4244;
  font-size: 16px;
  font-family: "proxima_novaregular";
}

.swap-button-dropdown,
.swap-button-dropdown a {
  color: #875379 !important;
}

.tablelist-appt-view,
.tablelist-appt-view a {
  color: #00a7e1 !important;
}

.refrence-close-button {
  width: 30px;
  position: absolute !important;
  top: 6px;
  right: 6px;
}

.MuiPickersDay-today {
  background-color: #fff !important;
  /* color: #15A07D !important; */
  color: #000 !important;
  color: transparent !important;
  border-color: #15a07d !important;
}

.tablelist-appt-view svg {
  margin: 0 8px 0 5px !important;
}

.client-view-button {
  margin-top: 20px !important;
}

.overflow-none {
  overflow: unset !important;
}

.shadow-none {
  box-shadow: none !important;
}

.start-appointment-hideClient {
  padding-bottom: 8px !important;
}

.hide-client-status fieldset > legend {
  display: none !important;
}

.tooltip-header-hc {
  min-width: 40px;
}

.tooltip-header-hc .MuiButton-label > div {
  margin-left: 0 !important;
}

.hc-header-tooltip-wrapper .tooltip-header-hc:hover {
  background-color: transparent !important;
}

.header-tooltip-box::before {
  position: absolute;
  border-top: 10px solid #eee;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  bottom: -18px;
  left: 10px;
}

.header-tooltip-box::after {
  position: absolute;
  border-top: 10px solid #fff;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  bottom: -16px;
  left: 10px;
}

.header-tooltip-box {
  top: -98px !important;
  left: 10px !important;
  background-color: #fff !important;
}

.header-tooltip-text {
  font-size: 12px !important;
  color: #000;
  font-family: "proxima_novaregular";
}

.header-tooltip-box div {
  background-color: #fff !important;
}

.header-tooltip-box .tooltip-header-hc {
  box-shadow: 0 0 15px #f1f1f1 !important;
  border: 1px solid #f9f9f9 !important;
  border-radius: 4px !important;
}

#health-carer-appbar .MuiTooltip-arrow {
  color: #fff !important;
}

.tooltip-hc-icon {
  width: 24px;
}

#superAdmin-stepper .stepper-box-main {
  flex-direction: column;
}

#superAdmin-stepper .stepper {
  flex-direction: row;
}

#superAdmin-stepper .step-box .step-lable {
  flex-direction: column;
}

#superAdmin-stepper .step-lable span:nth-child(1) {
  margin-bottom: 20px;
}

#superAdmin-stepper .step-lable span {
  text-align: center;
}

#superAdmin-stepper .stepper {
  width: 80%;
  margin: auto;
}

#superAdmin-stepper .step-lable > span > div {
  left: -10px;
}

#superAdmin-stepper .MuiStepConnector-alternativeLabel {
  top: -40px;
  right: auto;

  transform: rotate(90deg);
}

#superAdmin-stepper .MuiStepConnector-lineVertical {
  min-height: 101px;
}

#superAdmin-stepper .stepper-form-SA {
  width: 100%;
  padding: 20px 0;
  box-shadow: 0 0 15px #f1f1f1;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
}

#superAdmin-stepper .stepper-form-SA-wrapper {
  padding-left: 40px;
  width: 100%;
  padding-right: 40px;
}

.stepper-form-SA-wrapper button {
  cursor: pointer !important;
}

.edit-view-button {
  color: #00a7e1 !important;
}

.edit-buttonCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.action-column-wrapper {
  min-width: 122px !important;
}

.action-dropdown-wrapper {
  width: 128px !important;
}

.tapPanel-wrapper > div {
  padding: 0 !important;
}

.meating-details-color {
  color: #645dd7 !important;
}

.onBoard-stepperTab-heading {
  margin-left: 10px !important;
}

.hide-client-img-box-height {
  height: 288px;
}

.table-type-TableCell:nth-child(9) {
  min-width: 60px !important;
}

.table-type-TableCell:nth-child(9) > div {
  width: 60px !important;
}

.table-type-TdCell {
  min-width: 60px !important;
}

.routine-cell-padding {
  padding-top: 4px !important;
}

.calender-dataPop-status > a {
  width: auto !important;
  border-radius: 40px !important;
  padding: 8px 10px !important;
  display: inline-block !important;
}

.calender-dataPop-detailsButton > a {
  color: #00a7e1 !important;
}

.tooltip-hc-iconImage {
  /* height: 45px; */
  /* width: 45px; */
  overflow: hidden;
}

.tooltip-hc-iconImage img {
  width: 27px;
  transform: scale(1.1);
  margin-left: -1px;
  margin-top: -4px;
}

.hc-tooltip-imgWrapper {
  width: 26px;
  height: 26px;
  overflow: hidden;
}

.hc-tooltip-imgWrapper img {
  width: 28px;
  height: 22px;
  object-fit: cover;
  margin-left: -1px;
}

.header-profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.table-icon-popover {
  width: 160px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.table-width-custom {
  width: 112px !important;
}

.header-profile-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.tab-main .client-popup-tab .tab-icon {
  width: 18px;
  margin-top: -3px;
  left: 10px !important;
}

.tab-main .client-popup-tab .tab-iconNotes::after {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 999;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  content: "+";
}

.tab-main .client-popup-tab .Mui-selected .tab-icon {
  filter: none;
}

.task-tab-row-typo {
  color: #3d4244;
}

.task-tab-row-typo svg {
  fill: #15a07d;
  margin-left: 4px;
}

.client-popUp-Status {
  color: #3d4244;
}

.Self-Onboarding-button {
  position: absolute;
  top: -10px;
  right: 20px;
}

.Self-Onboarding-button button {
  width: auto !important;
  display: inline-block;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.Self-Onboarding-button span {
  font-size: 13px;
  color: #202e2a;
  display: block;
}

.SA-onBoarding .MuiStepConnector-lineVertical {
  border-left-width: 3px;
  min-height: 175px !important;
}

.off-boarding-stepper .MuiStepConnector-lineVertical {
  min-height: 100px !important;
}

.off-boarding-stepper .MuiStepConnector-alternativeLabel {
  top: -26px !important;
}

/* .onBoarding-horizontal-stepper .MuiStepConnector-lineVertical {
  border-left-width: 3px;
} */

.SP-onBoarding .MuiStepConnector-line {
  border-color: #e5e5e5;
}

.SP-onBoarding .MuiStepConnector-alternativeLabel {
  top: -85px !important;
}

.SA-onBoarding .MuiStepConnector-alternativeLabel {
  top: -66px !important;
}

.SP-onBoarding .MuiStepConnector-completed span {
  border-color: #15a07d;
}

.stepper-box-main .MuiStepConnector-active span {
  border-color: #15a07d !important;
}

.SP-titleSelect-Menu {
  padding-left: 10px !important;
}

.SP-titleSelect-Label {
  padding-right: 10px !important;
}

.expiryDate-text {
  font-size: 13px;
  color: #212121;
  font-family: "proxima_novaregular";
}

.browse-label {
  background-color: #f5f5f5 !important;
  padding: 2px 4px !important;
  border-radius: 3px !important;
}

.browse-label p {
  font-size: 13px;
}

.browse-label svg {
  width: 20px;
}

.hundred-points p {
  font-size: 14px;
  color: #202e2a;
}

.hundred-points svg path {
  fill: #15a07d;
}

.hundred-points span {
  font-size: 16px;
  margin-left: 10px;
  font-family: "proxima_novabold" !important;
}

.reference-stepperHeading {
  font-size: 20px;
  font-family: "proxima_novabold" !important;
}

.SP-PopUp-Icon {
  background-color: #109648;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SP-PopUp-Icon svg path {
  fill: #fff;
}

.SP-PopUp-Icon svg {
  width: 40px;
}

.SP-stepper-Popup .MuiDialog-container > div {
  max-width: 400px;
  width: 100%;
}

.SP-stepper-Popup .MuiDialogContentText-root p {
  color: #212121 !important;
}

.SP-stepper-popUp-button {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 20px !important;
}

.stepper-employee-id span {
  font-size: 16px;
  margin-left: 5px;
  font-family: "proxima_novabold" !important;
}

.SP-listAppointment .filter-search-field:nth-child(11) .input-group {
  display: none !important;
}

.SP-listAppointment .table-head-cell:nth-child(11) .fliter-img {
  display: none;
}

.Appointment-Table-Wrapper {
  padding-top: 20px;
}

.table-search-filterText {
  font-size: 13px !important;
}

.SP-stepper-form fieldset {
  border-color: #e3f0ed !important;
}

.titleSelect-Menu-light .MuiSelect-select {
  color: #9d9fa0 !important;
}

.AFP-expiry {
  color: #000000;
  font-size: 16px;
  font-style: italic;
}

.stepper-sublabel {
  font-style: italic;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

.MuiPickersDay-today {
  /* color: #fff !important; */
  color: #000 !important;
  border-radius: 50% !important;
}

.SP-stepper-heading {
  padding-left: 40px;
  padding-right: 20px;
}

.stepper-buttons button {
  text-transform: capitalize !important;
}

.stepper-form-checkBoxLabel .MuiFormControlLabel-label {
  color: #3d4244;
  font-family: "proxima_novasemibold" !important;
}

#personal-info .SP-stepper-heading-sec {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}

.swal2-actions {
  flex-direction: row-reverse;
}

.appointment-location-add {
  width: 250px;
}

.location-dashed-border {
  width: 30px;
  height: 217px;
  border: 1px dashed #15a07d;
  position: absolute;
  top: 40%;
  left: 30px;
  border-right: 0;
  z-index: 1;
  transform: translateY(-49%);
}

.location-details-wrapper {
  padding-left: 60px !important;
}

.location-dashed-afterLine::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #15a07d;
  z-index: 2;
}

.add-location-button {
  color: #645dd7;
  font-size: 16px;
  font-family: "proxima_novaregular";
  padding: 10px 16px;
  background: #fff;
  border-radius: 8px;
  outline: none;
  border: 1px solid #645dd7;
  margin-top: 10px;
}

.add-location-button svg {
  margin-right: 10px;
}

.appointment-checkbox-label .checkbox-label {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.appointment-clear-button {
  background-color: rgba(21, 160, 125, 0.1) !important;
  color: #15a07d !important;
  padding: 10px 16px;
  border-radius: 4px;
}

.clientDetail-teams-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.clientDetail-teams-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.clientDetail-teams-content {
  margin-left: 8px;
}

.clientDetail-teams-content p {
  font-size: 16px;
  font-family: "proxima_novaregular";
  color: #202e2a;
  line-height: 12px;
}

.clientDetail-teams-content span {
  font-size: 13px;
  color: #8c8c8c;
  font-family: "proxima_novaregular";
}

.appointment-started-main .hide-client-details,
.appointment-view-details-main .hide-client-details {
  background-color: #fff;
  padding-bottom: 0 !important;
  width: 94%;
  margin: auto;
}

.appointment-started-teams-wrapper {
  background: #e3e8e6;
  width: 100% !important;
  margin-left: 0 !important;
  padding: 32px 0;
}

.appointment-started-main .service-strip,
.appointment-view-details-main .service-strip {
  margin: 32px 0;
}

.location-Select-icon {
  width: 24px;
}

.location-Select {
  width: 215px !important;
}

.appointment-started-main .routine-chip {
  border-radius: 4px;
  background: rgba(100, 93, 215, 0.1) !important;
  padding: 8px;
  width: auto;
  display: inline-block;
}

.routine-chip-closeIcon {
  padding-left: 8px;
}

.appointment-started-main .add-task-item p,
.appointment-view-details-main .add-task-item p {
  text-decoration: underline;
}

.appointment-started-main .save-exit-button {
  padding: 11px 0 !important;
}

.appointment-started-main .finish-button {
  border-radius: 8px;
  background: var(--rejected-terminate-error, #f02d3a);
  color: #fff !important;
  border: none !important;
  outline: none !important;
}

.appointment-started-main .appoint-start-dashboardButton {
  border-radius: 8px;
  background: var(--Primary_Light_10, rgba(21, 160, 125, 0.1));
  margin: 0 14px;
  color: #15a07d;
  border: none !important;
  outline: none !important;
}

.appointment-started-main .appoint-start-dashboardButton svg {
  height: 16px;
}

.appointment-view-details-main .hide-client-details {
  box-shadow: 0 0 15px rgba(22, 22, 22, 0.1) !important;
}

.appointment-view-swap-button {
  display: flex;
  padding: 8px 16px !important;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none !important;
  color: #645dd7;
  background-color: #f0effb !important;
  margin: 0 14px;
}

.appointment-view-details-buttons .exit-button,
.appointment-view-details-buttons .save-button {
  padding: 11px 0 !important;
}

.map-location-icons {
  width: 24px;
}

.routine-chip-wrapper {
  width: auto;
  display: inline-block;
}

.appointment-view-swap-buttonTop {
  display: flex;
  padding: 8px 16px !important;
  align-items: center !important;
  gap: 8px;
  border-radius: 4px;
  border: none !important;
  background-color: #f5dcee !important;
}

.appointment-view-swap-buttonTop a {
  color: #875379 !important;
  font-family: "proxima_novasemibold" !important;
}

.appointment-view-swap-buttonTop img {
  width: 24px;
}

.appointment-view-start-button {
  display: flex;
  padding: 8px 16px !important;
  align-items: center !important;
  gap: 8px;
  color: #fff !important;
  border-radius: 4px;
  border: none !important;
  background-color: #15a07d !important;
}

.horizontal {
  overflow-y: visible;
  overflow-x: scroll !important;
}

.stepper-client-onBoard .MuiStepConnector-alternativeLabel {
  transform: rotate(0deg) !important;
}

.horizontal-stepper .MuiStepConnector-alternativeLabel {
  transform: rotate(0deg) !important;
}

.boarding-radio-label .radio-box-wrraper label {
  margin-left: 0 !important;
}

.leave-manage-tab {
  padding: 0 15px !important;
}

.timesheet-client-filter #timesheet-filter {
  border-top: none !important;
}

.border-top-none {
  border-top: none !important;
}

.stepper-client-onBoard .MuiStepConnector-alternativeLabel {
  width: 100%;
  top: 12px !important;
  margin-left: 0 !important;
}

.horizontal-stepper .MuiStepConnector-alternativeLabel {
  width: 100%;
  top: 12px !important;
  margin-left: 0 !important;
}

.stepper-client-onBoard .MuiStepConnector-line {
  min-height: 6px !important;
  width: 100%;
  background-color: #e5e5e5;
}

.stepper-client-onBoard .MuiStepConnector-active span {
  background-color: #15a07d;
}

.stepper-client-onBoard .MuiStepConnector-completed span {
  background-color: #15a07d;
}

.stepper-client-onBoard .step-lable > span > div::after {
  left: 0 !important;
}

.stepper-client-onBoard .step-lable svg {
  left: 23px !important;
}

.horizontal-stepper .step-lable svg {
  left: 23px !important;
}

.horizontal-stepper .step-lable > span > div::after {
  left: 0 !important;
}

.horizontal-stepper .MuiStepConnector-completed span {
  background-color: #15a07d;
}

.horizontal-stepper .MuiStepConnector-active span {
  background-color: #15a07d;
}

.horizontal-stepper .MuiStepConnector-line {
  min-height: 6px !important;
  width: 100%;
  background-color: #e5e5e5;
}

.MuiStep-completed .on-boarding-stepiIcon {
  filter: brightness(100);
}

.vertical-stepper-main .MuiStepConnector-alternativeLabel {
  top: -41px !important;
  right: -10px !important;
  left: auto !important;
}

.start-appoint-PopupCancel {
  border: 0.5px solid #8c8c8c !important;
  padding: 9px 16px !important;
  border-radius: 8px !important;
}

.start-appoint-PopupCancel:focus {
  background-color: #fff !important;
}

.start-appoint-PopupCancel:hover {
  background-color: #fff !important;
  background-image: none !important;
}

.vertical-stepper-main .MuiStepConnector-lineVertical {
  border-left-width: 4px;
}

.vertical-stepper-main .MuiStep-completed .MuiStepConnector-line {
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.start-appoint-PopupFinish {
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 8px !important;
}

.service-provider-actionBox {
  width: 100px !important;
  min-width: 100px !important;
}

.service-provider-actionBox .table-edit-del {
  margin-right: 0 !important;
}

.on-boarding-stepiIcon {
  position: absolute;
  z-index: 999;
  width: 22px;
  height: 24px;
  right: -21px;
  top: 1px;
  margin-bottom: 0px;
  object-fit: cover;
}

.horizontal-stepper-position .step-lable > span > div::after {
  left: -13px !important;
}

.horizontal-stepper-position .content {
  margin-top: 23px;
}

.start-appoint-PopupFinish {
  position: relative;
}

.swal2-icon.popIconReject {
  border-color: #f02d3a !important;
  color: #f02d3a !important;
}

.meeting-checkbox-wrapper {
  padding-left: 6px !important;
}

.bloodpressure-tabPanel > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.view-details-appointmentTab .checkbox-label > .d-flex > span {
  padding: 9px;
}

.view-details-appointmentTab .appointment-clear-button {
  padding: 6px 16px;
  border-radius: 4px;
}

.view-details-appointmentTab .select-category-menuItem {
  padding: 6px 16px !important;
}

/* SA dashboard css start here  */

/* #sa-PieChart .css-1u0lry5-MuiChartsLegend-root {
  display: none !important;
} */

.sa-pieChart-title.MuiTypography-root {
  font-size: 20px;
  color: #202e2a;
  font-family: "proxima_novasemibold";
}

.sa-pieChart-header.MuiTypography-root {
  text-align: center;
  color: #202e2a;
}

.sp-pieChart-header.MuiTypography-root {
  text-align: start;
  color: #202e2a;
  margin-left: 55px;
}

.sa-card-body1 {
  border-radius: 20px;
  background: #c5fff1b2;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  /* max-width: fit-content; */
  height: 217px;
}

.sa-card-body2 {
  border-radius: 20px;
  background: #d7d4ff99;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  /* max-width: fit-content; */
  height: 217px;
}

.sa-card-body3 {
  border-radius: 20px;
  background: #fff3c8b2;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  /* max-width: fit-content; */
  height: 217px;
}

.sa-card-body4 {
  border-radius: 20px;
  background: #ffdddfb2;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  /* max-width: fit-content; */
  height: 217px;
}

.sa-card-body-icon-container {
  margin-top: 10px;
  margin-left: 14px;
}

.sa-card-body-sc-container {
  margin-top: 10px;
}

.sa-card-body-sc-container-main {
  margin-left: -6px;
  margin-right: 5px;
}

.sa-card-body-icon-hccontainer {
  margin-top: 10px;
  margin-left: 40px;
}

.sa-card-body-client-container {
  margin-top: 10px;
  margin-left: 95px;
}

.sa-card-body-icon {
  color: #15a07d;
}

.sa-card-icon-align {
  margin-right: 10px !important;
}

.sa-card-body-child {
  margin-left: -5px;
}

.sa-card-content span {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novaregular";
}

.sa-card-content p {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novaregular";
}

.sa-card-content-wrapper {
  padding-top: 10px;
}

.publicholiday-head span {
  background-color: #15a07d;
  color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.publicholiday-head p {
  color: #8c8c8c;
  font-family: "proxima_novaregular";
}

.sa-demorequests p {
  color: #8c8c8c;
  font-family: "proxima_novaregular";
  font-size: 14px;
}

.sa-demorequests-span {
  color: #202e2a;
  font-family: "proxima_novaregular";
  font-size: 14px;
  padding: 3px;
}

.sa-demorequests-cards {
  background: #645dd740;
  height: 64px;
  width: 90px;
  border-radius: 11%;
  padding: 5px;
}

.sa-demorequests-cards span {
  color: #202e2a;
  font-size: 14px;
  font-family: "proxima_novaregular";
  text-align: center;
}

.sa-demorequests-cards p {
  color: #202e2a;
  font-size: 20px;
  font-family: "proxima_novabold";
  text-align: center;
}

.sa-demorequests-cards-2nd {
  background: #affed0 !important;
}

.sa-demorequests-cards-3rd {
  background: #fff3c8 !important;
}

.sa-payroll-chart-header p {
  font-size: 20px;
  color: #202e2a;
  font-family: "proxima_novasemibold";
  margin-bottom: 16px;
}

#invocie-bar-chat-width {
  margin: 0;
}

#invocie-bar-chat-width .css-thazi8-MuiBarElement-root {
  width: 16.5714px !important;
}

#invocie-bar-chat-width .css-1reacoo-MuiBarElement-root {
  width: 16.5714px !important;
}

/* SA dashboard css end here  */

/* Client dashboard css start here  */

.dashboard-main-goals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  overflow-x: auto;
}

.client-dashboard-card-body1 {
  border-radius: 20px;
  background: #affed0;
  padding: 26px 24px 0 24px;
  /* width: 100%px; */
  width: 350px;
  /* max-width: fit-content; */
  height: 217px;
  margin-right: 10px;
}

.client-dashboard-card-body2 {
  border-radius: 20px;
  background: #b5e6f7;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  /* max-width: fit-content; */
  width: 350px;
  height: 217px;
  margin-right: 10px;
}

.client-dashboard-card-body3 {
  border-radius: 20px;
  background: #fff3c8;
  padding: 26px 24px 0 24px;
  /* width: 256px; */
  width: 350px;
  /* max-width: fit-content; */
  height: 217px;
  margin-right: 10px;
}

/* Client dashboard css end here  */

/* health carer dashboard css  */

.dashboard-date {
  color: #15a07d;
  font-size: 24px;
  font-family: "proxima_novasemibold";
}

.dashboard-rating span {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
}

.dashboard-rating p {
  color: #212227;
  font-size: 24px;
  line-height: normal;
  font-family: "proxima_novabold";
}

.dashboard-rating svg {
  margin-left: 8px;
}

.hc-card-wrapper {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(126, 126, 126, 0.14);
  padding: 16px 24px;
  margin-top: 24px;
}

.hc-card-body {
  border-radius: 20px;
  border: 1px solid #e9edf7;
  background: #fff;
  padding: 10px 24px 0 24px;
  width: 256px;
  height: 156px;
}

.ind-hc-card-body {
  border-radius: 20px;
  border: 1px solid #e9edf7;
  background: #fff;
  padding: 10px 24px 0 24px;
  /* width: 256px; */
  width: 90%;
  height: fit-content;
  margin-left: 10px;
}

.ind-hc-card-content {
  padding-top: 10px;
  padding-bottom: 12px;
}

.hc-card-icon-detailText p {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novasemibold";
  font-weight: 400;
  line-height: normal;
}

.hc-card-icon-detailText span {
  color: #8c8c8c;
  font-size: 13px;
  font-family: "proxima_novaregular";
}

.hc-card-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 16px;
}

.wages-icon-bg {
  background-color: #e8f5f2;
}

.sc-profile-icon-bg {
  background-color: #d7d4ff99;
}

.hc-profile-icon-bg {
  background-color: #fff3c8b2;
}

.sp-profile-icon-bg {
  background-color: #c5fff1b2;
}

.hc-card-content span {
  color: #202e2a;
  font-size: 14px;
  font-family: "proxima_novaregular";
}

.hc-card-content p {
  color: #202e2a;
  font-size: 20px;
  font-family: "proxima_novabold";
}

.hc-card-content-wrapper {
  padding-top: 20px;
}

.hc-card-content-wrapper2 {
  padding-top: 13px;
}

.hour-icon-bg {
  background-color: #efeffb;
}

.leaves-icon-bg {
  background-color: #ffebe6;
}

.hc-appointment-table-wrapper {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 10px 60px 0px rgba(126, 126, 126, 0.14);
  padding: 24px;
}

.time-hour-span {
  color: #8c8c8c;
  font-size: 10px;
  font-family: "proxima_novaregular";
  display: block;
}

.hc-swap-action {
  color: #875379;
}

.hc-swap-action img {
  width: 18px;
  margin-right: 6px;
}

.hc-appointment-tabLable {
  font-size: 13px !important;
  color: #8c8c8c !important;
  font-family: "proxima_novaregular" !important;
  padding: 8px 12px !important;
  min-height: auto !important;
  text-transform: capitalize !important;
}

.hc-appointment-tabs-height {
  min-height: auto !important;
}

.hc-appointment-tabs .Mui-selected {
  background-color: #e3e8e680;
  border-radius: 6px;
  color: #202e2a !important;
}

.hc-appointment-tabs p {
  font-size: 20px;
  color: #202e2a;
  font-family: "proxima_novasemibold";
  margin-right: 18px;
}

.hc-appointment-showAll {
  color: #645dd7 !important;
  font-size: 13px !important;
  font-family: "proxima_novaregular" !important;
  text-transform: capitalize !important;
}

.hc-card-heading {
  color: #202e2a;
  line-height: normal;
  font-size: 20px;
  font-family: "proxima_novasemibold";
}

.alert-medications span {
  background-color: #f02d3a;
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
}

.alert-medications-text {
  padding-top: 16px;
}

.alert-medications-text img {
  width: 24px;
  margin-right: 11px;
}

.alert-medications-text p,
.alert-medications-text span {
  color: #202e2a;
  font-size: 16px;
  line-height: normal;
}

.alert-medications-text p {
  font-family: "proxima_novaregular" !important;
}

.alert-medications-text span {
  font-family: "proxima_novasemibold";
}

.hc-card-divider {
  width: 100%;
  background-color: #e3e8e6;
  height: 1px;
}

.upcoming-Leaves-text {
  margin-top: 20px;
}

.upcoming-Leaves-text p {
  color: #202e2a;
  font-size: 16px;
  font-family: "proxima_novaregular" !important;
}

.upcoming-Leaves-Type span {
  color: #8c8c8c;
  font-size: 16px;
  font-family: "proxima_novaregular" !important;
}

.upcoming-Leaves-Type div {
  width: 2px;
  height: 16px;
  background-color: #8c8c8c;
  margin: 0 8px;
}

.payrun-chart-status span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 4px;
}

.payrun-chart-status p {
  color: #202e2a;
  font-size: 13px;
  margin-right: 8px;
  font-family: "proxima_novaregular";
  line-height: normal;
}

.payrun-chart-status-green {
  background-color: #109648;
}

.payrun-chart-status-red {
  background-color: #f02d3a;
}

.payrun-chart-status-purple {
  background-color: #875379;
}

.payrun-chart-status-yellow {
  background-color: #ffc700;
}

.payrun-chart-status-blue {
  background-color: #645dd7;
}

/* __________ */

.payrun-chart-status-green1 {
  background-color: #15a07d;
}

.payrun-chart-status-green2 {
  background-color: #4fb89d;
}

.payrun-chart-status-green3 {
  background-color: #8acfbe;
}

.payrun-chart-status-green4 {
  background-color: #c4e7de;
}

.payrun-chart-status-blue1 {
  background-color: #645dd7;
}

.payrun-chart-status-blue2 {
  background-color: #8b85e1;
}

.payrun-chart-status-blue3 {
  background-color: #b1aeeb;
}

.payrun-chart-status-blue4 {
  background-color: #d8d6f5;
}

.payrun-chart-status-yellow1 {
  background-color: #fdd443;
}

.payrun-chart-status-yellow2 {
  background-color: #fdd443bf;
}

.payrun-chart-status-yellow3 {
  background-color: #fdd4438c;
}

.payrun-chart-status-yellow4 {
  background-color: #fdd4434d;
}

.payrun-chart-status-orange1 {
  background-color: #fc7a57;
}

.payrun-chart-status-orange2 {
  background-color: #fc7a57bf;
}

.payrun-chart-status-orange3 {
  background-color: #fc7a578c;
}

.payrun-chart-status-orange4 {
  background-color: #fc7a574d;
}

/* __________ */

/* -------- */

.payrun-chart-status-invoices {
  background-color: #15a07d;
}

.payrun-chart-status-Payroll {
  background-color: #645dd7;
}

/* -------- */

/* Client Dashboard Agrement css */

.client-chart-red {
  background-color: #f02d3a;
}

.client-chart-blue {
  background-color: #00a7e1;
}

.client-chart-green {
  background-color: #109648;
}

.client-chart-yellow {
  background-color: #ffc700;
}

/* __________ */

.payrun-chart-status-tabpanel > div {
  padding-left: 0;
}

.payrunChart {
  margin-top: 20px;
  width: 100%;
}

.payrunChart img {
  width: 100%;
  object-fit: cover;
}

.payment-chart-wrapper {
  padding: 24px !important;
}

.payrun-period {
  padding: 8px 0;
  border-top: 1px solid var(--stroke-border, #e3e8e6);
  background: #fff;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.payrun-period p,
.payrun-period span {
  font-size: 16px;
  color: #202e2a;
}

.payrun-period p {
  font-family: "proxima_novaregular";
}

.payrun-period span {
  font-family: "proxima_novasemibold";
}

.hc-client-tableCell p {
  color: #202e2a;
  font-family: "proxima_novaregular";
  font-size: 13px;
}

.hc-client-tableCell-icon {
  position: relative;
  width: 24px;
  margin-left: auto;
}

.hc-client-tableCell-icon img {
  width: 100%;
}

.hc-client-tableCell-icon span {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #f02d3a;
  color: #fff;
  line-height: normal;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-family: "proxima_novasemibold";
}

.chart-width {
  width: 800px;
}

.MuiChartsLegend-row,
.MuiChartsAxis-line,
.MuiChartsAxis-tick {
  display: none !important;
}

#health-carer-dashboard #simple-tabpanel-0 > .MuiBox-root {
  padding-bottom: 24px !important;
}

.appointment-table-vertical-height {
  height: calc(100vh - 336px);
}

/* support coordinator dashboard css  */

.sc-status-cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sc-status-cards {
  border-radius: 20px;
  border: 0px solid #e9edf7;
  padding: 20px 24px;
  width: 240px;
}

.sc-status-warning {
  background: rgba(255, 243, 200, 0.7);
}

.sc-status-danger {
  background: rgba(255, 221, 223, 0.7);
}

.sc-status-success {
  background: rgba(197, 255, 241, 0.7);
}

.sc-status-blue {
  background: rgba(215, 212, 255, 0.6);
}

.sc-card-status-icon {
  width: 40px;
}

.sc-status-card-content {
  margin-left: 16px;
}

.sc-status-card-content p {
  color: #202e2a;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novaregular";
}

.sc-status-card-content span {
  color: #202e2a;
  font-size: 24px;
  line-height: normal;
  font-family: "proxima_novabold";
}

.sc-status-card-addIcon {
  color: #15a07d;
}

.scTable-PaidIcon {
  color: #fff;
  width: 24px !important;
  height: 24px !important;
}

.sc-tableService-cell {
  width: 200px;
}

.bill-tooltip-title {
  color: #3d4244;
  font-size: 16px;
  line-height: normal;
  font-family: "proxima_novaregular";
  margin-left: 4px;
}

.sc-bill-tooltipCell .MuiTooltip-tooltipArrow {
  color: #fff;
}

.agreement-chart-status-active {
  background-color: #7dffb3;
}

.agreement-chart-status-ended {
  background-color: #ff7982;
}

.agreement-chart-status-ending {
  background-color: #ffc3c7;
}

.sc-agreement-chart {
  padding-top: 30px;
  position: relative;
}

.sc-agreement-chart svg {
  position: absolute;
  left: 15%;
}

.sc-agreement-chart .MuiChartsLegend-column {
  display: none !important;
}

.sc-agreement-chart > div {
  margin: auto !important;
}

.sc-agreement-chart-wrapper {
  padding-bottom: 102px !important;
}

/* .sc-fund-chart .MuiBarElement-series-uvId,
.sc-fund-chart .MuiBarElement-series-pvId {
 width: 40px !important;
} */

.fund-chart-status-fund {
  background-color: #b5e9d3;
}

.fund-chart-status-used {
  background-color: #ffb495;
}

/* .importexportbtn    */

.view-details-exportbuttons-wrapper .importexportbtn {
  padding: 0 33px !important;
}

.view-details-exportbuttons-wrapper .agency-add {
  padding-left: 20px;
  padding-right: 20px;
}

.sc-dashboard-tableAddButton {
  border-radius: 4px !important;
  background: #15a07d !important;
  padding: 8px 16px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  height: 37px;
}

/* .sc-dashboard-tableAddButton svg {
  margin-right: 8px;
} */

.subscription-page-filter .tableHeader .input-group {
  bottom: -30px;
  left: -57px;
}

.subscription-page-filter .tableHeader .input-group .MuiInputBase-sizeSmall {
  padding-right: 0 !important;
}

.subscription-three-dot-text {
  margin-right: 26px;
  margin-top: 4px;
}

.sc-dashboard-table-wrapper {
  padding-top: 0 !important;
}

.dashboard-rating svg {
  margin-bottom: 5px;
}

.SChour-chart .MuiChartsAxis-bottom {
  transform: translateY(264px);
}

.sc-dashboard-tabpannel > .MuiBox-root {
  padding: 0 0 24px 0 !important;
}

.Hc-dashboard-routine-cell .routine-cell-btn {
  border-radius: 4px;
  background: rgba(21, 160, 125, 0.1);
  padding: 4px;
  color: #15a07d;
  line-height: normal;
}

.Hc-dashboard-routine-cell .routine-cell-btn svg {
  margin-right: 6px;
}

.view-goal-accordion-wrapper .gaol-accordion .accrdingbg-color {
  flex-direction: row !important;
}

.attachment-doc-width {
  max-width: 324px;
}

.upload-input-iconSvg svg {
  margin-right: 8px;
}

.table-reason-link {
  color: #212121;
  cursor: pointer;
  line-height: normal !important;
  text-transform: capitalize !important;
  text-decoration: underline !important;
}

.list-checkboxes-wrapper-wrap {
  flex-wrap: wrap;
  justify-content: start !important;
}

.grid-padding-10 {
  padding-top: 10px !important;
}

.reasonLabel {
  font-family: "proxima_novasemibold";
}

.reasonText {
  font-family: "proxima_novaregular";
}

.reasonLabel,
.reasonText {
  color: #202e2a;
  font-size: 14px !important;
}

.reason-button {
  justify-content: start !important;
}

.taskSmall-icon {
  width: 16px !important;
}

.buttom-check .MuiCheckbox-root {
  padding: 9px !important;
}

.reason-tooltip-padding {
  padding: 6px 16px;
}

.fund-chartWrap {
  height: 600px !important;
}

#calender .fc .fc-daygrid-more-link {
  top: -15px;
}

.calender-data-cards {
  width: 100%;
}

.MuiAutocomplete-popper {
  display: block !important;
}

.MuiAutocomplete-option {
  margin-bottom: 10px !important;
}

.client-tooltip-button {
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-width: 48px !important;
  background: none !important;
}

.team-cell .MuiTooltip-popper {
  border: 1px solid #f9f9f9 !important;
  box-shadow: 0 0 15px #f1f1f1 !important;
}

.meating-details-wrapper {
  width: 100%;
  padding: 10px;
}

.meating-details-wrapper-bg {
  background-color: #bbaacc;
}

.meating-details-tablecell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-input .Mui-error,
.alert-color,
.Mui-error {
  font-family: "proxima_novaregular" !important;
  font-size: 14px !important;
  /* white-space: nowrap !important; */
}

.form-input-pass {
  white-space: initial;
}

.leave-calender-strip {
  width: 100%;
  padding: 4px 0;
  line-height: normal !important;
  align-items: center;
  justify-content: flex-start !important;
  border-radius: 6px !important;
  color: #3d4244 !important;
  border: none !important;
  position: relative;
  text-transform: capitalize !important;
}

.leave-calender-strip::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px 0px 0px 6px;

  width: 4px;
  height: 100%;
}

.leave-calender-strip-success::after {
  background: #00a11a !important;
}

.leave-calender-strip-success {
  background-color: #ccfad4 !important;
}

.leave-calender-strip-reject {
  background-color: #ffe8e8 !important;
}

.leave-calender-strip-reject::after {
  background: #ff5251 !important;
}

.leave-calender-strip-pending {
  background-color: #fdeeba !important;
}

.leave-calender-strip-pending::after {
  background: #e8b500 !important;
}

.leave-calender-strip-disable {
  background-color: #e3e3e3 !important;
}

.leave-calender-strip-disable::after {
  background-color: #929495 !important;
}

.leave-calender-strip-cancel {
  background-color: #ffe6d0 !important;
}

.leave-calender-strip-cancel::after {
  background-color: #fea151 !important;
}

#leave-calender-main .fc-daygrid-day-events {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

#leave-calender-main .fc-daygrid-day-events {
  height: auto !important;
}

.leave-calender-popup .client-img {
  width: 55px;
  margin-right: 16px;
  /* height: ; */
}

.leave-calender-popup .client-img img {
  width: 100%;
}

.leave-calender-popup {
  background: #fff;
  padding: 20px;
}

.client-name-main p {
  color: #006ca6;
  font-size: 16px;
  line-height: 28px;
  /* 28px */
  text-decoration-line: underline;
  font-family: "proxima_novasemibold";
}

.client-name-main span {
  color: #929495;
  font-size: 13px;
  font-style: normal;
  line-height: 175%;
  font-family: "proxima_novaregular";
}

.leave-slectslot-menuItem {
  display: block !important;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.leave-slot-select {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 20px;
}

.casual-unpaid-tooltipIcons {
  width: 20px;
  margin-right: 4px;
}

.leave-popup-selectBox fieldset {
  border: none !important;
}

.leave-subTooltip {
  background-color: #fff !important;
}

#leave-selectBox {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.2em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.casual-unpaid-icons {
  width: 20px;
  margin-right: 8px;
}

.casual-unpaid-icons img {
  width: 100%;
  object-fit: cover;
}

.leave-popup-date {
  font-size: 16px;
  font-family: "proxima_novasemibold";
  color: #3d4244;
  font-style: italic;
}

.reason-text {
  margin-top: 15px;
}

.reason-text p {
  font-size: 16px;
  font-family: "proxima_novasemibold";
  color: #3d4244;
}

.reason-text span {
  font-size: 16px;
  font-family: "proxima_novaregular";
  color: #3d4244;
}

.leave-popup-table {
  width: 368px;
}

.leave-table-th {
  border-radius: 4px 0px 0px 0px;
  border-right: 1px solid #dae5eb;
  background: #e2ecf2;
  color: #8c8c8c !important;
  /* padding: 4px 16px; */
}

.leave-underline {
  background: #dae5eb;
  width: 100%;
  height: 0.5px;
}

.leave-popup-selectBox {
  width: 150px;
}

.leave-popup-selectBox .MuiSelect-select {
  padding-left: 0 !important;
}

.leave-popup-status-icons {
  width: 20px;
}

.leave-popup-status-icons img {
  width: 100%;
  object-fit: cover;
}

.leave-popover-arrow {
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translateY(-50%);
  z-index: 999;
  color: #fff;
}

.leave-calenderPopoverMain .MuiPaper-root {
  overflow-y: visible !important;
  overflow-x: visible !important;
  transform: translateX(-16px) !important;
  z-index: 9999 !important;
}

.leave-popup-table .MuiPaper-root {
  transform: translateX(0) !important;
}

.leave-select-pending {
  background-color: #ffc700;
  color: #ffc700;
}

.leave-select-reject {
  background-color: #f02d3a;
  color: #f02d3a;
}

.leave-select-approved {
  background-color: #109648;
  color: #109648;
}

.leave-select-cancelled {
  background-color: #f17300;
  color: #f17300;
}

/* .leave-list-main .tab-container {
  max-width: 280px;
} */
.leave-list-main .tab-container {
  max-width: fit-content;
}

.leave-calender-fullcalender .fc .fc-popover {
  z-index: 999 !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.Hc-Availibility-main .select-slot-calender {
  margin-top: 0 !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #15a07d !important;
}

.Hc-Availibility-main .week-add-icon .modal-checkbox {
  margin-right: 0 !important;
  margin-left: -3px;
}

.calender-toggle-chip img {
  width: 24px;
}

.chat-popup {
  position: fixed;
  width: 600px;
  height: 600px;
  bottom: 0;
  right: 0;
  z-index: 999;
}

@media (max-width: 1610px) {
  .container {
    width: 100%;
    max-width: 100%;
  }

  .appbar-menu-item {
    margin-left: 15px;
  }
}

@media (max-width: 1450px) {
  /* .onBoarding-horizontal-stepper .MuiStepConnector-lineVertical {
    min-height: 182px !important;
  } */
  .list-checkboxes-wrapper .buttom-check label {
    margin-right: 0 !important;
  }

  .importexportbtn {
    font-size: 14px !important;
  }

  .header-topsection .agency-add {
    min-height: 36px;
  }

  .dashboard-logo {
    width: 70px;
  }

  .tab-container .Tabstyle-test {
    font-size: 14px !important;
  }

  .tab-container {
    max-width: fit-content;
  }

  .hc-card-wrapper {
    padding: 16px 18px;
  }

  .main-charts-wrapper {
    max-width: 50% !important;
    flex-basis: 50% !important;
  }

  .appbar-main {
    padding: 12px 20px;
  }
}

@media (max-width: 1320px) {
  .shift-type-checkbox {
    padding-right: 50px;
  }

  .dashboard-form .schedule-cell {
    min-width: 122px;
    width: 100%;
  }

  /* .main {
    max-width: calc(100% - 222px);
  } */

  .appbar-main {
    padding-left: 50px;
    padding-right: 50px;
  }

  .shift-formcontrol-box {
    padding-right: 50px !important;
  }

  .appbar-switch p {
    padding-left: 4px;
  }

  .meeting-details > div {
    padding: 10px;
  }

  .sidebar-item span {
    font-size: 13px;
  }

  .dashboard-form-typo-up {
    padding-left: 10px;
  }

  #client-table-head .table-head-cell {
    padding-left: 7px;
    padding-right: 7px;
  }

  #client-table-body .tableborder {
    padding-left: 7px;
    padding-right: 7px;
  }

  #client-table-body #client-color-td {
    padding-left: 7px;
    padding-right: 7px;
  }

  #client-table-body #meetingCell {
    padding-left: 7px;
    padding-right: 7px;
  }

  #client-management .client-drop-menu {
    left: -30px !important;
  }

  .dashboard-form .agency-table {
    padding-right: 0;
    margin-top: 30px;
  }

  .dashboard-form .MuiTableCell-body {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .dashboard-form .agency-searchbar {
    margin-top: 30px;
  }

  #document-table .table-head-cell {
    padding: 8px 10px;
  }

  .employee-th {
    padding: 0 4px !important;
  }

  #calender .calender-client-cell {
    padding: 4px 8px;
  }

  .employee-detail {
    padding: 0 5px;
  }

  /* fundmanagement css  */

  .fund-bar-content p {
    font-size: 14px;
  }

  .fund-bar-content span {
    font-size: 12px;
  }

  .addFund-field {
    width: 200px;
  }

  .fundImage {
    width: 40px;
  }

  .SP-listAppointment .search-box-wrap {
    align-items: start !important;
  }

  .appointment-checkbox .checkbox-label {
    padding-top: 0 !important;
  }

  .appointment-checkbox .checkbox-label span {
    font-size: 13px;
  }

  .Sp-tableCheckbox .clear-button-bgLight {
    margin-top: -10px !important;
  }

  .checkbox-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -8px;
  }

  .select-category-main {
    width: 260px !important;
  }

  .appointment-search {
    width: 325px !important;
  }

  .SP-stepper-heading {
    padding-left: 20px !important;
  }

  .buttom-check .MuiCheckbox-root {
    padding-right: 0 !important;
  }

  .buttom-check .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }

  .buttom-check span {
    padding-right: 0 !important;
  }
}

@media (max-width: 1280px) {
  .view-detail-sp-clientBox {
    width: 80%;
  }

  .hc-appointment-tabs p {
    margin-right: 12px;
  }

  #superAdmin-stepper .step-lable .content {
    font-size: 12px;
  }

  #superAdmin-stepper .stepper {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .appbar-notification {
    width: 50px;
  }

  .meeting-checkbox-wrapper {
    padding-left: 0 !important;
  }

  .appbar-menu-item {
    margin-left: 18px;
  }

  .routine-icon-box p {
    font-size: 14px;
  }

  .hc-appointment-tabs p {
    font-size: 16px;
  }

  .appointment-search input {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .tab-container .Tabstyle-test {
    min-height: 34px !important;
  }

  .tab-container {
    min-height: 40px !important;
  }

  .appointment-calender-filed-box {
    margin-top: 0 !important;
  }

  .dashboard-logo {
    width: 60px;
  }

  .appbar {
    padding: 10px 0;
  }

  .appbar-explore {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .appointment-table-vertical-height {
    height: calc(100vh - 340px);
    position: relative;
  }

  .appbar-notification button {
    margin-left: 0 !important;
  }
}

@media (max-width: 1240px) {
  .dashboard-form .weakday-section {
    padding-right: 10px;
  }
}

@media (max-width: 1199px) {
  .dashboard-form .weakday-section {
    padding-right: 10px;
    flex-wrap: wrap;
    justify-content: left !important;
  }

  .dashboard-form .weakday-box {
    margin: 20px 10px 0 0;
  }

  .shift-type-checkbox {
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 13px;
    justify-content: left !important;
  }

  .shift-formcontrol-box .flex-row {
    justify-content: left !important;
  }

  .shift-formcontrol-box .flex-row .MuiRadio-root {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .appbar-switch p {
    font-size: 14px;
    padding-left: 10px;
  }

  .appbar-switch {
    padding: 8px;
  }

  #appbar .text {
    font-size: 14px;
  }

  .appbar-notification p {
    padding-right: 0;
  }

  .shift-type-check span {
    font-size: 14px;
  }

  .shift-type-check {
    padding: 8px 10px;
  }

  .shift-type-check svg {
    width: 21px;
  }

  .shift-type-check span:nth-child(1) {
    padding-left: 0;
  }

  .dashboard-form .shift-type-section-box {
    margin-right: 10px;
  }

  .dashboard-form .shift-type-section:nth-child(1) {
    padding: 14px 0;
  }

  .dashboard-form .shift-type-section:nth-child(2) {
    padding: 0;
  }

  .dashboard-form .shift-type-section:nth-child(3) {
    padding: 14px 0;
  }

  .dashboard-form .shift-select .MuiSelect-select em {
    font-size: 14px;
  }

  .dashboard-form .form-text {
    font-size: 14px;
  }

  .dashboard-form .form-text span {
    font-size: 14px;
  }

  .shift-type-section .appbar-switch p {
    font-size: 16px;
  }

  .appbar-menu-item {
    margin-left: 56px;
  }
}

@media (max-width: 768px) {
  .menu-link-hide {
    display: none;
  }

  #sidebar {
    width: 64px;
  }

  .main {
    max-width: calc(100% - 65px);
  }

  .shift-type-section {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .shift-type-checkbox {
    margin: 20px 0 0 32px;
    justify-content: space-between !important;
  }

  .shift-formcontrol-box {
    padding-left: 32px !important;
  }

  .appbar-main {
    padding-left: 20px;
    padding-right: 20px;
  }

  .shift-formcontrol-box {
    padding-right: 20px !important;
  }

  .switch-box {
    padding: 20px 0 0 32px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: flex-start !important;
  }

  #appbar .text {
    display: none;
  }

  .appbar-notification {
    width: 20px;
  }

  .appbar-menu-item {
    margin-left: 47px;
  }

  .dashboard-logo {
    width: 60px;
  }
}

.fund-bar-box-view {
  background-color: rgba(100, 93, 215, 0.15);
  padding: 18px 16px;
  /* Adjust padding to make the width smaller */
  margin-top: 32px;
}

.fund-bar-content-view {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Adjusted to start from the left */
}

.fund-bar-content-view span {
  color: #202e2a;
  font-size: 12px;
  /* Adjust font size */
  font-family: "proxima_novaregular" !important;
  margin-right: 8px;
  /* Add margin between "Budget Plan for" and date range */
}

.fund-bar-content-view p {
  color: #202e2a;
  font-size: 14px;
  font-family: "proxima_novabold";
  margin: 0;
  /* Remove default margin */
}

@media (max-width: 600px) {
  .checkbox-label-box .checkbox-label {
    font-size: 16px;
    text-align: start;
  }

  .radio-group-box {
    padding-left: 50px;
  }

  .radio-group-box {
    padding-left: 0px;
  }

  .empid-label-box.MuiTypography-root {
    color: #3d4244;
  }

  .empid-label-box.MuiTypography-root {
    color: #3d4244;
    padding-left: 25px;
  }

  .tab-text.MuiTypography-root {
    color: #3d4244;
    font-size: 12px;
  }

  .myaccounttabs {
    width: 100%;
    overflow-x: auto !important;
    overflow-y: hidden;
  }
}

@media (max-width: 576px) {
  .dashboard-form .weakday-box {
    max-width: 100%;
  }

  .appbar-menu-item p {
    display: none;
  }

  .notification-main {
    min-width: 351px;
    right: -117px;
  }

  .padding-res-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

/* media query */

@media (max-width: 768px) {
  .breadcrumb-box-sty {
    justify-content: start;
    margin-top: 21px;
  }
}

.red-reject-button {
  background-color: rgba(218, 39, 39, 0.767) !important;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  margin-left: 10px;
  padding: 10px 14px !important;
  font-family: "proxima_novabold";
}

.scroll-showMore {
  overflow-y: scroll;
}

.centered-cell {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.shift-filter {
  border-radius: 8px;
  padding-right: 8px;
}

.shift-filter-bg {
  background-color: #e3e8e6;
}
