.color-tab {
  background-color: #59c5fe4d;
  padding: 1%;
}

.button-style {
  display: flex;
  float: right;
  padding-bottom: 2%;
}

.zero-margin {
  margin-left: 0px !important;
}

/* .ck-content .image-inline img, .ck-content .image-inline picture{
    width: 60vh !important;
    height: 51vh !important;
} */

.ck-content .image-inline img,
.ck-content .image-inline picture {
  max-width: 60vh;
  max-height: 51vh;
  width: auto;
  height: auto;
  object-fit: contain;
}

.provider-view-img {
  margin-top: -6px !important;
}

/* Policy Css */

.privacy-policy {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.privacy-policy h1,
.privacy-policy h2 {
  color: #333;
}

.terms-conditions {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.terms-conditions h1,
.terms-conditions h4 {
  color: #333;
}

.card-media-container {
  position: relative;
  height: 216px;
  width: 181px;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.card-media-container:hover .hover-image {
  display: block;
}

.card-media-container:hover .card-image {
  display: none;
}

.card-media-container:not(:hover) .card-image {
  display: block;
}

.card-media-container:not(:hover) .hover-image {
  display: none;
}

.verticalAlign {
  vertical-align: top !important;
}

.verticalAlign-mt {
  margin-top: 27px !important;
}
