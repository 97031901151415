.roaster-card {
  background: rgba(248, 248, 248, 1);
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
  border-radius: 10px;
}

.DateInput_input{
  font-weight: 200;
    font-size: 16px;
    line-height: 18px;
}

.roaster-card .card-total {
  line-height: 36px;
  font-size: 24px;
  color: #000;
  font-weight: 600;
}

.roaster-card .card-image { 
  height: 62px;
  width: 62px;
  margin: 0;
}

.roaster-card .card-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.roaster-card .card-shift {
  line-height: 24px;
  font-size: 16px;
  color: #989898;
  font-weight: 500;
}

.dashboard-form .MuiTableCell-body {
  height: 46px;
  padding: 10px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1px;
  width: 1px;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 10px;
}

.btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .my-icon {
    border: 10px solid #f25555;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icons {
      height: 20px;
      width: 20px;
      border: 10px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #f25555;
    }
  }
}

.btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .my-icon.check {
    border: 10px solid #07d62a;
    .icons.check {
      color: #07d62a;
    }
  }
}

.btn-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .my-icon.edit {
    border: 10px solid #1876d2;

    .icons.edit {
      color: #1876d2;
    }
  }
}

.flex-start {
  justify-content: flex-start;
}

.DateInput_input {
  padding: 6px 12px;
}

.DateRangePickerInput__withBorder{
  
  // height: 50px !important;
  .DateInput{
    .DateRangePickerInput_arrow_svg{
      padding-right: 15px;
    }
    .DateInput_input{
      font-size: 16px;
      padding: 5px 10px;
    }
  }
}


// /* Target the input fields within the DateRangePicker */
// .custom-date-range-picker 
// {.DateInput_input {
//   font-size: 14px !important;   /* Adjust font size */
//   height: 20px !important;      /* Adjust input height */
// }
// }

// /* You can also target other elements like the date range picker input field */
// .custom-date-range-picker .DateRangePicker_input {
//   font-size: 14px !important;
//   height: 20px !important;
// }

@media only screen and (max-width: 992px) {
  .roaster-card {
    padding: 10px;
  }

  .roaster-card .card-total {
    font-size: 20px;
  }
  .roaster-card .card-shift {
    font-size: 14px;
  }
  .roaster-card .card-image {
    height: 50px;
    width: 50px;
  }
}
