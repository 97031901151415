/* calender section start here */

#calenderal .fc-col-header {
  width: 100% !important;
}

#calenderal .fc-col-header-cell-cushion {
  font-family: "proxima_novasemibold";
  font-size: 16px;
  font-weight: 400;
  color: #3d4244;
}

#calenderal .fc-daygrid-day-events {
  width: 100%;
  height: 100px;
  background-color: transparent;
}

#calenderal .fc-event-main {
  height: 100%;
}

#calenderal .fc-event {
  height: 100%;
}

#calenderal .fc-daygrid-event-harness {
  height: 100%;
}

#calenderal .calender-date-cell {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
}

#calenderal .calender-date-cell p {
  color: #3d4244;
  font-size: 14px;
  font-weight: 400;
}

#calenderal .fc-h-event {
  background: none;
  border: none;
}

#calenderal .calender-client-cell p {
  font-size: 14px;
  font-weight: 400;
  color: #3d4244;
}

#calenderal .calender-client-cell span {
  font-size: 10px;
  font-weight: 400;
  color: #3d4244;
}

#calenderal .calender-client-cell span svg {
  width: 14px;
  margin-left: 10px;
}

#calenderal .fc-theme-standard td {
  width: 16.6666666667%;
  padding: 0 10px;
  border-left: none;
  border-right: none;
}

#calenderal .calender-client-cell {
  padding: 8px 14px;
  border-radius: 8px;
  width: 100%;
}

#calenderal .fc-scrollgrid-section > td {
  padding: 0 !important;
}

#calenderal .fc-header-toolbar {
  flex-direction: row-reverse;
}

#calenderal .calender-icon {
  width: 18px;
  color: #929495;
}

#calenderal .fc-theme-standard td,
.fc-theme-standard th {
  border: none;
  border-bottom: 1px solid var(--fc-border-color) !important;
}

.calender-view-gray {
  background-color: #d3d4d5;
}
.calender-view-pink {
  background-color: #f0d8ff;
}
.calender-view-succes {
  background-color: #a4ffb3;
}
.calender-view-purple {
  background-color: #edc3ac !important;
}
.calender-view-yellow {
  background-color: #ffeba4;
}

.calender-view-blue {
  background-color: #ccf4f8;
}

.calender-view-red {
  background-color: #ffcdcd;
}

#calenderal .fc-event .fc-event-main:focus-visible {
  outline: none !important;
}
