#demo-request .fc .fc-day-past .fc-daygrid-day-top {
  opacity: 0.3 !important;
}

#demo-request .fc .fc-day-today .fc-daygrid-day-top {
  opacity: 0.3 !important;
}

#demo-request .fc .fc-day-past .fc-daygrid-day-frame:hover {
  background-color: #e2e2e2 !important;
}

#demo-request .fc .fc-day-today .fc-daygrid-day-frame:hover {
  background-color: #e2e2e2 !important;
}
