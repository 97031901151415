.deleteAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}

/* .deleteAccountLogo {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  color: #1976d2;
} */

.deleteAccountLogo {
  margin-bottom: 20px;
  width: 80px; /* Adjust the size as needed */
  height: 80px;
}

.deleteAccountForm {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deleteAccountTextField {
  margin-bottom: 20px;
}

.deleteAccountButton {
  color: #15a07d !important;
  text-transform: none !important;
}

.deleteAccountButton2 {
  margin-top: 20px !important;
  background-color: #15a07d !important;
  color: #fff !important;
  text-transform: none !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  transition: background-color 0.3s ease !important;
}

.deleteAccountButton2:hover {
  background-color: #15a07d !important;
}

.deleteAccountError {
  color: red;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .deleteAccountForm {
    padding: 15px;
  }

  .deleteAccountLogo {
    width: 60px;
    height: 60px;
  }
}
