#roasterDashboardBox {
  padding: 10px 20px 20px 20px;
  width: 100%;
  padding-top: 10px;
}

#roasterDashboardBox .roasted-card-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 4px 0px hsla(0, 0%, 0%, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.agency-add.reset-btn{
  background-color: rgba(220, 53, 69, .9);  
  border: 1px solid #dc3545;
  &:hover{
    color: #dc3545;
  }
}

.justi-center{
  justify-content: space-between;
}

.date-arr-icon{
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 4px 0px hsla(0, 0%, 0%, 0.1);
}

#roasterDashboardBox .roaster-chart-container {
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}


#roasterDashboardBox 
{
  .fliter-toggle{
  display: none;
}
}

#roasterDashboardBox {
  .filter-btn {
    min-height: 0 !important;
    padding: 4px 10px;
    cursor: pointer;
  }
  .roaster-table-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    border: 1px solid red;
  }
}
.roaster-dash-heading {
  font-size: 28px;
  font-weight: 600;
}

.roaster-chart-box {
  border: 1px solid #ebebeb;
  border-radius: 10px;
  box-shadow: 0px 0px 4px 0px #0000001a;
  padding: 15px;
  gap: 20px;
  overflow: auto;
  .css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tickLabel {
    font-size: 16px !important;
  }
}

.flex-1 {
  flex: 1;
}
.table-scroll {
  max-height: 300px;
  overflow: auto;
  padding-right: 5px;
}

.table-scroll::-webkit-scrollbar {
  width: 1px;
  background-color: #15a07d;
  margin-left: 2px;
  height: 0px;
}

.css-tj2kpx-MuiPaper-root-MuiTableContainer-root {
  margin-left: 0;
  margin-right: 0;
}

.grid-two {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
}

.staff-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.staff-box {
  background: #05d9fe21;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .staff-head {
    color: #828181;
    font-size: 22px;
    font-weight: 400;
  }
  .staf-count {
    color: #000;
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0px;
  }
  .align-item-end {
    display: flex;
    justify-content: flex-end;
    .staff-icon {
      width: 40px;
      height: 40px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.staff-one {
  background: #fed07d21;
  border: 1px solid #fed07d;
}

.staff-two {
  background: #05d9fe21;
  border: 1px solid #05d9fe;
}

.staff-three {
  background: #b48eff21;
  border: 1px solid #b48eff;
}

.staff-four {
  background: #d8e90321;
  border: 1px solid #d8e903;
}
.staff-five {
  background: #14a07d21;
  border: 1px solid #14a07d;
}
.staff-six {
  background: #ea71ff21;
  border: 1px solid #ea71ff;
}
.staff-seven {
  background: #7d90fe21;
  border: 1px solid #7d90fe;
}

.staff-cta{
  background: rgba(197, 27, 87, 0.13);
  border: 1px solid rgba(197, 27, 87, 1);
}

.roaster-last-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.roaster-utilize {
  background: linear-gradient(
    180deg,
    #c5bcff 0%,
    rgba(245, 244, 255, 0.43) 100%
  );
  .utilize-heading {
    color: #4e3395;
    font-size: 28px;
  }
  .utilize-name-box {
    .utilize {
      padding: 10px 20px;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #d1cbf9;
      margin-top: 15px;
      background: #F5F4FF;
      .ut-name {
        color: #000;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.date-filter-section {
  padding: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  .center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .display-date {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    color: #15a07d;
    border: 4px solid transparent;
  }
  .filter-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    // label[data-shrink=false]+.MuiInputBase-formControl .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    //   min-width: 150px  !important;
    // }
  }
}

.aval-main-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .aval-icon-box{
    display: flex;
    flex-direction: column;
    gap: 5px;
    .aval-icon{
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: 1px solid #886ADD;
      background-color: #886ADD;
      margin-right: 5px;
    }
    .aval-icon.icons{
      border: 1px solid #7AB865;
      background-color: #7AB865;
      // background: #7AB865;

    }
  }
}

.dashboard-form .agency-add svg{
  margin-left: 0px;
}




@media only screen and (max-width: 1300px) {
  .grid-two {
    grid-template-columns: 1fr;
  }
  .roaster-dash-heading{
    font-size: 26px;
  }

  .staff-box {
    .staff-head {
      font-size: 18px;
    }
    .staf-count {
      font-size: 22px;
    }
  }  

    // #roasterDashboardBox .roaster-chart-container {
    //   grid-template-columns: 1fr;
    // }
}

@media only screen and (max-width: 1150px) {
  .roaster-last-section {
    grid-template-columns: 1fr;
  }
  #roasterDashboardBox .roasted-card-box {
    grid-template-columns: repeat(2, 1fr);
  }
  .date-filter-section {
    .filter-drop {
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 992px) {
  .pay-run-input {
    width: 100%;
    margin: 20px 0px;
  }
  .staff-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .roaster-dash-heading {
    font-size: 22px;
  }

#roasterDashboardBox .roaster-chart-container {
  grid-template-columns:  1fr;
}
}

@media only screen and (max-width: 768px) {
  .date-filter-section {
    
    .filter-drop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
  
  }


}


@media only screen and (max-width: 576px) {
  .date-filter-section {
    .center-flex {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  
  }

  #roasterDashboardBox 
{
  .date-filter-section{
    position: relative;
  }
  .fliter-toggle{
    display: block; 
    position: absolute;
    top: 10px;
    right: 0px;
  }
  .filter-drop{
    position: relative;
    height: 0px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
    
  }
  .filter-drop.toggle-state{
    height: 150px;
    overflow: auto;
  }
}


  #roasterDashboardBox .roasted-card-box {
    overflow: auto;
  }

  .roaster-utilize {
    .utilize-heading {
      font-size: 18px;
    }
    .utilize-name-box {
      .utilize {
        padding: 10px;
        .ut-name {
          font-size: 16px;
        }
      }
    }
  }

  .css-1sunrpn-MuiResponsiveChart-container {
    width: 420px;
  }

  .roaster-chart-box {
    padding: 10px;
    overflow: auto;
  }

  .bar-chart-box {
    max-width: 430px;
    overflow: auto;
  }

  .roaster-dash-heading {
    font-size: 18px;
  }
}
